export default {
  chosenType: null,
  files: {},
  errorMessage: '',
  disabled: true,
  title: 'step2.title',
  subtitle: 'step2.subtitle',
  status: '',
  buttonText: 'step2.btn.verifyKYC',
  uploadretries: 0,
  pollingCount: 1,
  gestureInstruction: [],
  gestureSetUsed: null,
  account: null,
  ekycData: []
}
