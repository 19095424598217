import * as selfConstants from './constants'
import initialState from './initialState'
import { supportedLocales } from '@/locales'

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case selfConstants.UPDATE_MODAL: {
      const { show } = action.payload
      const newModalState = show
        ? {
          ...state.modal,
          ...action.payload
        }
        : initialState.modal
      return {
        ...state,
        modal: newModalState
      }
    }
    case selfConstants.UPDATE_LOADER:
      return {
        ...state,
        showLoader: action.payload
      }
    case selfConstants.UPDATE_LOCALE:
      return {
        ...state,
        locale: supportedLocales[action.payload]
      }
    case selfConstants.UPDATE_LANGUAGE_CODE:
      return {
        ...state,
        locale: {
          ...state.locale,
          code: action.payload
        }
      }
    case selfConstants.UPDATE_APPLICATION_REQUEST_ID:
      return {
        ...state,
        applicationRequestId: action.payload
      }
    case selfConstants.UPDATE_IS_RESUMABLE:
      return {
        ...state,
        isResumable: action.payload
      }
    case selfConstants.UPDATE_MERCHANT:
      return {
        ...state,
        merchant: action.payload
      }
    case selfConstants.SET_NON_RA_RESPONSE:
      return {
        ...state,
        nonRaResponse: action.payload
      }
    default:
      return state
  }
}
export default reducer
