import styled from 'styled-components'

export const Wrapper = styled.div`
  background: #ffffff;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  padding: 2px 10px;
`
