import styled from 'styled-components'
import React from 'react'
import { secondaryColor } from '@/containers/App/globalStyles'

const ListWrapper = styled.div`
  & > div {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    font-size: 14px;
    &:nth-child(even) {
      background: ${secondaryColor};
    }
  }
`
const List = props => {
  const { data, ...rest } = props
  return (
    <ListWrapper {...rest}>
      {data.map((item, index) => {
        const { key, value } = item
        return (
          <div key={index}>
            <div>
              <strong>{key}</strong>
            </div>
            <div>
              <span>{value}</span>
            </div>
          </div>
        )
      })}
    </ListWrapper>
  )
}
export default List
