import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as appActions from '@/containers/App/actions'
import { FormattedMessage } from 'react-intl'
import Button from '@/components/Button'
import ButtonCancel from '@/components/ButtonCancel'

class APIErrorContent extends React.Component {
  closePopup = () => {
    const { appActions } = this.props
    appActions.updateModal({ show: false })
  };
  closeCancel = () => {
    const { appActions } = this.props
    appActions.updateModal({ show: false })
    window.location.href = 'https://trustiq.id/'
  };

  render () {
    // console.log('dynamic message are ', this.props.message)
    return (
      <div>
        <div className='text-center'>
          <h5>
            <strong>
              <FormattedMessage id='E-Kontrak Ditolak' />
            </strong>
          </h5>
        </div>
        <div className='text-center'>
          <div className='mt-3'>
            <div>
              {this.props.message || ''}
            </div>
          </div>
        </div>
        <div className='text-center mt-4'>
          <Button
            data-test-id='btn-close'
            onClick={this.closePopup}
          >
            <FormattedMessage id='Tinjau E-Kontrak' />
          </Button>
          <br />
          <ButtonCancel
            data-test-id='btn-close'
            onClick={this.closeCancel}
          >
            <FormattedMessage id='Batalkan' />
          </ButtonCancel>
        </div>
      </div>
    )
  }
}

export const mapStateToProps = state => {
  return {
    locale: state.app.locale,
    resultGetProducts: state.step4.resultGetProducts,
    selectedProductId: state.step4.selectedProductId
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    appActions: bindActionCreators(appActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(APIErrorContent)
