import styled from 'styled-components'

const HorizontalLine = styled.hr`
  background: #D8D8D8;
  position: relative;
  margin: 10px 0;
  &::before {
    content: "";
    background: #394dff;
    width: 20px;
    height: 3px;
    display: inline-block;
    position: absolute;
    top: -2px;
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px;
  }
`

export default HorizontalLine
