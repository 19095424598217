import React from 'react'
import { connect } from 'react-redux'
import qs from 'query-string'
import * as appActions from '@/containers/App/actions'
import * as appConstants from '@/containers/App/constants'
import { bindActionCreators } from 'redux'

class HandleRedirection extends React.Component {
  componentDidMount () {
    const {
      appActions,
      match: {
        params: { merchant }
      }
    } = this.props
    appActions.updateLoader(true)
    appActions.updateLocale(appConstants.merchantDetails[merchant].locale)
    appActions.updateMerchant(merchant)
    document.getElementById('form-params').submit()
  }

  componentWillUnmount () {
    const { appActions } = this.props
    appActions.updateLoader(false)
  }

  render () {
    const {
      location: { search },
      match: {
        params: { merchant }
      }
    } = this.props
    const parsedQs = qs.parse(search)
    return (
      <div className='d-flex position-fixed h-100 w-100 flex-column align-items-center justify-content-center'>
        <form id='form-params' action={`${API_URL}/${merchant}`} method='post'>
          {Object.keys(parsedQs).map(key => {
            return <input type='hidden' name={key} value={parsedQs[key]} />
          })}
        </form>
      </div>
    )
  }
}

export const mapStateToProps = state => {
  return {}
}
export const mapDispatchToProps = dispatch => {
  return {
    appActions: bindActionCreators(appActions, dispatch)
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HandleRedirection)
