export const PHASE = {
  ENTER_PHONE_NUMBER: 1,
  VERIFY_PHONE_NUMBER: 2
}

export const maxOtpNumbers = 4
export const timeoutOtpVerification = 180

export const ERROR_OTP_RESEND_ATTEMPT_EXCEEDED = 'otp_resend_attempt_exceeded'

export const GET_APPLICATION_REQUEST_ID = 'STEP_1/GET_APPLICATION_REQUEST_ID'
export const GET_APPLICATION_REQUEST_ID_SUCCESS = 'STEP_1/GET_APPLICATION_REQUEST_ID_SUCCESS'
export const GET_APPLICATION_REQUEST_ID_FAIL = 'STEP_1/GET_APPLICATION_REQUEST_ID_FAIL'
export const UPDATE_TIME_REMAINING = 'STEP_1/UPDATE_TIME_REMAINING'

export const GET_PHONE_NUMBERS = 'STEP_1/GET_PHONE_NUMBERS'
export const GET_PHONE_NUMBERS_SUCCESS = 'STEP_1/GET_PHONE_NUMBERS_SUCCESS'
export const GET_PHONE_NUMBERS_FAIL = 'STEP_1/GET_PHONE_NUMBERS_FAIL'

export const UPDATE_PHONE_NUMBER = 'STEP_1/UPDATE_PHONE_NUMBER'
export const UPDATE_PHASE = 'STEP_1/UPDATE_PHASE'
export const UPDATE_OTP = 'STEP_1/UPDATE_OTP'

export const SEND_OTP = 'STEP_1/SEND_OTP'
export const SEND_OTP_SUCCESS = 'STEP_1/SEND_OTP_SUCCESS'
export const SEND_OTP_FAIL = 'STEP_1/SEND_OTP_FAIL'

export const VERIFY_OTP = 'STEP_1/VERIFY_OTP'
export const VERIFY_OTP_SUCCESS = 'STEP_1/VERIFY_OTP_SUCCESS'
export const VERIFY_OTP_FAIL = 'STEP_1/VERIFY_OTP_FAIL'

// KYC STATUS
export const LENDER_AGREED_STATUS = 'LENDER_AGREED'
export const KYC_PERURI_VERIFIED_STATUS = 'KYC_PERURI_VERIFIED'
