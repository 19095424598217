import styled from 'styled-components'

export const StyledButton = styled.button`
  background: #394dff;
  height: 60px;
  border: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  position: fixed;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  &:focus {
    outline: none;
  }
  z-index: 1;

  ${props =>
    props.disabled
      ? `
    background: #dcdcdc;
    color: #b1b1b1;
    cursor: not-allowed !important;
  `
      : ''};
`

