import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import Button from '@/components/Button'
import { bindActionCreators } from 'redux'
import { ResumeAppSubtitle } from './styledComponents'
import appResumeLoader from '@/static/images/spinner.gif'
import appResumeRejection from './resume-app-rejection.png'
import appResumeAPIFail from './resume-app-apifail.png'
import Layout from '@/components/Layout'
import * as selfConstants from './constants'
import * as selfActions from './actions'
import * as appActions from '@/containers/App/actions'
import * as helpers from '@/utils/helpers'

export class ResumeApp extends React.Component {
  componentDidMount () {
    const { selfActions } = this.props
    selfActions.getApplicationStatus()

    helpers.getLatLng()
  }

  handleTryAgain = () => {
    const { selfActions } = this.props
    selfActions.getApplicationStatus()
  }

  handleClickSupportCall = () => {
  }

  handleClickSupportEmail = () => {
  }

  renderSwitch (status) {
    switch (status) {
      case selfConstants.STATUS.APIFAILURE:
        return (
          <React.Fragment>
            <div className="title mt-2 font-weight-bold text-center">
              <FormattedMessage id="resumeapp.error.fail.title" />
            </div>
            <div className="mt-5">
              <img src={appResumeAPIFail} />
            </div>
            <ResumeAppSubtitle className="subtitle mt-5 text-center">
              <FormattedMessage id="resumeapp.error.fail.subtitle" />
            </ResumeAppSubtitle>
            <div className="mt-5">
              <Button onClick={this.handleTryAgain}>
                <FormattedMessage id="step2.btn.tryagain" />
              </Button>
            </div>
          </React.Fragment>
        )
      case selfConstants.STATUS.USERREJECTED:
        return (
          <React.Fragment>
            <div className="title mt-2 font-weight-bold text-center">
              <FormattedMessage id="resumeapp.error.rejected.title" />
            </div>
            <div className="subtitle mt-2 text-center">
              <FormattedMessage id="resumeapp.error.rejected.subtitle" />
            </div>
            <div className="mt-3">
              <img src={appResumeRejection} />
            </div>
            <div className="subtitle mt-4 text-center">
              <FormattedMessage id="resumeapp.error.rejected.message" />
            </div>
          </React.Fragment>
        )
      default:
        return (
          <React.Fragment>
            <div className="title font-weight-bold">
              <FormattedMessage id="resumeapp.title" />
            </div>
            <div className="mt-5">
              <img src={appResumeLoader} />
            </div>
            <ResumeAppSubtitle className="subtitle text-center mt-5">
              <FormattedMessage id="resumeapp.subtitle" />
            </ResumeAppSubtitle>
          </React.Fragment>
        )
    }
  }

  render () {
    const { status, ...rest } = this.props
    return (
      <Layout {...rest}>
        <div
          className="d-flex flex-column align-items-center pt-3 pb-4 px-4 mt-5">
          {this.renderSwitch(status)}
        </div>
      </Layout>
    )
  }
}

export const mapStateToProps = state => {
  return {
    status: state.resumeApp.status
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    selfActions: bindActionCreators(selfActions, dispatch),
    appActions: bindActionCreators(appActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResumeApp)
