
export const GET_PRODUCTS = 'STEP_4/GET_PRODUCTS'
export const GET_PRODUCTS_SUCCESS = 'STEP_4/GET_PRODUCTS_SUCCESS'
export const GET_PRODUCTS_FAIL = 'STEP_4/GET_PRODUCTS_FAIL'
export const ON_GET_PRODUCT_FAILED = 'STEP_4/ON_GET_PRODUCT_FAILED'
export const ON_PRODUCTS_SUCCESS = 'STEP_4/GET_PRODUCTS_SUCCESS'

export const GET_INSTALLMENTS = 'STEP_4/GET_INSTALLMENTS'
export const GET_INSTALLMENTS_SUCCESS = 'STEP_4/GET_INSTALLMENTS_SUCCESS'
export const GET_INSTALLMENTS_FAIL = 'STEP_4/GET_INSTALLMENTS_FAIL'

export const POST_PRODUCT_SELECTION = 'STEP_4/POST_PRODUCT_SELECTION'
export const POST_PRODUCT_SELECTION_SUCCESS = 'STEP_4/POST_PRODUCT_SELECTION_SUCCESS'
export const POST_PRODUCT_SELECTION_FAIL = 'STEP_4/POST_PRODUCT_SELECTION_FAIL'

export const UPDATE_CURRENT_CREDIT_AMOUNT =
  'STEP_4/UPDATE_CURRENT_CREDIT_AMOUNT'
export const UPDATE_SELECTED_PRODUCT_ID = 'STEP_4/UPDATE_SELECTED_PRODUCT_ID'
