import React, { Component } from 'react'
import { ConnectedRouter } from 'react-router-redux'
import { connect, Provider } from 'react-redux'
import App from '@/containers/App'
import { addLocaleData, IntlProvider } from 'react-intl'
import * as generatedMessages from '@/locales'
import ErrorBoundary from '@/components/ErrorBoundary'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor } from '@/store/configureStore'

import en from 'react-intl/locale-data/en'
import id from 'react-intl/locale-data/id'
import vi from 'react-intl/locale-data/vi'

addLocaleData([...en, ...id, ...vi])

class Index extends Component {
  render () {
    const {
      store,
      history,
      locale: { lang, code }
    } = this.props
    console.log(store)
    console.log(history)
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <IntlProvider
            key={code}
            locale={lang}
            messages={generatedMessages[code]}
          >
            <ConnectedRouter history={history}>
              <ErrorBoundary>
                <App />
              </ErrorBoundary>
            </ConnectedRouter>
          </IntlProvider>
        </PersistGate>
      </Provider>
    )
  }
}

export const mapStateToProps = state => {
  return {
    locale: state.app.locale
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    // appActions: bindActionCreators(appActions, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index)
