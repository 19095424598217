import React from 'react'
import { connect } from 'react-redux'
import * as router from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import icon from './failure-smiley.svg'
import Button from '@/components/Button'
import * as selfActions from '../../actions'
import * as ga from '@/utils/gtag'

export class ErrorPage extends React.Component {
  handleClickTryAgain = () => {
    const { token, selfActions } = this.props

    ga.event({
      category: 'click',
      action: 'repymt_try_again',
      label: 'button',
      value: 1
    })

    selfActions.getRepayments(token)
  };

  render () {
    return (
      <div>
        <div className='mt-5 text-center'>
          <h5>
            <strong>
              <FormattedMessage id='repayments.selectChannel.errorPage.title' />
            </strong>
          </h5>
        </div>
        <div className='text-center mt-4'>
          <img src={icon} />
        </div>
        <div className='mt-4 text-center'>
          <span>
            <FormattedMessage id='repayments.selectChannel.errorPage.msg' />
          </span>
        </div>
        <div className='mt-4 text-center'>
          <Button onClick={this.handleClickTryAgain}>
            <FormattedMessage id='repayments.selectChannel.errorPage.tryAgain' />
          </Button>
        </div>
      </div>
    )
  }
}

export const mapStateToProps = state => {
  return {}
}

export const mapDispatchToProps = dispatch => {
  return {
    router: bindActionCreators(router, dispatch),
    selfActions: bindActionCreators(selfActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorPage)
