import styled from 'styled-components'

export const ImageUploaderWrapper = styled.div`
  & > div[data-image-box="true"] {
    background: #ffffff;
    border: solid 1px #e3e3e3;
    border-radius: 5px;
    height: 100px;
    width: 300px;
    position: relative;
    cursor: pointer;
    img[data-thumbnail="true"] {
      width: 48px;
      height: 56px;
    }

    div[data-title="true"] {
      font-size: 14px;
      font-weight: bold;
      color: #4a4a4a;
    }
    div[data-subtitle="true"] {
      font-size: 12px;
      font-weight: normal;
      color: #4a4a4a;
    }
    input {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
    }
  }
`
export const WarningText = styled.div`
  color: #f68530;
`
export const SuccessText = styled.div`
  color: #5fa300;
`
export const FailureText = styled.div`
  color: #e02020;
`
export const UploadFailureText = styled.div`
  color: black;
  font-size: 19px;
`
export const InstructionsMessage = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c211f;
`
export const ImageHolder = styled.img`
  width: 244px;
  height: 244px;
`
