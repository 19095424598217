import * as selfConstants from './constants'
import { setDefaultLocale } from 'react-datepicker'

export const updateModal = payload => {
  return {
    type: selfConstants.UPDATE_MODAL,
    payload
  }
}
export const updateLoader = payload => {
  return {
    type: selfConstants.UPDATE_LOADER,
    payload
  }
}
export const updateLanguageCode = payload => {
  return {
    type: selfConstants.UPDATE_LANGUAGE_CODE,
    payload
  }
}
export const updateLocale = payload => {
  setDefaultLocale(payload)
  return {
    type: selfConstants.UPDATE_LOCALE,
    payload
  }
}
export const updateApplicationRequestId = payload => {
  return {
    type: selfConstants.UPDATE_APPLICATION_REQUEST_ID,
    payload
  }
}
export const updateIsResumable = payload => {
  return {
    type: selfConstants.UPDATE_IS_RESUMABLE,
    payload
  }
}
export const updateMerchant = payload => {
  return {
    type: selfConstants.UPDATE_MERCHANT,
    payload
  }
}
