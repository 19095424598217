import React from 'react'
import { connect } from 'react-redux'
// import * as selfActions from './actions'
import * as appActions from '@/containers/App/actions'
import { bindActionCreators } from 'redux'
import Cleave from 'cleave.js/react'
import { SuggestionList } from '../styledComponents'

export class PhoneInput extends React.Component {
  state = {
    showSuggestionList: false
  };

  idTimeout = null;

  handleOnChange = e => {
    const { onChange } = this.props
    onChange(e.target.rawValue)
  };

  handleOnClickSuggestionItem = phone => {
    const { onChange } = this.props
    onChange(phone)
    this.setState({ showSuggestionList: false })
  };

  handleOnFocusInput = () => {
    this.setState({ showSuggestionList: true })
    document.getElementById('phoneInputWrapper').scrollIntoView()
  };
  handleOnBlurInput = () => {
    this.idTimeout = setTimeout(() => {
      this.setState({ showSuggestionList: false })
    }, 100)
  };

  componentWillUnmount () {
    clearTimeout(this.idTimeout)
  }

  render () {
    const { showSuggestionList } = this.state
    const { value = '', suggestionList, className = '', disabled } = this.props
    return (
      <div className={`d-inline-block ${className}`}>
        <Cleave
          type='tel'
          disabled={disabled}
          className='input-control w-100 border-bottom-0 bg-transparent'
          placeholder='Enter your phone number'
          options={{
            numericOnly: true,
            delimiter: ' ',
            blocks: [3, 4, 4]
          }}
          onFocus={this.handleOnFocusInput}
          onBlur={this.handleOnBlurInput}
          value={value}
          onChange={this.handleOnChange}
        />
        {showSuggestionList &&
          suggestionList.length > 0 && (
          <SuggestionList>
            {suggestionList.map((phone, index) => {
              return (
                <div
                  key={index}
                  onClick={() => this.handleOnClickSuggestionItem(phone)}
                >
                  <Cleave
                    data-suggestion
                    className='w-100'
                    options={{
                      delimiter: ' ',
                      blocks: [3, 4, 4]
                    }}
                    value={phone}
                  />
                </div>
              )
            })}
          </SuggestionList>
        )}
      </div>
    )
  }
}

export const mapStateToProps = state => {
  return {}
}

export const mapDispatchToProps = dispatch => {
  return {
    appActions: bindActionCreators(appActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhoneInput)
