import React from 'react'
import { connect } from 'react-redux'
import * as appActions from '@/containers/App/actions'
import Card, { Heading } from '@/components/Card'
import Accordion from '@/components/Accordion'
import BackButton from '@/components/BackButton'
import HorizontalLine from '@/components/HorizontalLine'
import { bindActionCreators } from 'redux'
import * as selfActions from './actions'
import * as router from 'react-router-redux'
import { FormattedMessage } from 'react-intl'
import CopiableCode from './components/CopiableCode'
import MethodContent from './components/MethodContent'
import * as ga from '@/utils/gtag'
import ButtonLink from '@/components/ButtonLink'
import _isNull from 'lodash/isNull'

export class SelectMethod extends React.Component {
  componentDidMount () {
    ga.pageview({
      title: 'Repayment: Payment Code Display',
      location: '/repayments/id/select-method',
      path: '/repayments/id/select-method'
    })
  }

  handleOnChangeSelectChannelIndex = newIndex => {
    const { selfActions, channels, selectedChannelIndex } = this.props
    selfActions.updateSelectedChannelIndex(newIndex)
    if (_isNull(newIndex)) {
      // all items are collapsed
      const selectedChannelPaymentMode =
        channels[selectedChannelIndex].paymentMode
      ga.event({
        category: 'click',
        action: 'repymt_collapse_channel',
        label: selectedChannelPaymentMode,
        value: 1
      })
    } else {
      const newChannelPaymentMode = channels[newIndex].paymentMode
      ga.event({
        category: 'click',
        action: 'repymt_expand_channel',
        label: newChannelPaymentMode,
        value: 1
      })
    }
  };

  handleOnCodeCopied = code => {
    ga.event({
      category: 'click',
      action: 'repymt_payment_code',
      label: 'tap',
      value: 1
    })
  };

  handleClickBack = () => {
    const { router, token } = this.props
    router.push(`/repayments/${token}/select-channel`)
    ga.event({
      category: 'click',
      action: 'repymt_payment_channel_back',
      label: 'button',
      value: 1
    })
  };

  showMethodPopup = (method, code) => {
    const { appActions } = this.props
    appActions.updateModal({
      show: true,
      showHeader: true,
      header: <FormattedMessage id={method} />,
      body: <MethodContent method={method} code={code} />
    })
    ga.event({
      category: 'click',
      action: 'repymt_select_method',
      label: method,
      value: 1
    })
  };

  render () {
    const {
      selectedChannelIndex,
      curAmountToPay,
      locale: { lang, currencyConfig },
      channels
    } = this.props
    const numberFormatter = new Intl.NumberFormat(lang, {
      style: 'currency',
      ...currencyConfig
    })
    return (
      <div>
        <Card>
          <Heading>
            <FormattedMessage id='repayments.selectMethod.heading.paymentChannel' />
          </Heading>
          <HorizontalLine />
          <h6 className='py-2'>
            <FormattedMessage
              id='repayments.selectMethod.label.pleasePay'
              values={{
                amount: (
                  <strong>{numberFormatter.format(curAmountToPay)}</strong>
                )
              }}
            />
          </h6>
          <Accordion
            items={channels.map(channel => {
              const { displayText, code, methods } = channel
              return {
                heading: <div>{displayText}</div>,
                container: (
                  <div className='px-2'>
                    <div className='mt-2'>
                      <CopiableCode
                        code={code}
                        title={
                          <FormattedMessage id='repayments.selectMethod.label.paymentCode' />
                        }
                        onCodeClick={this.handleOnCodeCopied}
                      />
                    </div>
                    <div className='my-3'>
                      {methods.map(method => {
                        return (
                          <div key={method} className='mb-3'>
                            <ButtonLink
                              onClick={() => this.showMethodPopup(method, code)}
                            >
                              &#x25cf;&nbsp;
                              <FormattedMessage id={method} />
                            </ButtonLink>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              }
            })}
            activeIndex={selectedChannelIndex}
            onClickHeading={this.handleOnChangeSelectChannelIndex}
          />
        </Card>
        <BackButton onClick={this.handleClickBack} />
      </div>
    )
  }
}

export const mapStateToProps = state => {
  return {
    locale: state.app.locale,
    resultGetRepayments: state.repaymentsSelectChannel.resultGetRepayments,
    errorGetRepayments: state.repaymentsSelectChannel.errorGetRepayments,
    channels: state.repaymentsSelectChannel.channels,
    selectedChannelIndex: state.repaymentsSelectMethod.selectedChannelIndex,
    status: state.repaymentsSelectChannel.status,
    curAmountToPay: state.repaymentsSelectChannel.curAmountToPay
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    router: bindActionCreators(router, dispatch),
    appActions: bindActionCreators(appActions, dispatch),
    selfActions: bindActionCreators(selfActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectMethod)
