import React from 'react'
import { connect } from 'react-redux'
import * as appActions from '@/containers/App/actions'
import { bindActionCreators } from 'redux'
import Layout from '@/components/Layout'
import mapping from './phases'
import { Wrapper } from './styledComponents'

export class RepaymentsPage extends React.Component {

  render () {
    const {
      match: {
        params: { phase, token }
      },
      ...rest
    } = this.props
    const Phase = mapping[phase]
    return (
      <Layout {...rest}>
        <Wrapper>
          <Phase token={token} />
        </Wrapper>
      </Layout>
    )
  }
}

export const mapStateToProps = state => {
  return {}
}

export const mapDispatchToProps = dispatch => {
  return {
    appActions: bindActionCreators(appActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RepaymentsPage)
