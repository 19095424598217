import React from 'react'
import { FormattedMessage } from 'react-intl'

class UserConsentAgreement extends React.Component {
  render () {
    return (
      <p className="mb-0 small text-center">
        <FormattedMessage id="step4.userConsentAgreement.content"
                          values={{
                            name: <strong>PT. BPR ARTHAGUNA MANDIRI</strong>,
                            br: <br />,
                            linkPrivacy:
                              <a
                                href="https://privy.id/id/privacy-policy"
                                target="_blank"
                                rel="noopener noreferrer">Kebijakan Privasi</a>,
                            linkSnK:
                              <a
                                href="https://privy.id/id/terms-of-use"
                                target="_blank"
                                rel="noopener noreferrer">Syarat
                                dan Ketentuan
                                Privy</a>
                          }} />
      </p>
    )
  }
}

export default UserConsentAgreement
