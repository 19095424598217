import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { ImageHolder, InstructionsMessage } from '../../styledComponents'
import { bindActionCreators } from 'redux'
import * as ga from '@/utils/gtag'
// import Layout from '@/components/Layout'
import Button from '@/components/Button'
import * as selfActions from '../../actions'
import * as selfConstants from '../../constants'
import CameraHandler from '@/components/CameraHandler'

const icon = {
  kyc: require('./instruction-kyc.gif'),
  selfie: require('./instruction-selfie.gif')
}

class KTPInstructionsPage extends React.Component {
  constructor (props) {
    super(props)
    this.inputRef = React.createRef()
  }

  componentDidMount () {
    const { chosenType } = this.props
  }

  goToCamera = () => {
    const { chosenType, selfActions } = this.props
    const enableCustomizedCamera = false
    if (enableCustomizedCamera) {
      selfActions.changeStatus(selfConstants.STATUS.CAMERA_ON)
    } else {
      selfActions.changeStatus(selfConstants.STATUS.SHOW_KTP_CAMERA)
    }
  }

  render () {
    const { chosenType } = this.props
    return (
      <React.Fragment>
        <div className="mb-4 mt-5 text-center">
          <ImageHolder src={icon[chosenType]} />
        </div>
        <div className="title mt-2 font-weight-bold text-center">
          <FormattedMessage id={`step2.${chosenType}instructions.title`} />
        </div>
        <InstructionsMessage className="mt-4 text-center">
          <FormattedMessage id={`step2.${chosenType}instructions.message`} />
        </InstructionsMessage>
        <div className="mt-5 text-center">
          <Button onClick={this.goToCamera}>
            <FormattedMessage id="step2.btn.continuetocamera" />
          </Button>
        </div>
      </React.Fragment>
    )
  }
}

export const mapStateToProps = state => {
  return {
    chosenType: state.step2.chosenType
  }
}
export const mapDispatchToProps = dispatch => {
  return {
    selfActions: bindActionCreators(selfActions, dispatch)
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KTPInstructionsPage)
