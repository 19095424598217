import React from 'react'
import { connect } from 'react-redux'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import Layout from '@/components/Layout'
import Button from '@/components/Button'
import iconCongrats from './congrats.svg'
import { TitleText } from './styledComponents'
import * as appConstants from '@/containers/App/constants'


export class Confirmation extends React.Component {
  componentDidMount () {
    const { curCreditAmount } = this.props
    window.cdcm.loan_success = 1
    window.cdcm.loan_amount = curCreditAmount
  }

  goToTCash = () => {
    window.location.href = appConstants.TCASH_APP_LINK
  };

  render () {
    const {
      locale: { lang, currencyConfig },
      emiObjForSelectedAmount,
      ...rest
    } = this.props
    const formatter = new Intl.NumberFormat(lang, {
      style: 'currency',
      ...currencyConfig
    })

    const balanceAmount =
      emiObjForSelectedAmount.balance_amount || appConstants.NO_VALUE_SYMBOL

    return (
      <Layout {...rest}>
        <div className='d-flex flex-column align-items-center py-5 px-4 text-center'>
          <div className='title'>
            <TitleText>
              <FormattedMessage id='confirmation.title' />
            </TitleText>
          </div>
          <div className='subtitle mt-4'>
            <FormattedMessage
              id='confirmation.willBeCredited'
              values={{
                amount: (
                  <strong className='primary-color'>
                    {formatter.format(balanceAmount)}
                  </strong>
                )
              }}
            />
          </div>
          <div className='mt-4'>
            <img src={iconCongrats} />
          </div>
          <div className='subtitle mt-4'>
            <FormattedHTMLMessage id='confirmation.redirection' />
          </div>
          <div className='mt-5'>
            <Button onClick={this.goToTCash}>
              <FormattedMessage id='btn.goToTcash' />
            </Button>
          </div>
        </div>
      </Layout>
    )
  }
}

export const mapStateToProps = state => {
  return {
    locale: state.app.locale,
    emiObjForSelectedAmount: state.step4.emiObjForSelectedAmount,
    curCreditAmount: state.step4.curCreditAmount
  }
}

export const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Confirmation)
