import { supportedLocales } from '@/locales'

export default {
  curCreditAmount: supportedLocales.id.creditAmount.max,
  maxCreditAmount: supportedLocales.id.creditAmount.max,
  loanProducts: [],
  parsedFirstRepaymentDate: [],
  emiObjForSelectedAmount: {},
  isMonthlyInstallmentCalculated: false,
  creditAmount: 0,
  tenor: '',
  selectedProduct: '',
  interestRate: 0,
  interestSchedule: 'Tahun',
  dueDate: '',
  principalLoan: 0,
  adminFee: 0,
  insuranceFee: 0,
  disbursementTotal: 0,
  selectedProductId: 0,
  installment: '',
  currency: 'IDR',
  principal: '',
  loan_product_id: '',
  cycle_day: ''
}
