import initialState from './initialState'
import * as selfConstants from './constants'

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case selfConstants.GET_CONTRACT_SUCCESS: {
      const resultGetContract = action.payload
      return {
        ...state,
        status: selfConstants.GET_CONTRACT_SUCCESS,
        resultGetContract,
        token: ''
      }
    }
    case selfConstants.ACCEPT_CONTRACT_SUCCESS: {
      const resultGetContract = action.payload
      return {
        ...state,
        status: selfConstants.ACCEPT_CONTRACT_SUCCESS,
        token: resultGetContract.applicationRequestId
      }
    }
    case selfConstants.GET_CONTRACT_FAIL: {
      return {
        ...state,
        status: selfConstants.GET_CONTRACT_FAIL,
        error: action.payload.data
      }
    }
    case selfConstants.ACCEPT_CONTRACT_FAIL: {
      return {
        ...state,
        status: selfConstants.ACCEPT_CONTRACT_FAIL,
        error: action.payload.data
      }
    }
    default:
      return state
  }
}
export default reducer
