import styled from 'styled-components'

export const PhoneInputWrapper = styled.div`
  width: 100%;
  background: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 15px;
  ${props =>
    props.disabled
      ? `
    background: #E7E7E7;
    color: #8F8F8F;
  `
      : ``};
`

export const SuggestionList = styled.div`
  border: 1px solid #dbdbdb;
  border-top: 0;
  padding: 4px 15px;
  position: absolute;
  left: 0px;
  top: 55px;
  width: 100%;
  background: white;
  z-index: 1;
  input {
    border: none;
    padding: 12px 0;
  }
  & > div:not(:last-child) input {
    border-bottom: 1px solid #dbdbdb;
  }
`

//
// export const InfoPopup = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 59px;
//   background: #72b925;
//   color: #fff;
//   z-index: 2;
//   [data-button-close="true"] {
//     font-size: 28px;
//   }
// `
