import React from 'react'
import { connect } from 'react-redux'
import iconNotFound from './404.svg'
import Layout from '@/components/Layout'
import { FormattedMessage } from 'react-intl'
import { NotFoundMessage, NotFoundSubtitle } from './styledComponents'
import * as ga from '@/utils/gtag'
import qs from 'query-string'

class Index extends React.Component {
  componentDidMount () {
    ga.pageview({
      title: 'Error page: not found',
      location: '/not-found',
      path: '/not-found'
    })
  }

  render () {
    const search = this.props.location.search
    const parsedQs = qs.parse(search)
    const errorId = parsedQs.errorId
      ? `notfound.error.${parsedQs.errorId}`
      : 'notfound.error.default'
    return (
      <Layout {...this.props}>
        <div
          className="d-flex flex-column align-items-center py-3 px-4 text-center">
          <div className="mt-2">
            <img src={iconNotFound} />
          </div>
          <div className="title mt-2 font-weight-bold">
            <FormattedMessage id="notfound.title" />
          </div>
          <NotFoundSubtitle>
            <FormattedMessage id="notfound.pagenotfound" />
          </NotFoundSubtitle>
          <NotFoundMessage className="mt-4">
            <FormattedMessage id={errorId} />
          </NotFoundMessage>
        </div>
      </Layout>
    )
  }
}

export const mapStateToProps = state => {
  return {}
}
export const mapDispatchToProps = dispatch => {
  return {}
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index)
