import React from 'react'
import { FormattedMessage } from 'react-intl'
import * as selfActions from '@/containers/Steps/5/actions'
import * as appActions from '@/containers/App/actions'
import { bindActionCreators } from 'redux'
import * as router from 'react-router-redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Button from '@/components/Button'
import ButtonCancel from '@/components/ButtonCancel'
import _get from 'lodash/get'
import Checkbox from '@/components/Checkbox'
import ojkLogo from '@/static/images/ojk-logo.svg'
import { TermsAndConditions } from '../styledComponents'
import * as ga from '@/utils/gtag'
import { Document, Page } from 'react-pdf'

class ContractLinkAja extends React.Component {
  state = {
    agreeTC: false,
    readTC: false,
    numPages: null,
    pageNumber: 1
  };
  readTC = false;
  handleToggleAcceptTermsAndConditions = newValue => {
    this.setState({ agreeTC: newValue && this.readTC })
  };

  handleAgreeContract = () => {
    const { selfActions } = this.props
    selfActions.acceptContract()
  };

  handleRejectContract = () => {
    const { selfActions } = this.props
    selfActions.rejectContract()
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages })
  }

  isBottom(el) {
    // return el.getBoundingClientRect().bottom <= el.innerHeight
    let btn = document.getElementById('divInnerContarct').getBoundingClientRect().bottom <= el.getBoundingClientRect().bottom
    if (el.getBoundingClientRect().bottom <= 288) {
      return false
    } else {
      return btn
    }
  }
  componentDidMount() {
    this.readTC = false
    console.log('contract', this.props.resultGetContract)
    document.addEventListener('scroll', this.trackScrolling, true)
  }

  componentWillUnmount() {
    this.readTC = false
    // document.removeEventListener('scroll', this.trackScrolling)
  }

  // trackScrolling = () => {
  //   const wrappedElement = document.getElementById('divContract')
  //   if (this.isBottom(wrappedElement)) {
  //     console.log('divContract bottom reached')
  //     document.removeEventListener('scroll', this.trackScrolling, true)
  //     this.readTC = true
  //   }
  // };
  tncRef = React.createRef()
  onScroll = () => {
    const scrollTop = this.tncRef.current.scrollTop
    const scrollHeight = this.tncRef.current.scrollHeight
    // console.log('divContract st' + scrollTop)
    // console.log('divContract sh' + scrollHeight)
    if (scrollTop >= (scrollHeight - 500)) {
      console.log('divContract reached')
      this.readTC = true
    }
  };

  render() {
    const { agreeTC, readTC, pageNumber } = this.state
    const contractHtml = _get(
      this.props,
      'resultGetContract.html'
    )
    const aStyle = {
      color: 'blue',
      marginTop: '10px'
    }
    // console.log('html: ', contractHtml)
    return (
      <React.Fragment>
        <div className='mt-2' >
          {/*<TermsAndConditions className='scrollable'>*/}
          {/*  <Document style={{overflow: 'scrollable'}}*/}
          {/*    file={'data:application/pdf;base64,' + contractHtml}*/}
          {/*    onLoadSuccess={this.onDocumentLoadSuccess}*/}
          {/*  >*/}
          {/*    <Page pageNumber={pageNumber} />*/}
          {/*  </Document>*/}
          {/*</TermsAndConditions>*/}
          <TermsAndConditions className='scrollable' ref={this.tncRef} id='divContract' onScroll={this.onScroll}>
            <div id='divInnerContarct' dangerouslySetInnerHTML={{ __html: contractHtml }} />
          </TermsAndConditions>
        </div>

        <div className='mt-2'>
          <Checkbox
            disabled={!readTC}
            data-test-id='cb-agreeTC'
            checked={agreeTC}
            label={<FormattedMessage id='step5.label.agreeT&C' />}
            onChange={this.handleToggleAcceptTermsAndConditions}
          />
        </div>

        <div className='mt-2'>
          <Button
            disabled={!agreeTC}
            data-test-id='btn-proceed'
            onClick={this.handleAgreeContract}
          >
            <FormattedMessage id='step5.btn.proceed' />
          </Button>
          <br />
          <ButtonCancel
            disabled={agreeTC}
            data-test-id='btn-cancel'
            onClick={this.handleRejectContract}
          >
            <FormattedMessage id='step5.btn.cancel' />
          </ButtonCancel>
        </div>
        <div className='mt-2'>
          <a style={aStyle} target='_blank' href='https://privy.id/privacy-policy'>
            Privy.id Privacy Policy
          </a>
        </div>
        <div className='mt-4'>
          <img src={ojkLogo} />
        </div>
      </React.Fragment>
    )
  }
}

export const mapStateToProps = state => {
  return {
    resultGetContract: state.step5.resultGetContract
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    selfActions: bindActionCreators(selfActions, dispatch),
    appActions: bindActionCreators(appActions, dispatch),
    router: bindActionCreators(router, dispatch)
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ContractLinkAja)
)
