import styled from 'styled-components'

export const Title = styled.span`
  color: #575757;
`
export const Subtitle = styled.span`
  color: #b3b3b3;
`
export const CodeArea = styled.div`
  border: dashed 1px #e2e2e2;
  background-color: #f8f8f8;
  color: #1f2020;
  font-size: 25px;
  font-weight: bold;
  border-radius: 5px;
  height: 50px;
  line-height: 50px;
  text-align: center;
`
