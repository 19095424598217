import React from 'react'
import { connect } from 'react-redux'
// import * as selfActions from './actions'
import * as appActions from '@/containers/App/actions'
import { bindActionCreators } from 'redux'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import closeIcon from './close-circular-button-symbol.svg'
import { CloseButton } from './styledComponents'
import Button from '@/components/Button'
import ButtonCancel from '@/components/ButtonCancel'
import Checkbox from '@/components/Checkbox'
import { Link } from 'react-router-dom'
import * as router from 'react-router-redux'
import qs from 'query-string'
import Card from '../../components/Card'
import Layout from '../../components/Layout'
import BottomLayout from '../../components/BottomLayout'

export class TermsAndConditions extends React.Component {
  state = {
    agreeTC: false
  }
  handleToggleAcceptTermsAndConditions = newValue => {
    this.setState({ agreeTC: newValue })
  }

  componentDidMount () {
  }

  handleOnApproved = () => {
    const {
      router,
      applicationRequestId,
      location: { search }
    } = this.props
    // const parsedQs = qs.parse(search)
    router.push(`/step/3`)
  }

  handleOnReject = () => {
    // const {
    //   router,
    //   applicationRequestId,
    //   location: { search }
    // } = this.props
    // console.log(applicationRequestId)
    // router.push(`https://trustiq.id/`)
    window.location.replace('https://trustiq.id/')
  }

  render () {
    const mystyle = {
      margin: 'auto',
      width: '60%',
      padding: '10px'
    }
    const { agreeTC } = this.state
    const {
      location: { search }
    } = this.props
    const fromOtherPage = search.includes('from')
    return (
      <Layout>
        <Card><FormattedHTMLMessage id="tc.content" />
          <BottomLayout>
            <div className="d-flex flex-column align-items-center w-100 pb-4">
              <div className="mt-8">
                <Checkbox
                  data-test-id="cb-agreeTC"
                  checked={agreeTC}
                  label={<FormattedMessage id="step1.label.agreeT&C" />}
                  onChange={this.handleToggleAcceptTermsAndConditions}
                />
              </div>
              <br />
              <div className="mt-8">
                <Button
                  disabled={!agreeTC}
                  data-test-id="btn-proceed"
                  onClick={this.handleOnApproved}
                >
                  <FormattedMessage id="step1.btn.proceed" />
                </Button>
                <br />
                <ButtonCancel
                  disabled={agreeTC}
                  data-test-id="btn-cancel"
                  onClick={this.handleOnReject}
                >
                  <FormattedMessage id="step1.btn.cancel" />
                </ButtonCancel>
              </div>
            </div>
            {/*<div className='mt-4'>*/}
            {/*  <div className="d-flex flex-row-reverse">*/}
            {/*    <div className="p-2">*/}
            {/*      /!*<button style={{ background: 'none', border: 0, color: '#32a89e' }} onClick={this.handleOnApproved}>*!/*/}
            {/*      /!*  Setuju*!/*/}
            {/*      /!*</button>*!/*/}
            {/*      <Button*/}
            {/*        data-test-id='btn-proceed'*/}
            {/*        onClick={this.handleOnApproved}*/}
            {/*      >*/}
            {/*        <FormattedMessage id='step1.btn.proceed' />*/}
            {/*      </Button>*/}
            {/*    </div>*/}
            {/*    /!*<button style={{ background: 'none', border: 0, color: '#32a89e' }}onClick={this.handleOnReject}>*!/*/}
            {/*    /!*  Tidak*!/*/}
            {/*    /!*</button>*!/*/}
            {/*    <ButtonCancel*/}
            {/*      data-test-id='btn-cancel'*/}
            {/*      onClick={this.handleOnReject}*/}
            {/*    >*/}
            {/*      <FormattedMessage id='step1.btn.cancel' />*/}
            {/*    </ButtonCancel>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </BottomLayout>
        </Card>
      </Layout>
    )
  }
}

export const mapStateToProps = state => {
  return {
    applicationRequestId: state.app.applicationRequestId
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    router: bindActionCreators(router, dispatch),
    appActions: bindActionCreators(appActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TermsAndConditions)
