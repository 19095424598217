export const getAge = inputDate => {
  let currentYear = new Date().getFullYear()
  let inputYear = inputDate.getFullYear()
  return currentYear - inputYear
}

export const DEFAULT_SELECT_WIDGET_VALUE = 'Select';

export const GET_KYC_FORM = 'STEP_2/GET_KYC_FORM'
export const GET_KYC_FORM_SUCCESS = 'STEP_2/GET_KYC_FORM_SUCCESS'
export const GET_KYC_FORM_FAIL = 'STEP_2/GET_KYC_FORM_FAIL'

export const GET_CITIES = 'STEP_2/GET_CITIES'
export const GET_CITIES_SUCCESS = 'STEP_2/GET_CITIES_SUCCESS'
export const GET_CITIES_FAIL = 'STEP_2/GET_CITIES_FAIL'

export const GET_SUBDISTRICTS = 'STEP_2/GET_SUBDISTRICTS'
export const GET_SUBDISTRICTS_SUCCESS = 'STEP_2/GET_SUBDISTRICTS_SUCCESS'
export const GET_SUBDISTRICTS_FAIL = 'STEP_2/GET_SUBDISTRICTS_FAIL'

export const GET_VILLAGES = 'STEP_2/GET_VILLAGES'
export const GET_VILLAGES_SUCCESS = 'STEP_2/GET_VILLAGES_SUCCESS'
export const GET_VILLAGES_FAIL = 'STEP_2/GET_VILLAGES_FAIL'

export const SET_KYC_FORM_DATA = 'STEP_2/SET_KYC_FORM_DATA'
export const SET_KYC_FORM_SCHEMA = 'STEP_2/SET_KYC_FORM_SCHEMA'
export const SET_KYC_FORM_DATA_HOW_TO_REACH = 'STEP_2/SET_KYC_FORM_DATA_HOW_TO_REACH'
export const SET_KYC_FORM_IS_TOUCHED = 'STEP_2/SET_KYC_FORM_IS_TOUCHED'

export const SUBMIT_KYC_FORM = 'STEP_2/SUBMIT_KYC_FORM'
export const SUBMIT_KYC_FORM_SUCCESS = 'STEP_2/SUBMIT_KYC_FORM_SUCCESS'
export const SUBMIT_KYC_FORM_FAIL = 'STEP_2/SUBMIT_KYC_FORM_FAIL'
export const SUBMIT_KYC_FORM_LOAN_REJECTED = 'STEP_2/SUBMIT_KYC_FORM_LOAN_REJECTED'
export const SUBMIT_KYC_FORM_API_FAIL = 'STEP_2/SUBMIT_KYC_FORM_API_FAIL'
export const STATUS = {
  FAILURE: 'FAILURE',
  LOANREJECTED: 'LOANREJECTED'
}

export const KTP_ERR_MSG_FIRST_4_DIGITS = 'does not have a valid city code'
export const KTP_ERR_MSG_GENDER = 'Birthday does not match KTP digits'

export const enumFields = {
  "city": { "code": "city_code" },
  "sub_district": { "code": "sub_district_code" },
  "village": { "code": "village_code" }
};

export const fieldNameToFieldLabelMap = {
  "city": "City",
  "sub_district": "Sub District",
  "village": "Village"
}
