import React, { PureComponent } from "react";
import DatePicker from "react-datepicker";
import { FormattedMessage } from "react-intl";
import { ErrorText, FormInput, FormSelect } from "./styledComponents.js";
import caretDown from "./caret-down.svg";
import * as selfConstants from './constants'
import deleteAllIcon from './delete-all.svg'

const ErrorComponent = ({ error, showError = false }) => {
  // Phone number's validation is handled on the frontend and it's error messages
  // need to be internationalized on the frontend and the validation returns
  // i18n keys as error messages. We filter them and put them in FormattedMessages.
  const isValidationHandledOnFrontend = error && error.indexOf("step3.error") !== -1;
  return (
    <React.Fragment>
      {showError && (
        <ErrorText className={"d-inline-block"}>
          {isValidationHandledOnFrontend ? <FormattedMessage id={error} /> : <span>{error}</span>}
        </ErrorText>
      )}
    </React.Fragment>
  );
};

const generatePropsForErrorComponent = (id, errors, isTouched) => {
  const hasError = errors && errors.length > 0;
  const error = hasError ? errors[0] : undefined;
  const fieldIsTouched = isTouched.indexOf(id) !== -1;
  const showError = hasError && fieldIsTouched;
  return [error, showError];
};

export const InputWidget = ({
  id,
  value,
  options,
  onFocus,
  onBlur,
  rawErrors: errors,
  formContext: { isTouched },
  ...props
}) => {
  const _onChange = ({ target: { value } }) => {
    return props.onChange(value === "" ? options.emptyValue : value);
  };

  const [error, showError] = generatePropsForErrorComponent(
    id,
    errors,
    isTouched
  );

  // if "showX is not defined as false" explicitly,
  // X button will always be visible.
  const isXButtonDisabled = options.showX === false;

  const isTextPresentInField = typeof value === 'string' && value.length > 0
  const showXButton = isXButtonDisabled ? false : isTextPresentInField

  const onClickXButton = () => _onChange({ target: { value: ''}})
  return (
    <div className="pl-0 pr-3">
      <FormInput
        // This prevents chrome from autofilling fields
        autoComplete="new-password"
        id={id}
        type="text"
        className={`w-100 input-control ${showError ? "has-error" : ""}`}
        value={value == null ? "" : value}
        onBlur={() => onBlur(id, value)}
        onFocus={() => onFocus(id, value)}
        onChange={_onChange}
      />
      { showXButton && (
        <img
          alt={`clear-text-button-${id}`}
          data-btn-delete-all
          src={deleteAllIcon}
          onClick={onClickXButton}
        />
      )}
      <ErrorComponent error={error} showError={showError} />
    </div>
  );
};

export const DatePickerWidget = ({
  id,
  value,
  onFocus,
  onBlur,
  onChange,
  rawErrors: errors,
  formContext: { isTouched }
}) => {
  const [error, showError] = generatePropsForErrorComponent(
    id,
    errors,
    isTouched
  );
  // This is a workaround to fix bug with DatePicker it triggers 
  // onBlur two times, one before and after onChange and this
  // results in value of date being set to initial value then updated
  // value and then again initial value. Test is added for the bug
  // so that it doesn't regress.
  const _onFocus = () => {
    onFocus(id, value);
    onBlur(id, value);
  };
  return (
    <div className="pl-0 pr-3">
      <DatePicker
        id={id}
        peekNextMonth
        withPortal
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        dateFormat="dd-MM-yyyy"
        placeholderText="dd-mm-yyyy"
        className={`w-100 input-control ${showError ? "has-error" : ""}`}
        onFocus={_onFocus}
        onChange={dateObject => onChange(dateObject.toString())}
        selected={value && new Date(value)}
      />
      <ErrorComponent error={error} showError={showError} />
    </div>
  );
};

export const RadioWidget = ({
  id,
  options,
  onFocus,
  onBlur,
  value: selectedValue,
  label: name,
  onChange,
  rawErrors: errors,
  formContext: { isTouched },
  ...props
}) => {
  const { enumOptions: enums } = options;
  const [error, showError] = generatePropsForErrorComponent(
    id,
    errors,
    isTouched
  );
  return (
    <div className="w-100 pl-0 pr-3">
      {enums.map(({ label, value }, index) => (
        <label htmlFor={label} key={label} key={index + label} className={`ml-0 mr-4 ${showError ? "has-error" : ""}`}>
          <input
            id={label}
            type="radio"
            className="mr-1"
            name={name}
            value={value}
            checked={selectedValue === value}
            onBlur={() => onBlur(id, selectedValue)}
            onFocus={() => onFocus(id, selectedValue)}
            onChange={event => onChange(event.target.value)}
          />
          {label}
        </label>
      ))}
      <ErrorComponent error={error} showError={showError} />
    </div>
  );
};

export const SelectWidget = ({
  id,
  options,
  onFocus,
  onBlur,
  value: selectedValue,
  onChange,
  formContext: { isTouched },
  rawErrors: errors
}) => {
  const { enumOptions: enums } = options;
  const [error, showError] = generatePropsForErrorComponent(
    id,
    errors,
    isTouched
  );
  return (
    <div className="pl-0 pr-3">
      <div className="position-relative">
        <img className="caret" src={caretDown} />
        <FormSelect
          id={id}
          value={selectedValue}
          onBlur={() => onBlur(id, selectedValue)}
          onFocus={() => onFocus(id, selectedValue)}
          onChange={event => onChange(event.target.value)}
          className={`w-100 input-control ${showError ? "has-error" : ""}`}
        >
          {enums.map(({ label, value }, index) => {
            // I18n for Select option in all select boxes.
            const isSelect = value === selfConstants.DEFAULT_SELECT_WIDGET_VALUE;
            if (isSelect) {
              return (
              <FormattedMessage id="step3.select" key={index}>
                {message => <option value={value}>{message}</option>}
              </FormattedMessage>)
            }

            // Normal cases
            return (
              <option value={value} key={index + label}>
                {label}
              </option>
            )
          }
          )}
        </FormSelect>
        <ErrorComponent error={error} showError={showError} />
      </div>
    </div>
  );
};
