/* eslint-disable import/default */

import React from 'react'
import { render } from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import * as Sentry from '@sentry/browser'
import { history, store } from '@/store/configureStore'
import Root from './components/Root'
// import './styles/styles.scss' // Yep, that's right. You can import SASS/CSS files too! Webpack will run the associated loader and plug this into the page.
require('./favicon.ico') // Tell webpack to load favicon.ico
require('babel-core/register')
require('babel-polyfill')

try {
  Sentry.init({
    dsn: SENTRY_DSN
  })
} catch (e) {}

function runMyApp () {
  render(
    <AppContainer>
      <Root store={store} history={history} />
    </AppContainer>,
    document.getElementById('app')
  )

  if (module.hot) {
    module.hot.accept('./components/Root', () => {
      const NewRoot = require('./components/Root').default
      render(
        <AppContainer>
          <NewRoot store={store} history={history} />
        </AppContainer>,
        document.getElementById('app')
      )
    })
  }
}

if (!global.Intl) {
  require.ensure(
    [
      'intl',
      'intl/locale-data/jsonp/en.js',
      'intl/locale-data/jsonp/id.js',
      'intl/locale-data/jsonp/vi.js'
    ],
    function (require) {
      require('intl')
      require('intl/locale-data/jsonp/en.js')
      require('intl/locale-data/jsonp/id.js')
      require('intl/locale-data/jsonp/vi.js')
      runMyApp()
    }
  )
} else {
  runMyApp()
}
