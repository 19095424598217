import styled from 'styled-components'

const ButtonLink = styled.span.attrs({
  className: 'primary-color'
})`
  cursor: pointer;
  font-size: 14px;
`

export default ButtonLink
