import { createTransform } from 'redux-persist'

// don't use cached data of modal and loader to avoid bugs
export const ModalTransform = createTransform(
  null,
  (outboundState, key) => {
    return {
      ...outboundState,
      modal: {},
      showLoader: false,
    }
  },
  { whitelist: ['app'] }
)
