import styled from 'styled-components'
import { primaryColor } from '@/containers/App/globalStyles'

export const StyledFormContainer = styled.div`
  width: 100%;
  max-width: 680px;
  padding-top: 10px;
`
export const FieldBox = styled.div`
  padding-left: 10px;
`
export const FormLabel = styled.label`
  color: #797979;
  font-size: 12px;
  margin: 0px;
`
export const ErrorText = styled.div`
  color: #e02020;
  text-align: right;
  width: 100%;
  font-size: 11px;
`
export const WarningText = styled.div`
  color: #f68530;
  text-align: right;
`
export const RadioLabel = styled.label`
  width: 100%;
`
export const FormInput = styled.input`
  &:focus {
    border-bottom: 1px solid #394dff;
  }
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  ::-webkit-clear-button {
    display: none;
    -webkit-appearance: none;
  }
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 1.2em;
  & + [data-btn-delete-all="true"] {
    pointer: cursor;
    position: absolute;
    right: 15px;
    padding: 5px;
  }
`
export const FormSelect = styled.select`
  background-color: transparent;
  display: inline-block;
  line-height: 1.5em;
  margin: 0;
  box-sizing: border-box;
  padding-left: 0px;
  ${props => props.value === '' ? `color: #797979` : ''};
`
export const UploadFailureText = styled.div`
  color: black;
  font-size: 19px;
`

export const ButtonWrapper = styled.div`
  padding-left: 10px;
  margin-left: -10px;
  padding-right: 10px;
  margin-right: -10px;
  background: ${primaryColor};
`
export const CardBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding-left: 10px;
`