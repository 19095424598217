export en from './en.json'
export id from './id.json'
export vi from './vi.json'

export const supportedLocales = {
  en: {
    code: 'en',
    lang: 'en-US',
    countryCode: 62,
    currencyConfig: {
      currency: 'USD',
      minimumFractionDigits: 0
    },
    creditAmount: {
      step: 100000,
      min: 500000,
      max: 10000000
    },
    pageLoanStatus: {
      repaymentLink: 'https://trustiq.id/faq-en.html#repayments'
    }
  },
  id: {
    code: 'id',
    lang: 'id-ID',
    countryCode: 62,
    currencyConfig: {
      currency: 'IDR',
      minimumFractionDigits: 0
    },
    creditAmount: {
      step: 100000,
      min: 500000,
      max: 10000000
    },
    pageLoanStatus: {
      repaymentLink: 'https://trustiq.id/faq.html#repayments'
    }
  },
  vi: {
    code: 'vi',
    lang: 'vi-VN',
    countryCode: 84,
    currencyConfig: {
      currency: 'VND',
      minimumFractionDigits: 0
    },
    creditAmount: {
      step: 100000,
      min: 500000,
      max: 10000000
    },
    pageLoanStatus: {
      repaymentLink: 'https://trustiq.id/faq.html#repayments'
    }
  }
}
