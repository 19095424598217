import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as appActions from '@/containers/App/actions'
import { FormattedMessage } from 'react-intl'
import Button from '@/components/Button'

class APIErrorContent extends React.Component {
  closePopup = () => {
    const { appActions } = this.props
    appActions.updateModal({ show: false })
  };

  render () {
    return (
      <div>
        <div className='text-center'>
          <h5>
            <strong>
              <FormattedMessage id='popup.apiErrorContent.title' />
            </strong>
          </h5>
        </div>
        <div className='text-center'>
          <div className='mt-3'>
            <div>
              <FormattedMessage id='popup.apiErrorContent.message' />
            </div>
          </div>
        </div>
        <div className='text-center mt-4'>
          <Button
            data-test-id='btn-close'
            onClick={this.closePopup}
          >
            <FormattedMessage id='popup.apiErrorContent.btn.tryagain' />
          </Button>
        </div>
      </div>
    )
  }
}

export const mapStateToProps = state => {
  return {
    locale: state.app.locale,
    resultGetProducts: state.step4.resultGetProducts,
    selectedProductId: state.step4.selectedProductId
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    appActions: bindActionCreators(appActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(APIErrorContent)
