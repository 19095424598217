import React from 'react'
import PropTypes from 'prop-types'
import { OTPTextBox, Wrapper } from './styledComponents'

const numericValidation = /\D/

class OTPInput extends React.Component {
  constructor (props) {
    super(props)
    this.inputRef = React.createRef()
    this.state = {
      focusedBoxIndex: null
    }
  }

  handleOnClick = (e, index) => {
    this.setState({
      focusedBoxIndex: index
    })
    this.inputRef.current.focus()
    this.inputRef.current.setSelectionRange(index, index)
  };
  handleOnBlur = () => {
    this.setState({ focusedBoxIndex: null })
  };
  handleOnChange = e => {
    const { onChange, value } = this.props
    const { focusedBoxIndex } = this.state
    const newOTP = [...value]

    const newChar = e.target.value[focusedBoxIndex]
    if (!numericValidation.test(newChar)) {
      newOTP[focusedBoxIndex] = newChar
      const nextElementSibling = this[`otpTextBox${focusedBoxIndex}`]
        .nextElementSibling
      if (nextElementSibling && newChar !== '') {
        setTimeout(() => {
          nextElementSibling.click()
        })
      }
      onChange(newOTP)
    }
  };

  render () {
    const { maxNumbers, value, valid } = this.props
    const { focusedBoxIndex } = this.state
    const totalInputDigits = Array.from(
      new Array(maxNumbers),
      (val, index) => index + 1
    )
    return (
      <Wrapper onBlur={this.handleOnBlur}>
        {totalInputDigits.map((item, index) => {
          return (
            <OTPTextBox
              ref={e => (this[`otpTextBox${index}`] = e)}
              key={item}
              id={'phone' + item}
              focused={index === focusedBoxIndex}
              onClick={e => this.handleOnClick(e, index)}
              valid={valid}
            >
              <strong>{value[index]}</strong>
            </OTPTextBox>
          )
        })}
        <input
          data-hidden-input
          ref={this.inputRef}
          value={value.join('')}
          type='tel'
          onChange={this.handleOnChange}
        />
      </Wrapper>
    )
  }
}

OTPInput.propTypes = {
  value: PropTypes.array,
  valid: PropTypes.bool,
  maxNumbers: PropTypes.number,
  onChange: PropTypes.func
}

export default OTPInput
