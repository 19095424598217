import React from 'react'
import { StyledButton } from './styledComponents'

class ButtonFixed extends React.Component {
  componentDidMount () {
    document.body.style.paddingBottom = '100px'
  }

  componentWillUnmount () {
    document.body.style.paddingBottom = ''
  }

  render () {
    const { children, ...rest } = this.props
    return <StyledButton {...rest}>{children}</StyledButton>
  }
}

export default ButtonFixed
