import React from 'react'
import overlay from '../head.svg'

/**
 * Photo overlay for ekyc.
 * @return {JSX.Element}
 * @constructor
 */
export function PhotoOverlay ({ style, ...props }) {
  return <img src={overlay}
              alt="Ekyc indicator"
              style={{ opacity: 0.5, ...style }} {...props} />
}
