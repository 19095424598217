import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as appActions from '@/containers/App/actions'
import { FormattedMessage } from 'react-intl'
import { AttemptText } from './styledComponents'
import blockedIcon from './blocked-icon.svg'
import * as ga from '@/utils/gtag'

class PopupBlocked extends React.Component {
  componentDidMount () {
    ga.event({
      category: 'occurrence',
      action: 'step3',
      label: 'blocked_popup',
      value: 1
    })
  }

  handleClickSupportCall = () => {
    ga.event({
      category: 'click',
      action: 'step3_blocked_popup_support',
      label: 'call',
      value: 1
    })
  }

  handleClickSupportEmail = () => {
    ga.event({
      category: 'click',
      action: 'step3_blocked_popup_support',
      label: 'email',
      value: 1
    })
  }

  render () {
    return (
      <div className="text-center">
        <div>
          <h5>
            <strong>
              <FormattedMessage id="step3.popup.blocked.title" />
            </strong>
          </h5>
        </div>
        <div className="mt-3">
          <AttemptText>
            <FormattedMessage id="step3.popup.blocked.attempts" />
          </AttemptText>
        </div>
        <div className="mt-3">
          <img src={blockedIcon} />
        </div>
        <div className="mt-3">
          <FormattedMessage id="step3.popup.blocked.message" />
        </div>
      </div>
    )
  }
}

export const mapStateToProps = state => {
  return {}
}

export const mapDispatchToProps = dispatch => {
  return {
    appActions: bindActionCreators(appActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PopupBlocked)
