import React from 'react'
import { FormattedHTMLMessage } from 'react-intl'

class MethodContent extends React.Component {
  render () {
    const { method, code } = this.props
    return (
      <div>
        <FormattedHTMLMessage id={`${method}.content`} values={{ code }} />
      </div>
    )
  }
}

export default MethodContent
