import styled from 'styled-components'

export const Wrapper = styled.span`
  padding: 1rem;
  padding-bottom: 0;
  position: relative;
`
export const Title = styled.span`
  font-size: 18px;
  font-weight: bold;
`
export const CloseButton = styled.span`
  top: 9px;
  right: 9px;
  padding: 0px 3px;
  position: absolute;
`
