import React from 'react'
import { connect } from 'react-redux'
import * as appActions from '@/containers/App/actions'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import iconLoanRejected from './icon-loan-rejected.svg'
import iconLoanAccepted from './icon-loan-accepted.svg'
import Layout from '@/components/Layout'
import qs from 'query-string'
import _get from 'lodash/get'

const statuses = {
  accepted: {
    icon: iconLoanAccepted
  },
  rejected: {
    icon: iconLoanRejected
  }
}

export class LoanStatusPage extends React.Component {
  componentDidMount () {
    const {
      location: { search }
    } = this.props
    const { status } = qs.parse(search)

    if (status === 'accepted') {
      window.cdcm.user_return_type = '4'
    }
  }

  handleClickSupportCall = () => {
    const {
      location: { search }
    } = this.props
    const { status } = qs.parse(search)
  }

  handleClickSupportEmail = () => {
    const {
      location: { search }
    } = this.props
  }

  render () {
    const {
      locale: {
        pageLoanStatus: { repaymentLink }
      },
      ...rest
    } = this.props
    const { status } = qs.parse(this.props.location.search)
    const icon = _get(statuses, `${status}.icon`)
    return (
      <Layout noSeparateLine {...rest}>
        <div className="mt-3 mb-4">
          <img src={icon} />
        </div>
        <div className="title text-center">
          <h5>
            <strong>
              <FormattedMessage id={`loanStatus.${status}.label`} />
            </strong>
          </h5>
        </div>
        <div className="subtitle text-center">
          <FormattedMessage
            id={`loanStatus.${status}.text`}
            values={{
              link: (
                <a href={repaymentLink} target="__blank">
                  <FormattedMessage id="loanStatus.accepted.linkText" />
                </a>
              )
            }}
          />
        </div>
      </Layout>
    )
  }
}

export const mapStateToProps = state => {
  return {
    locale: state.app.locale
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    appActions: bindActionCreators(appActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoanStatusPage)
