import React from 'react'
import { connect } from 'react-redux'
import * as router from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import * as selfActions from '../../actions'
import * as ga from '@/utils/gtag'
import successIcon from './repayment_success.svg'
import _get from 'lodash/get'
import Card, { Heading } from '@/components/Card'
import HorizontalLine from '@/components/HorizontalLine'
import List from '@/components/List'

export class LoanSuccess extends React.Component {
  componentDidMount () {
    ga.pageview({
      title: 'Repayment: Loan Payment Success',
      location: '/repayments/id/loan-payment-success',
      path: '/repayments/id/loan-payment-success'
    })
  }

  render () {
    const {
      locale: { lang, currencyConfig },
      resultGetRepayments
    } = this.props
    const numberFormatter = new Intl.NumberFormat(lang, {
      style: 'currency',
      ...currencyConfig
    })
    const firstName = _get(resultGetRepayments, 'data.user_details.first_name')
    const loanId = _get(
      resultGetRepayments,
      'data.loan_details.0.loan.loan_contract_id'
    )
    const loanPrincipal = _get(
      resultGetRepayments,
      'data.loan_details.0.loan.principal'
    )
    const loanTermMonth = _get(
      resultGetRepayments,
      'data.loan_details.0.loan.loan_term_month'
    )
    return (
      <div>
        <div className='mt-3 mb-3 primary-color title text-center'>
          <strong>
            <FormattedMessage id='repayments.selectChannel.loanSuccess.label.title' />
          </strong>
        </div>
        <div className='mt-4 mb-4 text-center'>
          <img src={successIcon} />
        </div>
        <div className='text-center'>
          <FormattedMessage
            id='repayments.selectChannel.loanSuccess.label.msg'
            values={{ name: firstName }}
          />
        </div>
        <Card className='mt-4'>
          <Heading>
            <FormattedMessage id='repayments.selectChannel.label.loanDetails' />
          </Heading>
          <HorizontalLine />
          <List
            className='mt-2'
            data={[
              {
                key: (
                  <FormattedMessage id='repayments.selectChannel.label.loanId' />
                ),
                value: loanId
              },
              {
                key: (
                  <FormattedMessage id='repayments.selectChannel.label.principal' />
                ),
                value: numberFormatter.format(loanPrincipal)
              },
              {
                key: (
                  <FormattedMessage id='repayments.selectChannel.label.tenure' />
                ),
                value: (
                  <FormattedMessage
                    id='step4.label.tenureOption'
                    values={{ nMonth: loanTermMonth }}
                  />
                )
              },
              {
                key: (
                  <FormattedMessage id='repayments.selectChannel.loanSuccess.label.loanStatus' />
                ),
                value: (
                  <FormattedMessage id='repayments.selectChannel.loanSuccess.label.repaid' />
                )
              }
            ]}
          />
        </Card>
      </div>
    )
  }
}

export const mapStateToProps = state => {
  return {
    locale: state.app.locale
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    router: bindActionCreators(router, dispatch),
    selfActions: bindActionCreators(selfActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoanSuccess)
