import imageCompression from 'browser-image-compression'

export const convertToMB = size => {
  return Math.round((size / 1024 / 1024) * 10) / 10
}

export const convertMilliToSeconds = ms => {
  return Math.round((ms / 1000) * 10) / 10
}

window.userData = {}

export const getBrowserData = () => {
  let unknown = null
  // screen
  let screenSize = ''
  let screen = window.screen
  if (screen.width) {
    let width = screen.width ? screen.width : ''
    let height = screen.height ? screen.height : ''
    screenSize += '' + width + ' x ' + height
  }
  // cookie
  let cookieEnabled = !!navigator.cookieEnabled
  if (typeof navigator.cookieEnabled === 'undefined' && !cookieEnabled) {
    document.cookie = 'testcookie'
    cookieEnabled = document.cookie.indexOf('testcookie') !== -1
  }
  // Language
  let language = unknown
  language = navigator.language || navigator.userLanguage
  // Time Zone
  let timezone = unknown
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  // Device Pixel Ratio
  let devicePixelRatio = unknown
  devicePixelRatio = window.devicePixelRatio
  // Plugins installed
  let installedPlugins = navigator.plugins // store the total no of plugin stored
  let arrPlugins = []
  for (let i = 0; i < installedPlugins.length; i++) {
    arrPlugins.push(installedPlugins[i].name)
  }
  const data = {
    'Screen-Resolution': screenSize,
    'Cookies-Enabled': cookieEnabled,
    Language: language,
    Timezone: timezone,
    'No-Of-Plugins': installedPlugins.length,
    'Plugin-Details': arrPlugins,
    'Device-Pixel-Ratio': devicePixelRatio
  }
  return data
}

export const getLatLng = () => {
  navigator.geolocation.getCurrentPosition(
    position => {
      const {
        coords: { latitude, longitude }
      } = position
      window.userData.latitude = latitude
      window.userData.longitude = longitude
    },
    () => {}
  )
}

export const srcToFile = (src, fileName, mimeType) => {
  return fetch(src).then(res => {
    return res.arrayBuffer()
  }).then(buf => {
    return new File([buf], fileName, { type: mimeType })
  })
}

// Convert file to base64 string
export const fileToBase64 = (file) => {
  return new Promise(resolve => {
    // eslint-disable-next-line no-undef
    var reader = new FileReader()
    reader.onload = function (event) {
      resolve(event.target.result)
    }
    // Convert data to base64
    reader.readAsDataURL(file)
  })
}

export const toMMSS = nSec => {
  if (typeof nSec !== 'number') return ''
  const sec_num = parseInt(nSec, 10)
  let minutes = Math.floor(sec_num / 60)
  let seconds = sec_num - minutes * 60

  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }
  return minutes + ':' + seconds
}

export const compressImage = (image, { maxSizeMB } = { maxSizeMB: 0.1 }) => {
  const options = {
    maxSizeMB: maxSizeMB,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  }
  return imageCompression(image, options)
}
