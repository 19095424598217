import styled from 'styled-components'

const headerHeight = 60

export const Wrapper = styled.div`
  padding: 0 10px 2px;
  ${props =>
    props.noSeparateLine
      ? `
    `
      : `
      padding-top: ${headerHeight}px;
  `};
  display: flex;
  flex-direction: column;
  align-items: center;

  [data-header="true"] {
    height: ${headerHeight}px;
    background: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 15px;
    border-bottom: 1px solid #dcdcdc;
    z-index: 1;

    display: flex;
    align-items: center;
  }

  [data-logo="true"] {
    ${props =>
    props.noSeparateLine
      ? `
      padding: 35px 0;
      width: 160px;
    `
      : `
      width: 100px;
    `};
  }
`
