import React from 'react'
import { connect } from 'react-redux'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import * as appActions from '@/containers/App/actions'
import * as step1Actions from '@/containers/Steps/1/actions'
import Button from '@/components/Button'
import { Link } from 'react-router-dom'
import * as router from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { Feature } from './styledComponents'
import Layout from '@/components/Layout'

const logosByMerchant = {
  linkaja: [
    require('@/static/images/ojk-logo.svg'),
    require('@/static/images/afpi-logo.png')
  ],
  viettel: [
    require('@/static/images/viettelpay-logo.svg'),
    require('@/static/images/OCB-logo.svg')
  ]
}

const getFeatures = merchant => {
  return [
    {
      id: 1,
      icon: require(`./${merchant}/feature-1.svg`),
      content: <FormattedHTMLMessage id="intro.feature.1" />
    },
    {
      id: 2,
      icon: require(`./${merchant}/feature-2.svg`),
      content: <FormattedHTMLMessage id="intro.feature.2" />
    },
    {
      id: 3,
      icon: require(`./${merchant}/feature-3.svg`),
      content: <FormattedHTMLMessage id="intro.feature.3" />
    }
  ]
}

export class Intro extends React.Component {
  componentDidMount () {
    const { appActions } = this.props
    window.cdcm.user_return_type = '1'
    appActions.updateIsResumable(false)
    this.handleClickGetStarted()
  }

  handleClickTC = () => {
  }

  handleClickGetStarted = () => {
    const { step1Actions } = this.props
    step1Actions.initialize()
    step1Actions.handleRedirect()
  }

  render () {
    const { merchant } = this.props
    return null
  }
}

export const mapStateToProps = state => {
  return {
    merchant: state.app.merchant
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    // selfActions: bindActionCreators(selfActions, dispatch),
    appActions: bindActionCreators(appActions, dispatch),
    step1Actions: bindActionCreators(step1Actions, dispatch),
    router: bindActionCreators(router, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Intro)
