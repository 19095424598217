import React from 'react'
import { connect } from 'react-redux'
import * as router from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import Button from '@/components/Button'
import * as selfActions from '../../actions'
import _get from 'lodash/get'
import * as ga from '@/utils/gtag'
import awaitConfirmationIcon from './awaiting_confirmation.svg'
import { CenteredText } from '../../styledComponents'
import * as appConstants from '@/containers/App/constants'

export class AwaitConfirmation extends React.Component {
  componentDidMount () {
    ga.pageview({
      title: 'Repayment: Payment Initiated Waiting Confirmation',
      location: '/repayments/id/payment-initiated-waiting-confirmation',
      path: '/repayments/id/payment-initiated-waiting-confirmation'
    })
  }

  handleClickSupportCall = () => {
    const { resultGetRepayments } = this.props
    const channel = _get(
      resultGetRepayments,
      'data.last_repayment_attempt.payment_mode'
    )
    const initiationDate = _get(
      resultGetRepayments,
      'data.last_repayment_attempt.payment_initiation_date'
    )
    ga.event({
      category: 'click',
      action: 'repymt_await_support',
      label: 'call',
      value: 1
    })
    ga.event({
      category: 'occurrence',
      action: 'repymt_await_channel_1',
      label: channel,
      value: 1
    })
    ga.event({
      category: 'occurrence',
      action: 'repymt_await_trx_date_1',
      label: initiationDate,
      value: 1
    })
  }

  handleClickSupportEmail = () => {
    const { resultGetRepayments } = this.props
    const channel = _get(
      resultGetRepayments,
      'data.last_repayment_attempt.payment_mode'
    )
    const initiationDate = _get(
      resultGetRepayments,
      'data.last_repayment_attempt.payment_initiation_date'
    )
    ga.event({
      category: 'click',
      action: 'repymt_await_support',
      label: 'email',
      value: 1
    })
    ga.event({
      category: 'occurrence',
      action: 'repymt_await_channel_1',
      label: channel,
      value: 1
    })
    ga.event({
      category: 'occurrence',
      action: 'repymt_await_trx_date_1',
      label: initiationDate,
      value: 1
    })
  }

  handleTryPaymentAgain = () => {
    const { selfActions, resultGetRepayments } = this.props
    const channel = _get(
      resultGetRepayments,
      'data.last_repayment_attempt.payment_mode'
    )
    const initiationDate = _get(
      resultGetRepayments,
      'data.last_repayment_attempt.payment_initiation_date'
    )
    ga.event({
      category: 'click',
      action: 'repymt_await_retry',
      label: 'button',
      value: 1
    })
    ga.event({
      category: 'occurrence',
      action: 'repymt_await_channel_2',
      label: channel,
      value: 1
    })
    ga.event({
      category: 'occurrence',
      action: 'repymt_await_trx_date_2',
      label: initiationDate,
      value: 1
    })
    selfActions.tryPaymentAgain()
  }

  render () {
    const {
      resultGetRepayments,
      locale: { lang }
    } = this.props
    const dateFormatter = new Intl.DateTimeFormat(
      lang,
      appConstants.dateFormatSetting
    )
    const firstName = _get(resultGetRepayments, 'data.user_details.first_name')
    const channel = _get(
      resultGetRepayments,
      'data.last_repayment_attempt.payment_mode'
    )
    const initiationDate = _get(
      resultGetRepayments,
      'data.last_repayment_attempt.payment_initiation_date',
      ''
    ).split('-')
    return (
      <div>
        <div className="mt-3 mb-3 primary-color title text-center">
          <strong>
            <FormattedMessage
              id="repayments.selectChannel.awaitConfirmation.label.title" />
          </strong>
        </div>
        <div className="mt-4 mb-4 text-center">
          <img src={awaitConfirmationIcon} />
        </div>
        <div className="text-center">
          <FormattedMessage
            id="repayments.selectChannel.awaitConfirmation.label.msg"
            values={{
              name: firstName,
              channel: channel,
              date:
                initiationDate.length === 3 &&
                dateFormatter.format(
                  new Date(
                    Date.UTC(
                      initiationDate[0],
                      initiationDate[1] - 1,
                      initiationDate[2]
                    )
                  )
                )
            }}
          />
        </div>
        <div className="py-3">
          <CenteredText>
            <span>
              <FormattedMessage
                id="repayments.selectChannel.awaitConfirmation.label.dividerTxt" />
            </span>
          </CenteredText>
        </div>
        <div className="text-center">
          <Button onClick={this.handleTryPaymentAgain}>
            <FormattedMessage
              id="repayments.selectChannel.awaitConfirmation.btn.paymentAgain" />
          </Button>
        </div>
      </div>
    )
  }
}

export const mapStateToProps = state => {
  return {
    locale: state.app.locale
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    router: bindActionCreators(router, dispatch),
    selfActions: bindActionCreators(selfActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AwaitConfirmation)
