import React from 'react'
import { CheckboxWrapper } from './styledComponents'
import iconCheck from './check.svg'

export default class Checkbox extends React.Component {
  render () {
    const { checked, label, onChange, ...rest } = this.props
    return (
      <CheckboxWrapper
        checked={checked}
        onClick={() => onChange(!checked)}
        {...rest}
      >
        <span data-icon>{checked && <img src={iconCheck} />}</span>
        <span className='ml-2'>{label}</span>
      </CheckboxWrapper>
    )
  }
}
