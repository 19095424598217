import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { Modal, ModalBody } from 'reactstrap'
import ModalHeader from '@/components/ModalHeader'
import NotFoundPage from '@/containers/NotFoundPage'
import RepaymentsPage from '@/containers/RepaymentsPage'
import Loader from '@/components/Loader'
import PropTypes from 'prop-types'
import React from 'react'
import { hot } from 'react-hot-loader'
import { connect } from 'react-redux'
import * as selfActions from './actions'
import PrivateRoute from './PrivateRoute'
import { bindActionCreators } from 'redux'
import * as appActions from '@/containers/App/actions'
// import * as appConstants from '@/containers/App/constants'
import GlobalStyles from './globalStyles'

import qs from 'query-string'
import {
  attachOnUnloadEvent,
  disableBackButton,
  disablePullToRefresh,
  redirectToLastLocationIfNeeded
} from './utils'
import Cookies from 'js-cookie'
import { routes } from './constants'
import * as router from 'react-router-redux'

export class App extends React.Component {
  closeModal = () => {
    const { selfActions } = this.props
    selfActions.updateModal({ show: false })
  };

  componentDidMount () {
    const {
      location: { search },
      location,
      appActions,
      history,
      router
    } = this.props

    disableBackButton()
    disablePullToRefresh()
    attachOnUnloadEvent()

    this.unlistenHistory = history.listen((location, action) => {
      if (action === 'PUSH') {
        Cookies.set('last-location', location)
      } else {
        redirectToLastLocationIfNeeded(location, router)
      }
    })

    const parsedQs = qs.parse(search)
    const parsedLocale = parsedQs['locale']
    const parsedLang = parsedQs['lang']
    const parsedMerchant = parsedQs['merchant']
    if (parsedLocale) {
      appActions.updateLocale(parsedLocale)
    }
    if (parsedLang) {
      appActions.updateLanguageCode(parsedLang)
    }
    if (parsedMerchant) {
      appActions.updateMerchant(parsedMerchant)
    }

    redirectToLastLocationIfNeeded(location, router)
  }

  componentWillUnmount () {
    this.unlistenHistory()
  }

  render () {
    const {
      app: {
        modal: { show, body, showHeader, header, centered, fullContent },
        showLoader
      },
      repaymentsSelectChannel: { curAmountToPay }
    } = this.props

    return (
      <React.Fragment>
        <Switch>
          {routes.map(route => {
            const {
              path,
              exact = false,
              isPrivate,
              component: Component,
              routeProps
            } = route
            const RouteWithTypeDefined = isPrivate ? PrivateRoute : Route
            return (
              <RouteWithTypeDefined
                key={path}
                path={path}
                exact={exact}
                render={props => (
                  <Component {...props} routeProps={routeProps} />
                )}
              />
            )
          })}
          <Route
            path='/repayments/:token/:phase'
            render={props => {
              const {
                match: {
                  params: { phase, token }
                }
              } = props
              if (phase !== 'select-channel' && !curAmountToPay) {
                return <Redirect to={`/repayments/${token}/select-channel`} />
              }
              return <RepaymentsPage {...props} />
            }}
          />
          <Route component={NotFoundPage} />
        </Switch>

        <Modal
          isOpen={show}
          keyboard={false}
          backdrop='static'
          centered={centered}
          toggle={this.closeModal}
        >
          {fullContent || (
            <React.Fragment>
              {showHeader && (
                <ModalHeader onClickCloseButton={this.closeModal}>{header}</ModalHeader>
              )}
              <ModalBody>{body}</ModalBody>
            </React.Fragment>
          )}
        </Modal>
        {showLoader && <Loader />}
        <GlobalStyles />
      </React.Fragment>
    )
  }
}

App.propTypes = {
  children: PropTypes.element
}

export const mapStateToProps = state => {
  return {
    app: state.app,
    repaymentsSelectChannel: state.repaymentsSelectChannel
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    appActions: bindActionCreators(appActions, dispatch),
    selfActions: bindActionCreators(selfActions, dispatch),
    router: bindActionCreators(router, dispatch)
  }
}

export default hot(module)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(App)
  )
)
