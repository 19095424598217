import _get from 'lodash/get'

export const getEmiObjForSelectedAmount = (
  installmentForDueDate,
  selectedProductId
) => {
  return (
    installmentForDueDate.emis.find(
      e => e.loan_product_id === selectedProductId
    ) || {}
  )
}

export const getInstallmentForDueDate = (resultGetInstallments, dueDate) => {
  const installments = _get(resultGetInstallments, 'data.installments', [])
  return (
    installments.find(i => i.cycle_day === dueDate) || {
      emis: []
    }
  )
}

export const updateStateForChanges = ({
  selectedProductId,
  resultGetInstallments,
  dueDate
}) => {
  const installmentForDueDate = getInstallmentForDueDate(
    resultGetInstallments,
    dueDate
  )
  return {
    emiObjForSelectedAmount: getEmiObjForSelectedAmount(
      installmentForDueDate,
      selectedProductId
    )
  }
}
