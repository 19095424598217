import AboutPage from '@/containers/AboutPage'
import HomePage from '@/containers/HomePage'
import Intro from '@/containers/Intro'
import Confirmation from '@/containers/Confirmation'
import ApologyPage from '@/containers/ApologyPage'
import LoanStatusPage from '@/containers/LoanStatusPage'
import CircuitBreakerPage from '@/containers/CircuitBreakerPage'
import HandleRedirection from '@/containers/HandleRedirection'
import TermsAndConditions from '@/containers/TermsAndConditions'
import TermsAndConditionStep1 from '@/containers/TermsAndConditionsStep1'
import WelcomeBack from '@/containers/WelcomeBack'
import ThankYouPage from '@/containers/ThankYouPage'
import ThankYouWaitPage from '@/containers/ThankYouWaitPage'
import ThankYouAcceptPage from '@/containers/ThankYouAcceptPage'
import ThankYouApprovePage from '@/containers/ThankYouApprovePage'
// import KycAsliriPage from '@/containers/KycAsliriPage'
import ResumeApp from '@/containers/ResumeApp'
import PendingKYC from '@/containers/PendingKYCPage'

export const UPDATE_MODAL = 'APP/UPDATE_MODAL'
export const UPDATE_LOADER = 'APP/UPDATE_LOADER'
export const UPDATE_LANGUAGE_CODE = 'APP/UPDATE_LANGUAGE_CODE'
export const UPDATE_LOCALE = 'APP/UPDATE_LOCALE'
export const UPDATE_IS_RESUMABLE = 'APP/UPDATE_IS_RESUMABLE'
export const UPDATE_MERCHANT = 'APP/UPDATE_MERCHANT'
export const UPDATE_APPLICATION_REQUEST_ID =
  'APP/UPDATE_APPLICATION_REQUEST_ID'
export const SET_NON_RA_RESPONSE = 'APP/SET_NON_RA_RESPONSE'
export const TCASH_MAIL = 'mailto:cs@trustiq.id'
export const TCASH_SUPPORT_NUMBER = 'tel:02150112180'
export const TCASH_APP_LINK =
  'https://app.adjust.com/5yyfj9g?deep_link=tcashwallet://?PageApp=MainPage'

export const QUERY_STRING_FOR_TEST_MODE = 'test=true'
export const dateFormatSetting = {
  year: 'numeric',
  month: 'short',
  day: 'numeric'
}
export const NO_VALUE_SYMBOL = '––'

export const merchantDetails = {
  linkaja: {
    name: 'LinkAja',
    locale: 'id'
  },
  viettel: {
    name: 'ViettelPay',
    locale: 'vi'
  }
}

export const routes = [
  {
    path: '/',
    isPrivate: true,
    exact: true,
    component: HomePage,
    routeProps: {
      redirectToLastLocation: true
    }
  },
  {
    path: '/intro',
    isPrivate: true,
    component: Intro
  },
  {
    path: '/welcome-back',
    isPrivate: true,
    component: WelcomeBack
  },
  {
    path: '/thanks',
    isPrivate: true,
    component: ThankYouPage
  },
  {
    path: '/thanks-wait',
    isPrivate: true,
    component: ThankYouWaitPage
  },
  {
    path: '/thanks-accept',
    isPrivate: true,
    component: ThankYouAcceptPage
  },
  {
    path: '/thanks-approve',
    isPrivate: true,
    component: ThankYouApprovePage
  },
  // {
  //   path: '/kyc-page',
  //   isPrivate: true,
  //   component: KycAsliriPage
  // },
  {
    path: '/step/confirmation',
    isPrivate: true,
    component: Confirmation,
    routeProps: {
      redirectToLastLocation: true
    }
  },
  {
    path: '/step/:id',
    isPrivate: true,
    component: HomePage,
    routeProps: {
      redirectToLastLocation: true
    }
  },
  {
    path: '/resume-application',
    isPrivate: true,
    component: ResumeApp
  },
  {
    path: '/about',
    component: AboutPage
  },
  {
    path: '/terms-and-conditions',
    component: TermsAndConditions
  },
  {
    path: '/terms-and-conditions-step1',
    component: TermsAndConditionStep1
  },
  {
    path: '/apologies',
    component: ApologyPage
  },
  {
    path: '/loan-status',
    component: LoanStatusPage
  },
  {
    path: '/overloaded',
    component: CircuitBreakerPage
  },
  {
    path: '/handle-redirection/:merchant',
    component: HandleRedirection
  },
  {
    path: '/pending-kyc',
    component: PendingKYC
  }
]
