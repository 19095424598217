import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  input[data-hidden-input="true"] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
  }
`

export const OTPTextBox = styled.span`
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 40px;
  line-height: 40px;
  border: none;
  border-radius: 5px;
  background-color: #f4f4f4;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  color: #202020;
  font-size: 14px;
  margin: 0px 5px;

  border: ${props =>
    typeof props.valid === 'undefined'
      ? '1px solid #ebebeb'
      : props.valid
        ? '1px solid #61a11b'
        : '1px solid #d32e2e'};
  color: ${props =>
    typeof props.valid === 'undefined'
      ? '#202020'
      : props.valid
        ? '#202020'
        : '#d32e2e'};
  background-color: ${props =>
    typeof props.valid === 'undefined'
      ? '#ffffff'
      : props.valid
        ? '#ffffff'
        : '#fff2f2'};

  ${props =>
    props.focused === true
      ? `border: 2px solid #394dff;
    `
      : ''};
`
