import { applyMiddleware, compose, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import thunk from 'redux-thunk'
import createHistory from 'history/createBrowserHistory'
// 'routerMiddleware': the new way of storing route changes with redux middleware since rrV4.
import { routerMiddleware } from 'react-router-redux'
import rootReducer from './reducers'
import { ModalTransform } from './persistTransforms'
import { eventTracking, updateLoader } from './middlewares';

const persistConfig = {
  key: 'blink.1.1.',
  storage,
  transforms: [ModalTransform]
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const history = createHistory()
const reactRouterMiddleware = routerMiddleware(history)

// thunk middleware can also accept an extra argument to be passed to each thunk action
// https://github.com/reduxjs/redux-thunk#injecting-a-custom-argument
// Changing order of middlewares can cause issues.
export const commonMiddlewareAcrossEnvironments = [ thunk, updateLoader, eventTracking, reactRouterMiddleware];

function configureStoreProd(initialState) {
  const middlewares = [
    ...commonMiddlewareAcrossEnvironments
  ]

  return createStore(
    persistedReducer,
    initialState,
    compose(applyMiddleware(...middlewares))
  )
}

function configureStoreDev(initialState) {
  const middlewares = [
    // Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
    reduxImmutableStateInvariant(),
    ...commonMiddlewareAcrossEnvironments
  ]

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose // add support for Redux dev tools
  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  )

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      const nextReducer = require('./reducers').default // eslint-disable-line global-require
      store.replaceReducer(nextReducer)
    })
  }

  return store
}

const configureStore =
  process.env.NODE_ENV === 'production'
    ? configureStoreProd
    : configureStoreDev

export const store = configureStore()
export const persistor = persistStore(store)
