import React from "react"
import PropTypes from "prop-types"
import { ActiveStep, InactiveStep, ConnectingLine } from "./styledComponents.js"

const Steps = props => {
  let steps = Array.from(new Array(props.maxSteps), (val, index) => index + 1)
  // const mystyle = {
  //   color: 'white',
  //   backgroundColor: 'DodgerBlue',
  //   padding: '10px',
  //   border: '1px',
  //   fontFamily: 'Arial'
  // }
  return (
    <div>
      {steps.map((item, index) => {
        return (
          <div className="d-inline-block" key={index}>
            {item <= props.currentStep ? (
              <ActiveStep>{item}</ActiveStep>
            ) : (
              <InactiveStep>{item}</InactiveStep>
            )}

            {item !== props.maxSteps ? <ConnectingLine /> : null}
          </div>
        )
      })}
    </div>
  )
}

Steps.propTypes = {
  maxSteps: PropTypes.number,
  currentStep: PropTypes.number
}

export default Steps
