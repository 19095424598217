import React from 'react'
import styled from 'styled-components'
import { secondaryColor } from '@/containers/App/globalStyles'

export const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 100px;
  padding-top: 10px;
`

export const Table = styled.table.attrs({
  className: 'table table-borderless'
})`
  font-size: 14px;
  thead > tr {
    background: ${secondaryColor};
    border-radius: 4px;
  }
  td {
    vertical-align: middle;
  }
  tbody > tr:not(:last-child) {
    border-bottom: 1px solid #d5d6eb !important;
  }
`
