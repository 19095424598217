import React from 'react'
import { connect } from 'react-redux'
import * as selfActions from './actions'
import * as appActions from '@/containers/App/actions'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import BackButton from '@/components/BackButton'
import * as router from 'react-router-redux'
import { withRouter } from 'react-router-dom'
import * as helpers from '@/utils/helpers'
import * as step6Actions from '../6/actions'
import * as selfConstants from './constants'
import failureSmiley from '../2/failure-smiley.svg'
import { UploadFailureText } from '../2/styledComponents'
import Button from '@/components/Button'

const ContractByMerchant = {
  linkaja: require('./components/ContractLinkAja').default,
  viettel: require('./components/ContractVN').default
}

export class Step5 extends React.Component {
  componentDidMount () {
    const { selfActions } = this.props
    // this.setState({ applicationRequestId: '1870c27f-3a9f-48b7-bedc-17996fe003f2' })
    selfActions.getContract()
    helpers.getLatLng()
  }

  gotoGetContract = () => {
    const { selfActions } = this.props
    selfActions.getContract()
  }

  handleClickBack = () => {
    const {
      router,
      location: { search },
      selectedProductId,
      selectedProduct: { name, number_of_repayments },
      curCreditAmount
    } = this.props
    router.push({ pathname: '/step/4', search })
  }

  handleOnFinishAcceptContract = () => {
    const { selfActions } = this.props
    selfActions.gotoVerifyOtp()
  }

  componentWillUnmount () {
    const { step6Actions, token } = this.props
    step6Actions.initialize(token)
  }

  render () {
    console.log('props ', this.props)
    const { status, token } = this.props
    console.log('status ', status)
    console.log('selected ', token)
    const { merchant } = this.props
    const ContractComponent = ContractByMerchant[merchant]
    if (status != null && status == selfConstants.ACCEPT_CONTRACT_SUCCESS) {
      return (<div className="subtitle text-center mt-5">
          <button style={{ display: 'none' }}
                  onClick={this.handleOnFinishAcceptContract()} />
        </div>
      )
    } else if (status != null && status == selfConstants.GET_CONTRACT_FAIL) {
      return (
        <React.Fragment>
          <div className="mt-4">
            <img src={failureSmiley} />
          </div>
          <div className="subtitle text-center mt-2 font-weight-bold">
            <UploadFailureText>
              <FormattedMessage id="step2.subtitlefailureapology" />
            </UploadFailureText>
            <UploadFailureText>
              <FormattedMessage id="step3.subtitlefailurereason" />
            </UploadFailureText>
          </div>
          <div className="mt-4">
            <span className="subtitle">
              <FormattedMessage id="step3.label.help" />
            </span>
          </div>
          <div className="mt-4">
            <Button disabled={false} onClick={this.gotoGetContract}>
              <FormattedMessage id="step2.btn.tryagain" />
            </Button>
          </div>
        </React.Fragment>
      )
    } else {
      return (
        <div className="d-flex flex-column align-items-center w-100 pb-4">
          <div className="title">
            <FormattedMessage id="step5.title" />
          </div>
          <div className="subtitle text-center mt-2 mb-2">
            <FormattedMessage id="step5.subtitle" />
          </div>
          <ContractComponent />
          <BackButton onClick={this.handleClickBack} />
        </div>
      )
    }
  }
}

export const mapStateToProps = state => {
  return {
    merchant: state.app.merchant,
    curCreditAmount: state.step4.curCreditAmount,
    selectedProductId: state.step4.selectedProductId,
    selectedProduct: state.step4.selectedProduct,
    status: state.step5.status,
    token: state.step5.token
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    selfActions: bindActionCreators(selfActions, dispatch),
    step6Actions: bindActionCreators(step6Actions, dispatch),
    appActions: bindActionCreators(appActions, dispatch),
    router: bindActionCreators(router, dispatch)
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Step5)
)
