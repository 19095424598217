import { supportedLocales } from '@/locales'

// for react-datepicker
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import id from 'date-fns/locale/id'
import vi from 'date-fns/locale/vi'
registerLocale('id', id)
registerLocale('vi', vi)
setDefaultLocale('en')

export default {
  modal: {
    show: false,
    body: null,
    showHeader: true,
    header: null,
    centered: true,
    fullContent: null,
  },
  showLoader: false,
  locale: supportedLocales.id, // need to update window.cdcm.country_id accordingly
  applicationRequestId: '',
  isResumable: false,
  merchant: 'linkaja'
}
