import request from '@/utils/request'
import axirequesth from '@/utils/axirequesth'
import * as selfConstants from './constants'
import * as appConstants from '@/containers/App/constants'
import _get from 'lodash/get'
import { push } from 'react-router-redux'

const API_URL = 'https://api.bpragm.com/api/v1'
const API_PRIVY_URL = 'https://los.bpragm.com/services/ttfprivyid/api'

export const initialize = () => async (dispatch, getState) => {
  const {
    app: { applicationRequestId }
  } = getState()
  await dispatch(getPhoneNumbers())

  const params = new URLSearchParams()
  params.append('applicationRequestId', applicationRequestId)

  request(
    `${API_PRIVY_URL}/contract/borrower/v1/otp/request`,
    {
      method: 'POST',
      data: params
    }
  ).then(response => {
    dispatch({ type: appConstants.UPDATE_LOADER, payload: false })
    dispatch({
      type: selfConstants.INITIAL_OTP,
      token: response.applicationRequestId,
      payload: null
    })
  }).catch(error => {
    dispatch({ type: appConstants.UPDATE_LOADER, payload: false })
    return dispatch({
      type: selfConstants.GET_PHONE_NUMBERS_FAIL,
      payload: error
    })
  })
}

export const updatePhoneNumber = newPhoneNumber => {
  return {
    type: selfConstants.UPDATE_PHONE_NUMBER,
    payload: newPhoneNumber
  }
}

export const updatePhase = newPhase => {
  return {
    type: selfConstants.UPDATE_PHASE,
    payload: newPhase
  }
}

export const updateOTP = newOTP => {
  return {
    type: selfConstants.UPDATE_OTP,
    payload: newOTP
  }
}
export const updateTimeRemaining = newTime => {
  return {
    type: selfConstants.UPDATE_TIME_REMAINING,
    payload: newTime
  }
}
export const resendOTP = () => (dispatch, getState) => {
  const {
    app: { applicationRequestId }
  } = getState()
  dispatch({ type: selfConstants.GET_PHONE_NUMBERS })
  dispatch({ type: appConstants.UPDATE_LOADER, payload: true })

  const params = new URLSearchParams()
  params.append('applicationRequestId', applicationRequestId)

  return axirequesth(
    // `${API_PRIVY_URL}/application_requests/${applicationRequestId}/retry_request_doc_signing/kyc`,
    `${API_PRIVY_URL}/contract/borrower/v1/otp/resend`,
    {
      method: 'POST',
      data: params
    }
  ).then(response => {
    dispatch({ type: appConstants.UPDATE_LOADER, payload: false })
    dispatch({
      type: selfConstants.INITIAL_OTP,
      token: response.applicationRequestId,
      payload: null
    })
  }).catch(error => {
    dispatch({ type: appConstants.UPDATE_LOADER, payload: false })
    return dispatch({
      type: selfConstants.GET_PHONE_NUMBERS_FAIL,
      payload: error
    })
  })
}
export const getPhoneNumbers = () => (dispatch, getState) => {
  const {
    app: { applicationRequestId }
  } = getState()
  dispatch({ type: selfConstants.GET_PHONE_NUMBERS })
  dispatch({ type: appConstants.UPDATE_LOADER, payload: true })

  return request(
    `${API_URL}/application_requests/${applicationRequestId}/phone_numbers`,
    { method: 'GET' }
  ).then(response => {
    dispatch({ type: appConstants.UPDATE_LOADER, payload: false })
    return dispatch({
      type: selfConstants.GET_PHONE_NUMBERS_SUCCESS,
      payload: response
    })
  }).catch(error => {
    dispatch({ type: appConstants.UPDATE_LOADER, payload: false })
    return dispatch({
      type: selfConstants.GET_PHONE_NUMBERS_FAIL,
      payload: error
    })
  })
}

export const verifyOTP = (otp, token) => (dispatch, getState) => {
  const {
    app: { applicationRequestId, isResumable },
    step6: { timeRemaining, resultSendOtp }
  } = getState()
  console.log('token ', token)
  dispatch({ type: selfConstants.VERIFY_OTP })
  dispatch({ type: appConstants.UPDATE_LOADER, payload: true })

  // const urlToVerifyOtp = `${API_PRIVY_URL}/application_requests/${applicationRequestId}/verrify_otp_peruri/kyc`
  const urlToVerifyOtp = `${API_PRIVY_URL}/contract/borrower/v1/otp/verify?applicationRequestId=${applicationRequestId}&otp=${otp.join(
    '')}`
  axirequesth(urlToVerifyOtp, {
    method: 'POST',
    data: { otp: otp.join(''), token: token }
  }).then(response => {
    dispatch({ type: appConstants.UPDATE_LOADER, payload: false })
    dispatch(push({ pathname: '/thanks' }))
    // if (!isResumable) {
    //   dispatch(push({ pathname: '/thanks' }))
    // } else {
    //   const {
    //     data: { redirect_to }
    //   } = response
    //   switch (redirect_to) {
    //     case 'product_selection': {
    //       dispatch(push({ pathname: '/resume-application' }))
    //       break
    //     }
    //     case 'dashboard': {
    //       dispatch(push({ pathname: '/dashboard' }))
    //       break
    //     }
    //     default:
    //       dispatch(push({ pathname: '/not-found' }))
    //   }
    // }
    dispatch({
      type: selfConstants.VERIFY_OTP_SUCCESS,
      payload: response
    })
    window.cdcm.user_login = 'True'
  }).catch(error => {
    dispatch({
      type: selfConstants.VERIFY_OTP_FAIL,
      payload: error
    })
    dispatch({ type: appConstants.UPDATE_LOADER, payload: false })
  })
}
