import styled from 'styled-components'

export const StyledButton = styled.button`
  min-width: 300px;
  padding: 15px;
  height: 50px;
  border-radius: ${props => (props.full ? '0' : '4px')};
  ${props => (props.full ? 'width: 100%' : '')};
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
  font-size: 14px;
  font-weight: bold;
  border: none;

  ${props =>
    props.disabled
      ? `
    background: #E7E7E7;
    color: #8F8F8F;
    cursor: not-allowed !important;
  `
      : `
    background: #394DFF;
    color: #FFFFFF;
  `};
`
