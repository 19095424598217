import React from 'react'
import { connect } from 'react-redux'
import * as router from 'react-router-redux'
import * as appActions from '@/containers/App/actions'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import * as selfActions from '../../actions'
import * as ga from '@/utils/gtag'
import successIcon from './repayment_success.svg'
import infoIcon from './info.svg'
import { LinkButton } from '../../styledComponents'
import _get from 'lodash/get'
import * as appConstants from '@/containers/App/constants'
import Card, { Heading } from '@/components/Card'
import HorizontalLine from '@/components/HorizontalLine'
import List from '@/components/List'

export class InstallmentSuccess extends React.Component {
  componentDidMount () {
    ga.pageview({
      title: 'Repayment: Installment Payment Success',
      location: '/repayments/id/installment-payment-success',
      path: '/repayments/id/installment-payment-success'
    })
  }

  handleMoreInfoClicked = () => {
    const { appActions, router, token } = this.props
    ga.event({
      category: 'click',
      action: 'ins_success_info',
      label: 'button',
      value: 1
    })
    router.push(`/repayments/${token}/installment-summary`)
  };

  render () {
    const {
      locale: { lang, currencyConfig },
      resultGetRepayments
    } = this.props
    const numberFormatter = new Intl.NumberFormat(lang, {
      style: 'currency',
      ...currencyConfig
    })
    const dateFormatter = new Intl.DateTimeFormat(
      lang,
      appConstants.dateFormatSetting
    )
    const firstName = _get(resultGetRepayments, 'data.user_details.first_name')
    const loanId = _get(
      resultGetRepayments,
      'data.loan_details.0.loan.loan_contract_id'
    )
    const loanPrincipal = _get(
      resultGetRepayments,
      'data.loan_details.0.loan.principal'
    )
    const loanTermMonth = _get(
      resultGetRepayments,
      'data.loan_details.0.loan.loan_term_month'
    )
    const loanAmountDue = _get(
      resultGetRepayments,
      'data.loan_details.0.loan.amount_due'
    )
    const nextDueAmount = _get(
      resultGetRepayments,
      'data.loan_details.0.loan.next_due_amount'
    )
    const nextDueDate = _get(
      resultGetRepayments,
      'data.loan_details.0.loan.next_due_date',
      ''
    ).split('-')
    const transactionID = _get(
      resultGetRepayments,
      'data.last_repayment_attempt.transaction_id'
    )
    return (
      <div>
        <div className='mt-3 mb-3 primary-color title text-center'>
          <strong>
            <FormattedMessage id='repayments.selectChannel.loanSuccess.label.title' />
          </strong>
        </div>
        <div className='mt-4 mb-4 text-center'>
          <img src={successIcon} />
        </div>
        <div className='text-center'>
          <FormattedMessage
            id='repayments.selectChannel.installmentSuccess.label.msg'
            values={{
              name: firstName,
              amount: numberFormatter.format(loanAmountDue),
              transactionID: transactionID
            }}
          />
        </div>
        <Card className='mt-4'>
          <div className='d-flex justify-content-between'>
            <div>
              <Heading>
                <FormattedMessage id='repayments.selectChannel.heading.loanDetails' />
              </Heading>
            </div>
            <div>
              <LinkButton onClick={this.handleMoreInfoClicked}>
                <img src={infoIcon} />
              </LinkButton>
            </div>
          </div>

          <HorizontalLine />
          <List
            className='mt-2'
            data={[
              {
                key: (
                  <FormattedMessage id='repayments.selectChannel.label.loanId' />
                ),
                value: loanId
              },
              {
                key: (
                  <FormattedMessage id='repayments.selectChannel.label.principal' />
                ),
                value: numberFormatter.format(loanPrincipal)
              },
              {
                key: (
                  <FormattedMessage id='repayments.selectChannel.label.tenure' />
                ),
                value: (
                  <FormattedMessage
                    id='step4.label.tenureOption'
                    values={{ nMonth: loanTermMonth }}
                  />
                )
              }
            ]}
          />
        </Card>
        <div className='mt-4 text-center'>
          <FormattedMessage
            id='repayments.selectChannel.installmentSuccess.label.nextInstallmentMsg'
            values={{
              date: dateFormatter.format(
                new Date(
                  Date.UTC(nextDueDate[0], nextDueDate[1] - 1, nextDueDate[2])
                )
              ),
              amount: numberFormatter.format(nextDueAmount)
            }}
          />
        </div>
      </div>
    )
  }
}

export const mapStateToProps = state => {
  return {
    locale: state.app.locale
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    router: bindActionCreators(router, dispatch),
    appActions: bindActionCreators(appActions, dispatch),
    selfActions: bindActionCreators(selfActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstallmentSuccess)
