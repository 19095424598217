import request from '@/utils/request'
import { push } from 'react-router-redux'

const API_PRIVY_KYC_URL = 'https://los.bpragm.com/services/ttfprivykyc/api'

export const gotoLoanOffer = () => dispatch => {
  dispatch(push({ pathname: '/step/4' }))
}

export const gotoKYC = () => dispatch => {
  dispatch(push({ pathname: '/step/3' }))
}

export const gotoApologies = () => dispatch => {
  dispatch(push({ pathname: '/apologies' }))
}

export const checkKYC = (intervalId) => async (
  dispatch, getState) => {
  const {
    app: { applicationRequestId, nonRaResponse = {} }
  } = getState()

  try {
    const checkKYCStatusUrl = `${API_PRIVY_KYC_URL}/privy/kyc/v1/register-non-ra/status`
    const res = await request(checkKYCStatusUrl, {
      method: 'GET',
      params: { requestId: applicationRequestId, trxId: nonRaResponse.trxId }
    })

    switch (res.status.toUpperCase()) {
      case 'SUCCESS':
        if (intervalId) window.clearInterval(intervalId)
        dispatch(gotoLoanOffer())
        break
      case 'FAILED':
        if (intervalId) window.clearInterval(intervalId)
        dispatch(gotoApologies())
        break
      case 'NOT_FOUND':
        if (intervalId) window.clearInterval(intervalId)
        dispatch(gotoKYC())
        break
    }
  } catch (error) {
    if (intervalId) window.clearInterval(intervalId)
    dispatch(gotoKYC())
  }
}
