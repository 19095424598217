const numericValidation = /^\d*$/;
const phoneValidation = /^(0|62|\d)?(811|812|813|821|822|852|853|823|851|814|815|816|855|856|857|858|817|818|819|859|876|877|878|838|831|832|833|895|896|897|898|899|881|882|883|884|885|886|887|888|889|828)(\d{6,20})$/;

export const validatePhone = (phoneNumber, phoneNumberFromPrevStep) => {
  if (phoneNumber && !numericValidation.test(phoneNumber)) {
    return "step3.error.numbersonly";
  }
  if (phoneNumber && !phoneValidation.test(phoneNumber)) {
    return "step3.error.invalidphone";
  }
  if (phoneNumber.length === 19) {
    phoneNumberFromPrevStep = "0" + phoneNumberFromPrevStep;
  } else if (phoneNumber.length === 20) {
    phoneNumberFromPrevStep = "62" + phoneNumberFromPrevStep;
  }
  if (parseInt(phoneNumber) === parseInt(phoneNumberFromPrevStep)) {
    return "step3.error.samephone";
  }
  return;
};
