import axios from 'axios'
import * as Sentry from '@sentry/browser'
import * as helpers from './helpers'

axios.interceptors.response.use(
  response => {
    if (response.data.success === undefined) {
      return response.data
    }
    if (response.data.success === false) {
      return Promise.reject(response.data)
    }
    return response.data
  },
  error => {
    let errorData = null
    if (error.response) {
      errorData = error.response.data
    } else {
      errorData = {
        message: error.message
      }
      Sentry.withScope(scope => {
        Object.keys(error.config).forEach(key => {
          scope.setExtra(key, error.config[key])
        })
        Sentry.captureException(`${error.message} or CORS issue`)
      })
    }
    return Promise.reject(errorData)
  }
)

const request = (url, { method, headers, ...rest }) => {
  return axios({
    url,
    method,
    headers: {
      ...helpers.getBrowserData(),
      'Lat-Long': window.userData && window.userData.latitude
        ? [window.userData.latitude, window.userData.longitude]
        : null,
      ...headers
    },
    ...rest
  })
}

export default request
