import styled from 'styled-components'

export const LinkButton = styled.span`
  text-decoration: underline;
  color: #007bff;
  cursor: pointer;
`
export const InfoWrapper = styled.div`
  border-bottom: 1px dashed #8e8e8e;
  & > div {
    display: flex;
    justify-content: space-between;
    padding: 5px 0 0 0;
  }
`
export const CenteredText = styled.h5`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin: 10px 0 20px;
  & > span {
    background: #f8f8f8;
    padding: 0 20px;
  }
`
