export const GET_REPAYMENTS = 'REPAYMENTS/GET_REPAYMENTS'
export const GET_REPAYMENTS_SUCCESS = 'REPAYMENTS/GET_REPAYMENTS_SUCCESS'
export const GET_REPAYMENTS_FAIL = 'REPAYMENTS/GET_REPAYMENTS_FAIL'

export const POST_AMOUNT_TO_PAY = 'REPAYMENTS/POST_AMOUNT_TO_PAY'
export const POST_AMOUNT_TO_PAY_SUCCESS = 'REPAYMENTS/POST_AMOUNT_TO_PAY_SUCCESS'
export const POST_AMOUNT_TO_PAY_FAIL = 'REPAYMENTS/POST_AMOUNT_TO_PAY_FAIL'

export const UPDATE_CHANNEL = 'REPAYMENTS/UPDATE_CHANNEL'
export const REDIRECT_TO_DEFAULT_PAGE = 'REPAYMENTS/REDIRECT_TO_DEFAULT_PAGE'
export const STATUS = {
  AWAITCONFIRMATION: 'awaiting_confirmation',
  LOANSUCCESS: 'loan_closed',
  INSTALLMENTSUCCESS: 'installment_paid',
  INSTALLMENTDUE: 'installment_due'
}
export const UPDATE_CURRENT_AMOUNT_TO_PAY = 'REPAYMENTS/UPDATE_CURRENT_AMOUNT_TO_PAY'
