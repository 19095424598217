import SelectChannel from './SelectChannel'
import SelectMethod from './SelectMethod'
import InstallmentSummary from './InstallmentSummary'

const mapping = {
  'select-channel': SelectChannel,
  'select-method': SelectMethod,
  'installment-summary': InstallmentSummary
}

export default mapping
