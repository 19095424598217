import request from '@/utils/request'
import * as appConstants from '@/containers/App/constants'

const API_URL = 'https://api.bpragm.com/api/v1'

export const postKYCFormService = (applicationRequestId, valuesToPost) => {
  return request(
    `${API_URL}/application_requests/${applicationRequestId}/kyc`,
    {
      method: 'POST',
      data: valuesToPost
    }
  )
}

export const getKYCFormService = (applicationRequestId, locale) => {
  return request(
    `${API_URL}/application_requests/${applicationRequestId}/form.json?locale=${locale}`,
    { method: 'GET' })
}

export const getCitiesService = provinceCode => {
  return request(`${API_URL}/regions/cities?province_code=${provinceCode}`, {
    method: 'GET'
  })
}

export const getSubdistrictsService = (provinceCode, cityCode) => {
  return request(
    `${API_URL}/regions/sub_districts?province_code=${provinceCode}&city_code=${cityCode}`,
    { method: 'GET' }
  )
}

export const getVillagesService = (provinceCode, cityCode, subdistrictCode) => {
  return request(
    `${API_URL}/regions/villages?province_code=${provinceCode}&city_code=${cityCode}&sub_district_code=${subdistrictCode}`,
    { method: 'GET' }
  )
}
