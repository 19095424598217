import styled from 'styled-components'

const headerHeight = 60

export const Wrapper = styled.div`s
  padding: 0 10px 2px;
  align-items: right;
  
  [data-footer="true"] {
    height: ${headerHeight}px;
    background: white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 15px;
    border-bottom: 1px solid #dcdcdc;
    .buttonConfirmation{
    .background: red;
  }
  }

  [data-logo="true"] {
    ${props =>
    props.noSeparateLine
      ? `
      padding: 35px 0;
      width: 160px;
    `
      : `
      width: 100px;
    `};
  }
`
