import styled from 'styled-components'
import { primaryColor } from '@/containers/App/globalStyles'

export const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  background: #e4e4e4;
  #scrolling-progress-bar {
    background-color: ${primaryColor};
    height: 2px;
  }
`
