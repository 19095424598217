import styled from 'styled-components'

export const ApologySubtitle = styled.div`
	font-size: 1.1rem;
	font-weight: 500;
`

export const ApologyMessage = styled.div`
	font-size: 14px;
	color: #4a4a4a;
	font-weight: 300;
	opacity: 1;
	text-align: center;
`

export const ApologyIcon = styled.img`
	width: 100px;
	height: 60px;
`
