import { step2UpdateLoader, step2EventTracking } from '../containers/Steps/2/middlewares';

const getMiddlewareLogicFromContainers = (store, next, action, middlewareLogicInContainers) => {
    const state = store.getState();
    const dispatch = store.dispatch;
    middlewareLogicInContainers.forEach(middlewareLogicInContainer => {
        middlewareLogicInContainer(state, dispatch, action)
    });
    return next(action);
}

export const updateLoader = store => next => action => {
    const logicInContainers = [step2UpdateLoader]
    getMiddlewareLogicFromContainers(store, next, action, logicInContainers)
}

export const eventTracking = store => next => action => {
    const logicInContainers = [step2EventTracking]
    getMiddlewareLogicFromContainers(store, next, action, logicInContainers)
}
