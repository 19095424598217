import axios from 'axios'
import * as Sentry from '@sentry/browser'

// axios.interceptors.response.use(
//   response => {
//     // if (response.data.success === false) {
//     //   return Promise.reject(response.data)
//     // }
//     // console.log('Response H ', response)
//     // console.log('Response H Status', response.status)
//     return response
//   },
//   error => {
//     let errorData = null
//     if (error.response) {
//       errorData = error.response.data
//     } else {
//       errorData = {
//         message: error.message
//       }
//       Sentry.withScope(scope => {
//         Object.keys(error.config).forEach(key => {
//           scope.setExtra(key, error.config[key])
//         })
//         Sentry.captureException(`${error.message} or CORS issue`)
//       })
//     }
//     return Promise.reject(errorData)
//   }
// )

const axirequesth = (url, { method, ...rest }) => {
  return axios({
    url,
    method,
    headers: {
      // ...helpers.getBrowserData(),
      // 'Content-Type': 'application/x-www-form-urlencoded',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Lat-Long': window.userData && window.userData.latitude
        ? [window.userData.latitude, window.userData.longitude]
        : null
    },
    ...rest
  })
}

export default axirequesth
