import React from 'react'
import { connect } from 'react-redux'
import resumeIcon from './App-Resume-Icon.svg'
import { FormattedMessage } from 'react-intl'
import { ApologySubtitle } from './styledComponents'
import Layout from '@/components/Layout'
import * as selfAction from './actions'
import { bindActionCreators } from 'redux'

class ApologyPage extends React.Component {
  componentDidMount () {
    const { selfAction } = this.props

    const interval = window.setInterval(
      function () {
        selfAction.checkKYC(interval)
      }
      , 10000)
  }

  render () {
    return (
      <Layout noSeparateLine {...this.props}>
        <div className="mb-4">
          <img src={resumeIcon} />
        </div>
        <div className="title mt-2 font-weight-bold text-center">
          <FormattedMessage id="pendingKYC.title" />
        </div>
        <ApologySubtitle className="text-danger mt-1">
          <FormattedMessage id="pendingKYC.subtitle" />
        </ApologySubtitle>
      </Layout>
    )
  }
}

export const mapStateToProps = state => {
  return {}
}
export const mapDispatchToProps = dispatch => {
  return { selfAction: bindActionCreators(selfAction, dispatch) }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApologyPage)
