import React, { createRef } from 'react'
import { bindActionCreators } from 'redux'
import * as selfActions from '@/containers/Steps/3/actions'
import { connect } from 'react-redux'
import Webcam from 'react-webcam'
import Camera from './Camera.svg'
import { dataUriToFile } from '@/utils/dataUriToFile'
import { KTPOverlay } from '@/containers/Steps/3/components/KTPOverlay'
import * as selfConstants from '@/components/CameraHandler/constants'
import { FormattedMessage } from 'react-intl'

class KTPCameraPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      webcamRef: createRef()
    }
  }

  onError = (errorType, errorMsg, fileDetails) => {
    const { selfActions } = this.props
    selfActions.handleFileError(errorType, errorMsg, fileDetails)
  }

  sendData = (imageBase64) => {
    const { selfActions } = this.props
    const file = dataUriToFile(imageBase64, 'ktp-photo.jpeg')
    if (file) {
      if (selfConstants.IMAGE_TYPE.indexOf(file.type) < 0) {
        this.onError('type', selfConstants.IMAGE_TYPE_ERROR, file)
        return false
      } else if (file.size / 1024 / 1024 > selfConstants.IMAGE_SIZE) {
        this.onError('limit', selfConstants.IMAGE_SIZE_ERROR, file)
        return false
      }

      selfActions.handleOnLoadFile({
        name: file.name,
        size: file.size,
        type: file.type,
        dataUrl: imageBase64
      })
    }
  }

  render () {
    const {
      webcamRef
    } = this.state

    return (
      <div className="d-flex flex-column h-100">
        <div className="h-100 overflow-hidden">
          <div
            className="d-flex h-100 w-100 position-relative justify-content-center">
            <p style={{
              position: 'absolute',
              color: 'white',
              textAlign: 'center',
              marginLeft: '1rem',
              marginRight: '1rem',
              marginTop: '1rem',
              zIndex: 101,
              fontWeight: 'bold'
            }}>
              <FormattedMessage id="step2.ktpPhoto.instruction" />
            </p>
            <KTPOverlay style={{
              position: 'absolute',
              zIndex: 100,
              width: 'min(550px, 90%)',
              display: 'grid',
              justifyItems: 'center',
              top: '50%',
              transform: 'translateY(-50%)'
            }}
            />
            <Webcam
              allowFullScreen
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              screenshotQuality={1}
              minScreenshotHeight={1280}
              className="h-100"
              videoConstraints={{
                facingMode: 'environment'
              }}
            />
          </div>
        </div>
        <div
          className="position-absolute fixed-bottom d-flex justify-content-center mb-3 w-100">
          <div className=" d-flex flex-row justify-content-center w-100 px-5">
          <span
            className="rounded-circle bg-primary svg-icon-white p-3"
            onClick={async () => {
              const imageSrc = webcamRef.current.getScreenshot()
              this.sendData(imageSrc)
            }}
          >
            <img src={Camera} alt="Camera Icon" />
          </span>
          </div>
        </div>
      </div>
    )
  }
}

export const mapStateToProps = state => {
  return {
    gestureInstruction: state.step2.gestureInstruction,
    account: state.step2.account,
    gestureSetUsed: state.step2.gestureSetUsed
  }
}
export const mapDispatchToProps = dispatch => {
  return {
    selfActions: bindActionCreators(selfActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KTPCameraPage)
