import React from 'react'
import HorizontalLine from '@/components/HorizontalLine'
import Card, { Heading } from '@/components/Card'
import SchemaField from 'react-jsonschema-form/lib/components/fields/SchemaField'
import {
  FieldBox,
  FormLabel,
  StyledFormContainer,
  CardBody
} from './styledComponents.js'

// Motivation: Add onFieldChange function which tells in which particular field the
// data is updated. The default rjsf onChange fn returns just formData object.
// Link - https://github.com/mozilla-services/react-jsonschema-form/issues/651#issuecomment-319347385
export const CustomSchemaField = function (props) {
  const customProps = {};
  if (props.name) {
    const formContext = props.registry.formContext;
    const { onChange, name } = props;
    customProps.onChange = function (formData) {
      if (formContext && formContext.onFieldChange &&
        typeof formContext.onFieldChange === 'function') {
        formContext.onFieldChange(name, formData);
      }
      onChange(formData);
    };
  }
  return (
    <SchemaField {...props} {...customProps} />
  );
};

export const CustomFieldTemplate = (props) => {
  const { id, classNames, label, help, description, children, schema } = props;
  const isObject = schema.type === "object"

  // For objects CustomObjectFieldTemplate handles template.
  if (isObject) {
    return children
  }

  // instead of using inbuilt error handling we are doing error handling on the
  // widget level on our own.
  const classNamesWithoutHasErrorClass = classNames.replace('has-error', '')

  return (
    <div className={`${classNamesWithoutHasErrorClass} mb-2 pl-0 pr-0`}>
        <FormLabel htmlFor={id}>
          {label}
        </FormLabel>
        {description}
        {children}
        {help}
    </div>
  );
}

export const CustomObjectFieldTemplate = (props) => {
  const isFormContainer = props.title === "formContainer"
  const children = props.properties.map((element, index) => <React.Fragment key={index}>{element.content}</React.Fragment>)
  if (isFormContainer) {
    return (
      <StyledFormContainer className='mt-2'>
        {children}
      </StyledFormContainer>
    );
  }

  // Other objects are form section which are rendered as cards
  return (
    <Card className='mt-2'>
      <Heading>{props.title}</Heading>
      <HorizontalLine />
      <CardBody>{children}</CardBody>
    </Card>
  );
}
