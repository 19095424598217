import React, { Component } from "react";
import Form from "react-jsonschema-form";
import Button from "@/components/Button";
import * as ga from "@/utils/gtag";
import { FormattedMessage } from 'react-intl'
import ScrollingProgressBar from "@/components/ScrollingProgressBar";
import {
  CustomSchemaField,
  CustomFieldTemplate,
  CustomObjectFieldTemplate
} from "./fields";
import {
  InputWidget,
  DatePickerWidget,
  SelectWidget,
  RadioWidget
} from "./widgets";
import { ButtonWrapper } from "./styledComponents";
import { validatePhone } from './validators';
import _get from 'lodash/get'

const widgets = {
  BaseInput: InputWidget,
  DatePickerWidget,
  SelectWidget,
  RadioWidget
};

export default class KYCForm extends Component {
  handleChange = ({ formData }) => {
    console.log('handle change', formData)
    this.props.setKYCFormData(formData);
  };

  onFieldChange = (fieldName, fieldData) => {
    console.log('onfield change field data ' + JSON.stringify(fieldData))
    const fieldNameToActionMap = {
      province: this.props.getCities,
      city: this.props.getSubdistricts,
      sub_district: this.props.getVillages
    };
    if (fieldNameToActionMap[fieldName] !== undefined) {
      fieldNameToActionMap[fieldName](fieldData);
    }
  };

  transformErrors = errors => {
    const errorTypeToErrorMessagesMap = this.props.errorMessages;
    return transformErrorMessages(errors, errorTypeToErrorMessagesMap)
  };

  validate = (formData, errors) => {
    const phoneNumber = _get(formData, "emergency.emergency_contact_phone_number", undefined)
    const error = phoneNumber && validatePhone(phoneNumber, this.props.phoneNumberFromPrevStep);
    if (error) {
      errors.emergency.emergency_contact_phone_number.addError(error);
    }
    return errors;
  };

  setAllFieldsAsTouched = () => {
    const allFieldNames = this.props.fieldNamesList;
    this.props.setKYCFormIsTouched(allFieldNames);
  }

  handleSubmitAndError = ({ formData, schema }, errors) => {
    this.setAllFieldsAsTouched();
    const hasErrors = errors.length > 0;
    if (hasErrors) {
      return;
    }
    this.props.postKYCForm(formData, schema);
  }

  // called on click of submit - if error is found.
  handleError = errors => {
    this.handleSubmitAndError({}, errors)
  };

  // called on click of submit - if no error is found.
  handleSubmit = ({ formData, schema }) => {
    this.handleSubmitAndError({ formData, schema }, [])
  };

  handleFocus = (fieldName, value) => {
    const fieldNameToGaTagsMap = this.props.gaFieldTags;
    const fieldNameForGA = fieldNameToGaTagsMap[fieldName];
    if (fieldNameForGA) {
      ga.event({
        action: "step3_v1_" + fieldNameForGA,
        category: "click",
        label: "tap",
        value: 1
      });
    }
  };

  handleBlur = (fieldNameWithSection, value) => {
    this.props.setKYCFormIsTouched([fieldNameWithSection]);
    const { errors, formData } = this.refs.form.state;
    const fieldNameToGaTagsMap = this.props.gaFieldTags;
    const fieldName = fieldNameToGaTagsMap[fieldNameWithSection];
    const {
      basicInfo: { ktp },
      howToReach: { email },
      emergency: { emergency_contact_phone_number: phone }
    } = formData;

    const fieldNameToErrorsToTrackMap = {
      ktp: ["step3.error.invalidktp"],
      emergency_contact_phone_number: ["step3.error.numbersonly", "step3.error.invalidphone", "step3.error.samephone"],
      email: ["step3.error.invalidemail"]
    }
    const errorToEventAttributesMap = {
      "step3.error.invalidktp": {
        action: "step3",
        category: "occurrence",
        label: "error_ktp_16",
        value: ktp ? ktp.length : 0
      },
      "step3.error.invalidemail": {
        action: "step3_email_invalid",
        category: "occurrence",
        label: email,
        value: 1
      },
      "step3.error.numbersonly": {
        action: "step3_phone_invalid",
        category: "occurrence",
        label: phone,
        value: 1
      },
      "step3.error.invalidphone": {
        action: "step3_phone_invalid",
        category: "occurrence",
        label: phone,
        value: 1
      },
      "step3.error.samephone": {
        action: "step3_phone_dup",
        category: "occurrence",
        label: "user_phone_dup",
        value: 1
      }
    };

    errors.forEach(errorObject => {
      const error = errorObject.stack;
      const currentFieldErrorsToTrack = fieldNameToErrorsToTrackMap[fieldName];
      if (currentFieldErrorsToTrack) {
        const [, errorString] = error.split(": ");
        currentFieldErrorsToTrack.forEach(errorToTrack => {
          if (errorString === errorToTrack) {
            const eventAttributesForError = errorToEventAttributesMap[errorString];
            if (eventAttributesForError) {
              ga.event(eventAttributesForError);
            }
          }
        })
      }
    });
  };

  render() {
    const { schema, uiSchema, formData, isTouched } = this.props;
    const formContext = { onFieldChange: this.onFieldChange, isTouched }
    console.log('props ', this.props)
    // Submit is handled by Form itself, the button which is present as
    // children of form is just dummy component to present submit button
    // this is the reason why onClick of the button is () => { } empty.
    return (
      <React.Fragment>
        {schema && (
          <div>
            <Form
              ref="form"
              schema={schema}
              uiSchema={uiSchema}
              formData={formData}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              liveValidate={true}
              validate={this.validate}
              transformErrors={this.transformErrors}
              showErrorList={false}
              formContext={formContext}
              fields={{ SchemaField: CustomSchemaField }}
              FieldTemplate={CustomFieldTemplate}
              ObjectFieldTemplate={CustomObjectFieldTemplate}
              widgets={widgets}
              onSubmit={this.handleSubmit}
              onError={this.handleError}
            >
              <ButtonWrapper className="mt-4">
                <Button type="submit" onClick={() => { }} full>
                  <FormattedMessage id='step3.btn.confirm' />
                </Button>
              </ButtonWrapper>
            </Form>
          </div>
        )}
        <ScrollingProgressBar />
      </React.Fragment>
    );
  }
}

const transformErrorMessages = (errors, errorTypeToErrorMessagesMap) => {
  const updatedErrors = errors.reduce((acc, error, index) => {
    const { name: type, property: fieldName, message } = error;

    // Edge case: Occupation is implemented using oneOf schema dependency.
    // if its children have validation errors then it starts getting an error
    // with the below message. It is unnecessary error.
    if (message === "should be equal to one of the allowed values") {
      return acc;
    }

    const fieldNameToErrorMessageMap = errorTypeToErrorMessagesMap[type];
    if (fieldNameToErrorMessageMap) {
      const errorMessage =
        fieldNameToErrorMessageMap && fieldNameToErrorMessageMap[fieldName];
      if (errorMessage) {
        error.message = errorMessage;
      } else {
        error.message = fieldNameToErrorMessageMap.default;
      }
    }
    acc.push(error);
    return acc;
  }, []);
  return updatedErrors;
};

export const __private__ = {
  transformErrorMessages,
}
