import request from '@/utils/request'
import * as helpers from '@/utils/helpers'
import * as selfConstants from './constants'
import { push } from 'react-router-redux'
import * as appConstants from '@/containers/App/constants'
import { DataURIToBlob } from '@/utils/dataToUriBlob'
import { VERIFICATION_ASLIRI_ERROR } from './constants'
import { compressImage } from '@/utils/helpers'

let apiStartTime = 0
let apiEndTime = 0
let userStartTime = 0
let userEndTime = 0

const API_URL = 'https://api.bpragm.com/api/v1'
const API_LOS_URL = 'https://los.bpragm.com/api'
const API_PRIVY_URL = 'https://los.bpragm.com/services/ttfprivyid/api'
const API_ASLIRI_URL = 'https://los.bpragm.com/services/ttfasliri/api'
const API_PRIVY_KYC_URL = 'https://los.bpragm.com/services/ttfprivykyc/api'

export const checkStatus = () => (dispatch, getState) => {
  let timestamp = window.performance.now()
  const {
    app: { applicationRequestId }
  } = getState()
  dispatch({ type: appConstants.UPDATE_LOADER, payload: true })
  return request(
    `${API_URL}/application_requests/${applicationRequestId}/status?timestamp=${timestamp}`,
    { method: 'GET' }
  ).then(response => {
    let respData = response.data
    dispatch({
      type: selfConstants.SET_UPLOAD_COUNT,
      payload: respData.upload_attempt_count
    })
    if (
      respData.upload_attempt_count > selfConstants.KYC_UPLOAD_MAX_RETRIES
    ) {
      dispatch({ type: appConstants.UPDATE_LOADER, payload: false })
      dispatch({
        type: selfConstants.GET_STATUS_MAX_RETRIES_REACHED
      })
    } else {
      dispatch({ type: appConstants.UPDATE_LOADER, payload: false })
      dispatch({
        type: selfConstants.GET_STATUS_STAY
      })
    }
  }).catch(() => {})
}
export const setErrorMessage = payload => {
  return {
    type: selfConstants.SET_ERROR_MESSAGE,
    payload
  }
}

export const goToKYCUpload = () => dispatch => {
  dispatch({
    type: selfConstants.GET_STATUS_STAY
  })
}
export const onSignatureSuccess = () => dispatch => {
  dispatch({
    type: selfConstants.GET_KYC_SIGNATURE_SUCCESS
  })
}

export const onSignatureFailed = () => dispatch => {
  dispatch({
    type: selfConstants.GET_STATUS_FAIL
  })
}

export const onProcessRecheck = () => (dispatch, getState) => {
  const {
    app: { applicationRequestId }
  } = getState()
  dispatch({
    type: selfConstants.RENDER_SIGNATURE_PAGE,
    payload: applicationRequestId
  })
}

export const gotoLoanOffer = () => dispatch => {
  dispatch(push({ pathname: '/step/4' }))
}

export const gotoPendingKYC = () => dispatch => {
  dispatch(push({ pathname: '/pending-kyc' }))
}
export const checkUploadRetriesToShowCorrectPage = () => (
  dispatch,
  getState
) => {
  const {
    step2: { uploadretries }
  } = getState()
  if (uploadretries < selfConstants.KYC_UPLOAD_MAX_RETRIES) {
    dispatch({
      type: selfConstants.GET_STATUS_FAIL
    })
  } else {
    dispatch({
      type: selfConstants.GET_STATUS_MAX_RETRIES_REACHED
    })
  }
}

export const verifyImages = () => async (dispatch, getState) => {
  const {
    app: { applicationRequestId, merchant }
  } = getState()
  dispatch({
    type: selfConstants.VERIFY_IMAGES
  })

  const allTypes = selfConstants.ALL_TYPES[merchant]

  for (let i = 0; i < allTypes.length; ++i) {
    const { id } = allTypes[i]
    userStartTime = window.performance.now()
    try {
      const {
        step2: {
          files: {
            [id]: { dataUrl, name, type, size }
          }
        }
      } = getState()
      const file = await helpers.srcToFile(dataUrl, name, type)
      const compressedImage = await compressImage(file,
        { maxSizeMB: 1 })
      const compressedImageBase64 = (await helpers.fileToBase64(
        compressedImage)).split(',')[1]
      const response = await request(
        `${API_LOS_URL}/application-data/upload/ktp`,
        {
          method: 'POST',
          data: {
            fileData: compressedImageBase64,
            creditFileSize: compressedImage.size,
            id: applicationRequestId
          }
        }
      )
      dispatch({ type: appConstants.UPDATE_LOADER, payload: false })
      console.log(response)
      // console.log(response.status)
      console.log('Success upload ktp')
      dispatch({
        type: selfConstants.RENDER_ASLIRI_PAGE
      })
      // if (response.data.result_code == 0){
      //   if (response.data.has_finish_kyc === true) {
      //     dispatch({
      //       type: selfConstants.RENDER_SIGNATURE_PAGE,
      //       payload: applicationRequestId
      //     })
      //   } else {
      //     dispatch({
      //       type: selfConstants.GET_SHOW_IFRAME,
      //       payload: response
      //     })
      //   }
      // } else {
      //   console.log('display error')
      //   dispatch(setErrorMessage(response.data.result_desc))
      //   dispatch(checkUploadRetriesToShowCorrectPage())
      // }
      // if (response.data.has_finish_kyc === true) {
      //   dispatch({
      //     type: selfConstants.GET_KYC_SIGNATURE_SUCCESS
      //   })
      // } else {
      //   dispatch({
      //     type: selfConstants.GET_SHOW_IFRAME,
      //     payload: response
      //   })
      // }
      // const {
      //   step2: {
      //     files: {
      //       [id]: { dataUrl, name, type, size }
      //     }
      //   }
      // } = getState()
      // console.log('type : ', type)
      // const file = await helpers.srcToFile(dataUrl, name, type)

      // try {
      //   await uploadFile(file, uploadUrl)
      //   userEndTime = window.performance.now()
      //   dispatch(getStatus())
      // } catch (err) {
      //   userEndTime = window.performance.now()
      //   dispatch(setErrorMessage(selfConstants.KYC_UPLOAD_FAIL_MESSAGE))
      //   dispatch(checkUploadRetriesToShowCorrectPage())
      //   return
      // }
    } catch (error) {
      userEndTime = window.performance.now()
      dispatch(setErrorMessage(selfConstants.GET_KYC_UPLOAD_URL_FAIL_MESSAGE))
      dispatch(checkUploadRetriesToShowCorrectPage())
      return
    }
  }

  // apiStartTime = window.performance.now()
  // userStartTime = window.performance.now()
  // try {
  //   await request(
  //     `${API_URL}/application_requests/${applicationRequestId}/facematch`,
  //     { method: 'POST' }
  //   )
  //   userEndTime = window.performance.now()
  //   dispatch(getStatus())
  // } catch (errFaceMatch) {
  //   apiEndTime = window.performance.now()
  // }
}

export const sendReportKyc = params => (dispatch, getState) => {
  console.log('send report kyc with params : ', params)
  const {
    app: { applicationRequestId, merchant }
  } = getState()
  dispatch({
    type: selfConstants.VERIFY_IMAGES
  })

  const urlKycReport = `${API_URL}/application_requests/${applicationRequestId}/presigned_url/selfie`
  request(urlKycReport, {
    method: 'POST',
    data: {
      result_code: params.resultCode,
      result_desc: params.resultDesc
    }
  }).then(response => {
    dispatch({ type: appConstants.UPDATE_LOADER, payload: false })
    dispatch({
      type: selfConstants.GET_KYC_SIGNATURE,
      payload: response
    })
  }).catch(error => {
    console.log('error : ', error)
    dispatch(setErrorMessage(selfConstants.GET_KYC_UPLOAD_URL_FAIL_MESSAGE))
    dispatch(checkUploadRetriesToShowCorrectPage())
  })
}
export const sendSpecimentResult = params => (dispatch, getState) => {
  console.log('send report speciment with params : ', params)
  const {
    app: { applicationRequestId, merchant }
  } = getState()
  dispatch({
    type: selfConstants.VERIFY_IMAGES
  })

  const urlKycReport = `${API_URL}/application_requests/${applicationRequestId}/send_speciment_result/kyc`
  request(urlKycReport, {
    method: 'POST',
    data: {
      result_code: params.resultCode,
      result_desc: params.resultDesc
    }
  }).then(response => {
    dispatch({ type: appConstants.UPDATE_LOADER, payload: false })
    dispatch({
      type: selfConstants.GET_KYC_SIGNATURE,
      payload: response
    })
  }).catch(error => {
    console.log('error : ', error)
    dispatch(setErrorMessage(selfConstants.GET_KYC_UPLOAD_URL_FAIL_MESSAGE))
    dispatch(checkUploadRetriesToShowCorrectPage())
  })
}

// eslint-disable-next-line no-unused-vars
const uploadFile = (file, url) => {
  helpers.fileToBase64(file).then(result => {
    console.log('result : ', result)
  })
  return Promise.resolve()
  // return new Promise(function (resolve, reject) {
  //   let xhr = new XMLHttpRequest()
  //   xhr.addEventListener('readystatechange', function () {
  //     if (this.readyState === 4) {
  //       if (xhr.status >= 200 && xhr.status <= 205) {
  //         resolve()
  //       } else {
  //         reject()
  //       }
  //     }else{
  //       console.log("eror", xhr.statusText)
  //     }
  //   })
  //   xhr.open('PUT', url)
  //   xhr.setRequestHeader('Cache-Control', 'no-cache')
  //   xhr.setRequestHeader('Content-Type', 'image/jpeg')
  //   xhr.send(file)
  // })
}

const getStatus = () => (dispatch, getState) => {
  const {
    app: { applicationRequestId }
  } = getState()
  dispatch({ type: selfConstants.GET_STATUS })
  let timestamp

  let timer = setInterval(() => {
    timestamp = window.performance.now()
    userStartTime = window.performance.now()
    return request(
      `${API_URL}/application_requests/${applicationRequestId}/status_signature?timestamp=${timestamp}`,
      { method: 'GET' }
    ).then(response => {
      let respData = response.data
      dispatch({
        type: selfConstants.SET_UPLOAD_COUNT,
        payload: respData.upload_attempt_count
      })
      if (
        respData.ocr_status === 'pending' ||
        respData.face_match_status === 'pending'
      ) {
        dispatch({
          type: selfConstants.STATUS_PENDING
        })
        const {
          step2: { pollingCount }
        } = getState()
        if (pollingCount > selfConstants.KYC_UPLOAD_MAX_TIMEOUT_COUNT) {
          apiEndTime = window.performance.now()
          clearTimer(timer)
          dispatch(checkUploadRetriesToShowCorrectPage())
        } else {
          userEndTime = window.performance.now()
        }
      } else if (
        respData.ocr_status === 'completed' &&
        respData.face_match_status === 'completed'
      ) {
        userEndTime = window.performance.now()
        clearTimer(timer)
        dispatch({
          type: selfConstants.GET_STATUS_SUCCESS,
          payload: response
        })
        setTimeout(function () {
          dispatch(push({ pathname: '/step/3' }))
        }, 2000)
      } else if (
        respData.ocr_status === 'failed' &&
        respData.face_match_status === 'completed'
      ) {
        userEndTime = window.performance.now()
        clearTimer(timer)
        dispatch({
          type: selfConstants.GET_STATUS_SUCCESS,
          payload: response
        })
        setTimeout(function () {
          dispatch(push({ pathname: '/step/3' }))
        }, 2000)
      } else if (respData.face_match_status === 'no_match') {
        userEndTime = window.performance.now()
        clearTimer(timer)
        dispatch(checkUploadRetriesToShowCorrectPage())
      } else if (respData.face_match_status === 'failed') {
        userEndTime = window.performance.now()
        clearTimer(timer)
        dispatch(checkUploadRetriesToShowCorrectPage())
      } else {
        clearTimer(timer)
        dispatch({
          type: selfConstants.GET_STATUS_FAIL,
          payload: response
        })
      }
    }).catch(error => {
      apiEndTime = window.performance.now()
      userEndTime = window.performance.now()
      return dispatch({
        type: selfConstants.GET_STATUS_FAIL,
        payload: error
      })
    })
  }, 1000)
}

const clearTimer = timer => {
  clearInterval(timer)
}

export const changeStatus = payload => {
  return {
    type: selfConstants.CHANGE_STATUS,
    payload
  }
}
export const changeChosenType = payload => {
  return {
    type: selfConstants.CHANGE_CHOSEN_TYPE,
    payload
  }
}
export const setFileDetails = payload => {
  return {
    type: selfConstants.SET_FILE_DETAILS,
    payload
  }
}

export const handleFileError = (errorType, errorMsg, fileDetails) => (
  dispatch,
  getState
) => {
  const {
    step2: { chosenType }
  } = getState()
  dispatch(setErrorMessage(errorMsg))
  dispatch(changeStatus(selfConstants.STATUS.MAIN_SCREEN))
}

export const handleOnLoadFile = fileDetails => (dispatch, getState) => {
  const {
    step2: { chosenType }
  } = getState()
  dispatch(
    setFileDetails({
      key: chosenType,
      value: fileDetails
    })
  )
  dispatch(changeStatus(selfConstants.STATUS.MAIN_SCREEN))
}

export const setFiles = payload => {
  return {
    type: selfConstants.SET_FILES,
    payload
  }
}

export const renderAsliRICamera = () => async (dispatch, getState) => {
  const {
    app: { applicationRequestId }
  } = getState()
  let resAccount

  try {
    const urlAccount = `${API_LOS_URL}/application-data/data/req/${applicationRequestId}`
    resAccount = await request(urlAccount, {
      method: 'GET'
    })
  } catch (error) {
    dispatch(setErrorMessage(selfConstants.GET_ACCOUNT_ERROR))
  }

  if (resAccount) {
    dispatch({
      type: selfConstants.RENDER_ASLIRI_CAMERA_PAGE,
      payload: {
        account: resAccount
      }
    })
  }
}

export const setAsliRIFiles = payload => dispatch => {
  dispatch({
    type: selfConstants.SET_ASLIRI_FILE,
    payload: {
      ekycData: payload
    }
  })
}

export const submitEkyc = () => async (dispatch, getState) => {
  const {
    app: { merchant, applicationRequestId }
  } = getState()
  dispatch({ type: selfConstants.VERIFY_ASLIRI })

  const allTypes = selfConstants.ALL_TYPES[merchant]

  for (let i = 0; i < allTypes.length; ++i) {
    const { id } = allTypes[i]
    userStartTime = window.performance.now()
    const {
      step2: {
        files: {
          [id]: { dataUrl, name, type, size }
        }, account, ekycData
      }
    } = getState()

    const ktpFile = await compressImage(
      await helpers.srcToFile(dataUrl, name, type),
      { maxSizeMB: 1 })
    const selfiePhoto = await compressImage(DataURIToBlob(ekycData[0]),
      { maxSizeMB: 1 })

    const formData = new FormData()
    formData.append('requestId', account.applicationRequestId)
    formData.append('govIdType', 'KTP')
    formData.append('govId', account.noKtp)
    formData.append('fullName', account.fullName)
    formData.append('dateOfBirth', account.dateOfBirth)
    formData.append('placeOfBirth', account.cityOfBirth)
    formData.append('address', account.addressStreet)
    formData.append('selfiePhoto', selfiePhoto)
    formData.append('govIdPhoto', ktpFile)
    formData.append('email', account.email)
    formData.append('phone', account.msisdn)
    formData.append('userAgent', window.navigator.userAgent)

    try {
      const urlLiveness = `${API_PRIVY_KYC_URL}/privy/kyc/v1/professionalVerification`
      const resLiveness = await request(urlLiveness, {
        method: 'POST',
        data: formData
      })

      if (resLiveness.verified) {
        const status = 'KYC_PERURI_VERIFIED'
        const reason = ''

        const registerData = new FormData()
        registerData.append('applicationRequestId',
          account.applicationRequestId)
        registerData.append('govIdPhoto', ktpFile)
        registerData.append('selfiePhoto', selfiePhoto)
        const registerPrivy = `${API_PRIVY_URL}/user/v1/register`
        await request(registerPrivy, {
          method: 'POST',
          data: registerData
        })

        const urlUpdateStatus = `${API_LOS_URL}/application-data/data/update/${applicationRequestId}/status?status=${status}&reason=${reason}`
        await request(urlUpdateStatus, {
          method: 'POST'
        })
        dispatch(gotoLoanOffer())
      } else {
        throw new Error()
      }
    } catch (error) {
      dispatch(setAsliRIFiles([]))
      dispatch({
        type: selfConstants.RENDER_ASLIRI_PAGE
      })
      dispatch(setErrorMessage(selfConstants.VERIFICATION_ASLIRI_ERROR))
    }
  }
}
