import React from 'react'
import { connect } from 'react-redux'
import * as appActions from '@/containers/App/actions'
import { bindActionCreators } from 'redux'
import * as selfActions from './actions'
import * as router from 'react-router-redux'
import Card, { Heading } from '@/components/Card'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import HorizontalLine from '@/components/HorizontalLine'
import { Table } from '@/containers/RepaymentsPage/styledComponents'
import BackButton from '@/components/BackButton'
import _get from 'lodash/get'
import warningIcon from '@/static/images/warning.svg'
import * as ga from '@/utils/gtag'
import * as appUtils from '@/containers/App/utils'
import * as appConstants from '@/containers/App/constants'

export class InstallmentSummary extends React.Component {
  componentDidMount () {
    // ga.pageview({
    //   title: 'Installment Summary',
    //   location: '/repayments/id/installment-summary',
    //   path: '/repayments/id/installment-summary'
    // })
  }

  handleClickBack = () => {
    const { router, token } = this.props
    router.push(`/repayments/${token}/select-channel`)
    ga.event({
      category: 'click',
      action: 'repymt_ins_summ_back',
      label: 'button',
      value: 1
    })
  };

  render () {
    const {
      resultGetRepayments,
      locale: { lang, currencyConfig }
    } = this.props
    const installmentSchedule = _get(
      resultGetRepayments,
      'data.loan_details.0.loan.installment_schedule',
      []
    )
    const lateFees = _get(
      resultGetRepayments,
      'data.loan_details.0.loan.installment_late_fees'
    )

    const numberFormatter = new Intl.NumberFormat(lang, {
      style: 'currency',
      ...currencyConfig
    })

    const dueDate = _get(
      resultGetRepayments,
      'data.loan_details.0.loan.due_date'
    )
    const cycleDate = dueDate // dueDate format: yyyy-mm-dd
      ? dueDate.split('-')[2]
      : appConstants.NO_VALUE_SYMBOL

    return (
      <div>
        <Card>
          <Heading>
            <FormattedMessage id='repayments.selectChannel.heading.loanDetails' />
          </Heading>
          <HorizontalLine />
          <div className='pt-2'>
            <Table>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id='repayments.installmentSummary.label.dueDate' />
                  </th>
                  <th>
                    <FormattedMessage id='repayments.installmentSummary.label.status' />
                  </th>
                  <th className='text-right'>
                    <FormattedMessage id='repayments.installmentSummary.label.amountPending' />
                  </th>
                </tr>
              </thead>
              <tbody>
                {installmentSchedule.map((item, index) => {
                  const { amount, due_date, status } = item
                  return (
                    <tr key={index}>
                      <td>
                        {appUtils.formatToDDMMYYYY(
                          new Date(Date.parse(due_date))
                        )}
                      </td>
                      <td>
                        <FormattedMessage
                          id={`repayments.installmentSummary.status.${status.toLowerCase()}`}
                        />
                      </td>
                      <td className='text-right'>
                        <strong>{numberFormatter.format(amount)}</strong>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
        </Card>
        <div className='mt-2 subtitle d-flex align-items-center'>
          <img src={warningIcon} className='mr-2' />
          <FormattedHTMLMessage
            id='repayments.installmentSummary.msg.lateFee'
            values={{
              amount: numberFormatter.format(lateFees),
              date: cycleDate
            }}
          />
        </div>
        <BackButton onClick={this.handleClickBack} />
      </div>
    )
  }
}

export const mapStateToProps = state => {
  return {
    locale: state.app.locale,
    resultGetRepayments: state.repaymentsSelectChannel.resultGetRepayments,
    curAmountToPay: state.repaymentsSelectChannel.curAmountToPay
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    router: bindActionCreators(router, dispatch),
    appActions: bindActionCreators(appActions, dispatch),
    selfActions: bindActionCreators(selfActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstallmentSummary)
