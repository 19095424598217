import React from 'react'
import { connect } from 'react-redux'
import { Wrapper } from './styledComponents'

class BottomLayout extends React.Component {
  componentDidMount () {
    const { noSeparateLine } = this.props
  }

  componentWillUnmount () {
  }

  render () {
    const {
      children,
      noSeparateLine = false
    } = this.props
    return (
      <Wrapper className={'bottomLayout'}>
        {children}
      </Wrapper>
    )
  }
}

export const mapStateToProps = state => {
  return {}
}

export const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BottomLayout)
