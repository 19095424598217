import React from 'react'
import { StyledButton } from './styledComponents'
import _debounce from 'lodash/debounce'

class Button extends React.Component {
  constructor (props) {
    super(props)
    this.debouncedOnClick = _debounce(
      args => {
        this.props.onClick(args)
      },
      1500,
      {
        leading: true,
        trailing: false
      }
    )
  }

  handleOnClick = (...args) => {
    this.debouncedOnClick(args)
  }

  render () {
    const { children, ...rest } = this.props
    return (
      <StyledButton {...rest} onClick={this.handleOnClick}>
        {children}
      </StyledButton>
    )
  }
}

export default Button
