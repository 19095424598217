import React from 'react'
import { connect } from 'react-redux'
import * as selfActions from './actions'
import * as appActions from '@/containers/App/actions'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import Button from '@/components/Button'
import ButtonFixed from '@/components/ButtonFixed'
import * as selfConstants from './constants'
import FullScreenPopup from '@/components/FullScreenPopup'
import InstructionsPage from './components/InstructionsPage'
import CameraHandler from '@/components/CameraHandler'
import _get from 'lodash/get'
import Iframe from 'react-iframe'
import * as step4Actions from '@/containers/Steps/4/actions'
import * as pendingKYCActions from '@/containers/PendingKYCPage/actions'

import {
  FailureText,
  ImageUploaderWrapper,
  SuccessText,
  WarningText
} from './styledComponents.js'
import successSmiley from './success-smiley.svg'
import failureSmiley from './failure-smiley.svg'
import spinner from './spinner.svg'
import SignaturePage from './components/SignaturePage'
import { LayoutBottom } from '../4/components/styledComponents'
import AsliriCameraPage from '@/containers/Steps/3/components/AsliriCameraPage'
import UserConsentAgreement
  from '@/containers/Steps/3/components/UserConsentAgreement'
import KTPCameraPage from '@/containers/Steps/3/components/KTPCameraPage'

export class KYCPage extends React.Component {
  constructor (props) {
    super(props)
    this.inputRef = React.createRef()
    this.childKey = 0
  }

  componentDidMount () {
    const { pendingKYCActions, selfActions, merchant } = this.props
    pendingKYCActions.checkKYC()
    selfActions.checkStatus()
    selfActions.setAsliRIFiles([])
    selfActions.setFiles(
      selfConstants.ALL_TYPES[merchant].reduce((res, cur) => {
        const { id } = cur
        res[id] = {}
        return res
      }, {})
    )
    window.addEventListener('message', this.handleIframeTask)
  }

  mailSupport () {
  }

  handleIframeTask = (e) => {
    if (typeof e.data.message !== 'undefined') {
      const { status } = this.props
      console.log('iframe task called with event : ', e)
      const message = JSON.parse(e.data.message)
      // if (message.resultCode == 1012 || message.resultCode == 0){
      const { selfActions } = this.props
      console.log(' process nextstep')
      if (status === selfConstants.STATUS.SHOWING_FRAME_PERURI) {
        selfActions.sendReportKyc(message)
        console.log('get iframe url speciment')
      } else {
        selfActions.sendSpecimentResult(message)
        console.log('send speciment result')
      }
      // } else {
      //   console.log(' Stay or failed')
      // }
    }
  }

  callSupport () {
  }

  handleOnFinishSignature = () => {
    const { selfActions } = this.props
    selfActions.onSignatureSuccess()
  }
  handleOnFailedGetSignature = () => {
    const { selfActions } = this.props
    selfActions.onSignatureFailed()
    console.log('signature failed')
  }
  handleReject = () => {
    window.location.replace('https://trustiq.id/')
  }
  handleRecheck = () => {
    const { selfActions } = this.props
    selfActions.onProcessRecheck()
  }
  handleOnSuccessSignature = () => {
    const { selfActions } = this.props
    selfActions.gotoLoanOffer()
  }

  submit = () => {
    document.getElementById('verifyKYC').className += ' rotate'
    const { selfActions } = this.props
    // const message = {result_code: '1111', result_desc: '132423'}
    setTimeout(() => {
      selfActions.verifyImages()
      // console.log('messsage', message)
      // selfActions.sendReportKyc(message)
    }, 230)
  }

  submitEkyc = () => {
    document.getElementById('asliri-button').className += ' rotate'
    const { selfActions } = this.props
    setTimeout(() => {
      selfActions.submitEkyc()
      // console.log('messsage', message)
      // selfActions.sendReportKyc(message)
    }, 230)
  }

  goToKYCUpload = () => {
    const { selfActions } = this.props
    selfActions.goToKYCUpload()
  }

  handleClickImageUploadBox = id => {
    const { selfActions, merchant } = this.props
    selfActions.setErrorMessage(null)
    selfActions.changeChosenType(id)
    if (merchant === 'viettel' && id === 'kyc_reverse') {
      // need instruction gif from design
      this.inputRef.current.click()
    } else {
      selfActions.changeStatus(selfConstants.STATUS.SHOWING_INSTRUCTIONS)
    }
  }

  handleClosePrivyTerm = () => {
    const { selfActions } = this.props
    selfActions.changeStatus('')
  }

  handleClickAsliriRecordBox = () => {
    const { selfActions } = this.props
    selfActions.renderAsliRICamera()
  }

  handleOnFileError = (errorType, errorMsg, fileDetails) => {
    const { selfActions } = this.props
    selfActions.handleFileError(errorType, errorMsg, fileDetails)
  }

  handleOnLoadFile = fileDetails => {
    const { selfActions } = this.props
    selfActions.handleOnLoadFile(fileDetails)
  }

  renderSwitch (status) {
    const {
      disabled,
      buttonText,
      chosenType,
      files,
      merchant,
      ekycData
    } = this.props
    switch (status) {
      case selfConstants.STATUS.VERIFYING:
        return (
          <React.Fragment>
            <div className="mt-5 rotateSpinner">
              <img src={spinner} />
            </div>
            <div className="subtitle text-center mt-5">
              <WarningText>
                <FormattedMessage id="step2.subtitlewarningwhileverifying" />
              </WarningText>
            </div>
          </React.Fragment>
        )
      case selfConstants.STATUS.SUCCESS:
        return (
          <React.Fragment>
            <div className="mt-5 rotateSuccessIcon">
              <img src={successSmiley} />
            </div>
            <div className="subtitle text-center mt-5">
              <SuccessText>
                <FormattedMessage id="step2.subtitlesuccess" />
              </SuccessText>
            </div>
          </React.Fragment>
        )
      case selfConstants.STATUS.FAILURE:
        return (
          <React.Fragment>
            <div className="mt-5">
              <img src={failureSmiley} />
            </div>
            <div className="mt-5 text-center">
              <span className="subtitle error-color">
                <FormattedMessage id="step2.error.uploadagain" />
              </span>
            </div>
            <div className="mt-4">
              <Button disabled={false} onClick={this.goToKYCUpload}>
                <FormattedMessage id="step2.btn.tryagain" />
              </Button>
            </div>
          </React.Fragment>
        )
      case selfConstants.STATUS.FAILURE_WITH_RECHECK:
        return (
          <React.Fragment>
            <div className="mt-5">
              <img src={failureSmiley} />
            </div>
            <div className="mt-5 text-center">
              <span className="subtitle error-color">
                <FormattedMessage id="step2.subkycfailed" />
              </span>
            </div>
            <div>
              <LayoutBottom>
                <Button
                  data-test-id="btn-proceed"
                  disabled={false}
                  onClick={this.handleReject}
                  style={{ minWidth: '40%', background: 'DeepSkyBlue' }}
                >
                  Batal
                </Button>
                <Button
                  disabled={false}
                  onClick={this.handleRecheck}
                  style={{
                    marginLeft: '1rem',
                    minWidth: '40%',
                    background: '#394dff'
                  }}
                >
                  Recheck
                </Button>
              </LayoutBottom>
            </div>
          </React.Fragment>
        )
      case selfConstants.STATUS.MAX_RETRIES_REACHED:
        return (
          <React.Fragment>
            <div className="mt-5">
              <img src={failureSmiley} />
            </div>
            <div className="mt-5 text-center">
              <span className="subtitle error-color">
                <FormattedMessage id="step2.error.retrylimitreached" />
              </span>
            </div>
          </React.Fragment>
        )
      case selfConstants.STATUS.SHOWING_PROGRESS_KYC_SIGNATURE: {
        ++this.childKey
        return (
          <React.Fragment>
            <div className="mt-5 rotateSpinner">
              <img src={spinner} />
            </div>
            <div className="subtitle text-center mt-5">
              <WarningText>
                <SignaturePage
                  applicationRequestId={this.props.applicationRequest}
                  onFinishedSignature={this.handleOnFinishSignature}
                  onFailedGetSignature={this.handleOnFailedGetSignature}
                  key={this.childKey}
                  maxTime={36}
                />
              </WarningText>
            </div>
          </React.Fragment>
        )
      }
      case selfConstants.STATUS.SUCCESS_GET_SIGNATURE: {
        return (
          <React.Fragment>
            <div className="mt-5 rotateSuccessIcon">
              <img src={successSmiley} />
            </div>
            <div className="subtitle text-center mt-5">
              <button style={{ display: 'none' }}
                      onClick={this.handleOnSuccessSignature()} />
            </div>
          </React.Fragment>
        )
      }
      case selfConstants.STATUS.SHOWING_INSTRUCTIONS: {
        return (
          <FullScreenPopup>
            <InstructionsPage />
          </FullScreenPopup>
        )
      }
      case selfConstants.STATUS.SHOWING_FRAME_PERURI: {
        const url = this.props.iframeSrc
        // rendering iframe here
        return (
          <FullScreenPopup>
            <Iframe url={url}
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allow="geolocation; microphone; camera"
            />
          </FullScreenPopup>
        )
      }
      case selfConstants.STATUS.SHOWING_FRAME_PERURI_SPECIMENT: {
        const url = this.props.iframeSrc
        // rendering iframe here
        return (
          <FullScreenPopup>
            <Iframe url={url}
                    width="100%"
                    height="100%"
            />
          </FullScreenPopup>
        )
      }
      case selfConstants.STATUS.CAMERA_ON: {
        let CameraOnComponent = null
        const foundChosenItem = selfConstants.ALL_TYPES[merchant].find(
          item => item.id === chosenType
        )
        if (foundChosenItem) {
          CameraOnComponent = foundChosenItem.cameraOnPage
        }
        return <FullScreenPopup>{CameraOnComponent}</FullScreenPopup>
      }
      case selfConstants.STATUS.SHOWING_ASLIRI: {
        const id = 'asliri'
        const ekycDataExist = Array.isArray(ekycData) && ekycData.length
        return (
          <React.Fragment>
            <div className="mt-4">
              <ButtonFixed
                id="asliri-button"
                disabled={disabled}
                onClick={this.submitEkyc}
              >
                <FormattedMessage id={buttonText} />
              </ButtonFixed>
            </div>
            <ImageUploaderWrapper>
              <div
                data-image-box
                key={id}
                id={id}
                className="d-flex align-items-center pr-2 mt-3"
                onClick={this.handleClickAsliriRecordBox}
              >
                <div className="mx-3">
                  <img
                    data-thumbnail
                    src={ekycDataExist ? ekycData[0] : require(
                      './placeholder-selfie.svg')}
                    style={{
                      objectFit: ekycDataExist ? 'cover' : 'contain'
                    }}
                  />
                </div>
                <div>
                  <div data-title>
                    <FormattedMessage
                      id={'step2.btn.record_asliri_title'} />
                  </div>
                  <div data-subtitle>
                    <FormattedMessage
                      id={'step2.btn.record_asliri_subtitle'} />
                  </div>
                </div>
              </div>
            </ImageUploaderWrapper>
          </React.Fragment>
        )
      }
      case selfConstants.STATUS.SHOWING_ASLIRI_CAMERA: {
        return (
          <FullScreenPopup noScroll noPadding>
            <AsliriCameraPage />
          </FullScreenPopup>
        )
      }
      case selfConstants.STATUS.SHOW_KTP_CAMERA: {
        return (
          <FullScreenPopup noScroll noPadding>
            <KTPCameraPage />
          </FullScreenPopup>
        )
      }
      case selfConstants.STATUS.SHOW_PRIVY_TERMS: {
        return (
          <FullScreenPopup>
            <div className="title text-center mb-4">
              <strong>
                <FormattedMessage id="step4.userConsentAgreement.title" />
              </strong>
            </div>

            <div style={{
              marginBottom: '60px',
              paddingTop: '1rem',
              paddingBottom: '1rem',
              maxWidth: '800px',
              marginLeft: 'auto', marginRight: 'auto'
            }}>
              <UserConsentAgreement />
            </div>

            <ButtonFixed onClick={this.handleClosePrivyTerm}>
              <FormattedMessage id="step1.btn.proceed" />
            </ButtonFixed>
          </FullScreenPopup>
        )
      }
      default:
        return (
          <React.Fragment>
            <ImageUploaderWrapper>
              {selfConstants.ALL_TYPES[merchant].map(image => {
                const { id, placeholderImg, title, subtitle } = image
                return (
                  <div
                    data-image-box
                    key={id}
                    id={id}
                    className="d-flex align-items-center pr-2 mt-3"
                    onClick={() => this.handleClickImageUploadBox(id)}
                  >
                    <div className="mx-3">
                      <img
                        data-thumbnail
                        src={_get(files, `${id}.dataUrl`) || placeholderImg}
                      />
                    </div>
                    <div>
                      <div data-title>
                        <FormattedMessage id={title} />
                      </div>
                      <div data-subtitle>
                        <FormattedMessage id={subtitle} />
                      </div>
                    </div>
                  </div>
                )
              })}
            </ImageUploaderWrapper>
            <div className="mt-4">
              <ButtonFixed
                id="verifyKYC"
                disabled={disabled}
                onClick={this.submit}
              >
                <FormattedMessage id={buttonText} />
              </ButtonFixed>
            </div>
            <CameraHandler
              ref={this.inputRef}
              onError={this.handleOnFileError}
              onLoadFile={this.handleOnLoadFile}
            />
          </React.Fragment>
        )
    }
  }

  render () {
    const { title, subtitle, status, errorMessage } = this.props
    return (
      <div className="d-flex flex-column align-items-center">
        <div className="title">
          <strong>
            <FormattedMessage id={title} />
          </strong>
        </div>
        <div className="subtitle text-center mt-2">
          <FormattedMessage id={subtitle} />
        </div>
        {this.renderSwitch(status)}
        {errorMessage && (
          <FailureText className="mt-3 text-center">
            <FormattedMessage id={errorMessage} />
          </FailureText>
        )}
      </div>
    )
  }
}

export const mapStateToProps = state => {
  return {
    disabled: state.step2.disabled,
    title: state.step2.title,
    subtitle: state.step2.subtitle,
    status: state.step2.status,
    buttonText: state.step2.buttonText,
    iframeSrc: state.step2.iframeSrc,
    applicationRequest: state.step2.applicationRequest,
    errorMessage: state.step2.errorMessage,
    uploadretries: state.step2.uploadretries,
    chosenType: state.step2.chosenType,
    files: state.step2.files,
    merchant: state.app.merchant,
    ekycData: state.step2.ekycData
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    selfActions: bindActionCreators(selfActions, dispatch),
    step4Actions: bindActionCreators(step4Actions, dispatch),
    appActions: bindActionCreators(appActions, dispatch),
    pendingKYCActions: bindActionCreators(pendingKYCActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KYCPage)
