import { primaryColor } from '@/containers/App/globalStyles'

export const sliderHandleStyle = {
  height: 26,
  width: 26,
  marginLeft: -13,
  marginTop: -10,
  backgroundColor: '#fff',
  border: `4px solid ${primaryColor}`,
  padding: 4
}

export const sliderTrackStyle = { backgroundColor: primaryColor, height: 6 }
export const sliderRailStyle = { backgroundColor: '#EDEDED', height: 6 }
export const dotStyle = { display: 'none' }
