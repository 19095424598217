import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as appActions from '@/containers/App/actions'
import { FormattedMessage } from 'react-intl'
import Button from '@/components/Button'
import * as ga from '@/utils/gtag'

class MoreInfoContent extends React.Component {
  handleCloseMoreInfoPopup = () => {
    const { appActions } = this.props
    appActions.updateModal({ show: false })
    ga.event({
      category: 'click',
      action: 'step4_info_link',
      label: 'close',
      value: 1
    })
  };

  render () {
    const {
      selectedProduct,
      locale: { lang, currencyConfig },
      dueDate
    } = this.props

    const formatter = new Intl.NumberFormat(lang, {
      style: 'currency',
      ...currencyConfig
    })

    const monthlyInterestRate = selectedProduct.monthly_interest_rate
    const serviceChargePercentage = selectedProduct.service_charge_percentage
    const lateFees = selectedProduct.late_fees

    return (
      <div>
        <div className='text-center'>
          <h5>
            <strong>
              <FormattedMessage id='step4.moreInfoContent.title' />
            </strong>
          </h5>
        </div>
        <div>
          <div className='mt-4'>
            <div>
              <strong>
                <FormattedMessage id='step4.moreInfoContent.label.interest' />
              </strong>
            </div>
            <div>
              <FormattedMessage
                id='step4.moreInfoContent.value.interest'
                values={{ interest: `${monthlyInterestRate}%` }}
              />
            </div>
          </div>

          <div className='mt-3'>
            <div>
              <strong>
                <FormattedMessage id='step4.moreInfoContent.label.serviceFee' />
              </strong>
            </div>
            <div>
              <FormattedMessage
                id='step4.moreInfoContent.value.serviceFee'
                values={{ fee: `${serviceChargePercentage}%` }}
              />
            </div>
          </div>

          <div className='mt-3'>
            <div>
              <strong>
                <FormattedMessage id='step4.moreInfoContent.label.lateFee' />
              </strong>
            </div>
            <div>
              <FormattedMessage
                id='step4.moreInfoContent.value.lateFee'
                values={{ amount: formatter.format(lateFees) }}
              />
            </div>
          </div>

          <div className='mt-3'>
            <div>
              <strong>
                <FormattedMessage id='step4.moreInfoContent.label.repaymentDueDate' />
              </strong>
            </div>
            <div>
              <FormattedMessage
                id='step4.moreInfoContent.value.repaymentDueDate'
                values={{ date: `${dueDate}th` }}
              />
            </div>
          </div>

          <div className='mt-3'>
            <div>
              <strong>
                <FormattedMessage id='step4.moreInfoContent.label.howToRepay' />
              </strong>
            </div>
            <div>
              <FormattedMessage id='step4.moreInfoContent.value.howToRepay' />
            </div>
          </div>
        </div>
        <div className='text-center mt-4'>
          <Button
            data-test-id='btn-close'
            onClick={this.handleCloseMoreInfoPopup}
          >
            <FormattedMessage id='step4.moreInfoContent.btn.close' />
          </Button>
        </div>
      </div>
    )
  }
}

export const mapStateToProps = state => {
  return {
    locale: state.app.locale,
    dueDate: state.step4.dueDate,
    selectedProduct: state.step4.selectedProduct
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    appActions: bindActionCreators(appActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MoreInfoContent)
