import styled from 'styled-components'

const Card = styled.div`
  background: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  padding: 10px;
`
export const CardVerify = styled.div`
  background: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  padding: 30px;
  `
export const Heading = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: #000000;
`
export default Card
