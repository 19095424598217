export default {
  highest_education: '',
  error_message: '',
  status: '',
  title: 'step3.title',
  subtitle: 'step3.subtitle',
  retryCount: 0,
  schema: {},
  uiSchema: {},
  formData: {},
  errorMessages: {},
  fieldNamesList: [],
  gaFieldTags: {},
  isTouched: []
}
