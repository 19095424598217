import styled from 'styled-components'

export const Feature = styled.div`
  background: #f3f4ff;
  border-radius: 3px;
  padding: 10px;
  margin: 10px 10px 0;
  display: flex;
  align-items: center;

  [data-icon="true"] {
    margin-right: 10px;
    padding: 10px;
  }
`
