import request from '@/utils/request'
import * as selfConstants from './constants'
import * as appConstants from '@/containers/App/constants'
import _get from 'lodash/get'
import { push, replace } from 'react-router-redux'
import { persistor } from '@/store/configureStore'

const API_URL = 'https://api.bpragm.com/api/v1'
const API_LOS_URL = 'https://los.bpragm.com/api'
export const initialize = () => async dispatch => {
  persistor.purge() // clean localStorage cache when user begins his journey
  // await dispatch(getApplicationRequestId())
  await dispatch(getPhoneNumbers())
  // dispatch(sendOTP())
}

export const handleRedirect = () => (dispatch, getState) => {
  const {
    app: { applicationRequestId }
  } = getState()

  dispatch(replace({ pathname: '/step/3' }))

  // Comment out redirect, need further testing
  // const urlGetAppData = API_LOS_URL + '/application-data/status/inquiry/' +
  //   applicationRequestId
  // request(urlGetAppData, {
  //   method: 'GET'
  // }).then(resp => {
  //   switch (resp.applicationStatus) {
  //     case selfConstants.LENDER_AGREED_STATUS:
  //       dispatch(replace({ pathname: '/step/3' }))
  //       break
  //     case selfConstants.KYC_PERURI_VERIFIED_STATUS:
  //       dispatch(replace({ pathname: '/step/4' }))
  //       break
  //     default:
  //       dispatch(replace({ pathname: '/apologies' }))
  //   }
  // })
}

export const getApplicationRequestId = () => async dispatch => {
  dispatch({ type: selfConstants.GET_APPLICATION_REQUEST_ID })
  console.log('url : ', API_URL)
  try {
    const response = await request(`${API_URL}/application_requests`, {
      method: 'POST'
    })
    dispatch({
      type: selfConstants.GET_APPLICATION_REQUEST_ID_SUCCESS,
      payload: response
    })
  } catch (error) {
    dispatch({
      type: selfConstants.GET_APPLICATION_REQUEST_ID_FAIL,
      payload: error
    })
  }
}

export const getPhoneNumbers = () => (dispatch, getState) => {
  const {
    app: { applicationRequestId }
  } = getState()
  dispatch({ type: selfConstants.GET_PHONE_NUMBERS })
  dispatch({ type: appConstants.UPDATE_LOADER, payload: true })

  return request(
    `${API_URL}/application_requests/${applicationRequestId}/phone_numbers`,
    { method: 'GET' }
  ).then(response => {
    dispatch({ type: appConstants.UPDATE_LOADER, payload: false })
    return dispatch({
      type: selfConstants.GET_PHONE_NUMBERS_SUCCESS,
      payload: response
    })
  }).catch(error => {
    dispatch({ type: appConstants.UPDATE_LOADER, payload: false })
    return dispatch({
      type: selfConstants.GET_PHONE_NUMBERS_FAIL,
      payload: error
    })
  })
}

export const updatePhoneNumber = newPhoneNumber => {
  return {
    type: selfConstants.UPDATE_PHONE_NUMBER,
    payload: newPhoneNumber
  }
}

export const updatePhase = newPhase => {
  return {
    type: selfConstants.UPDATE_PHASE,
    payload: newPhase
  }
}

export const updateOTP = newOTP => {
  return {
    type: selfConstants.UPDATE_OTP,
    payload: newOTP
  }
}
export const updateTimeRemaining = newTime => {
  return {
    type: selfConstants.UPDATE_TIME_REMAINING,
    payload: newTime
  }
}

export const sendOTP = () => (dispatch, getState) => {
  const {
    app: { applicationRequestId, isResumable },
    step1: { phoneNumber }
  } = getState()

  dispatch({ type: selfConstants.SEND_OTP })
  dispatch({ type: appConstants.UPDATE_LOADER, payload: true })
  // const urlToSendOtp = `${API_URL}/application_requests/${applicationRequestId}/verifications`
  // request(urlToSendOtp, {
  //   method: 'POST',
  //   data: {
  //     phone_number: phoneNumber,
  //     country_code: '0062'
  //   }
  // }).then(response => {
  //   dispatch({
  //     type: selfConstants.SEND_OTP_SUCCESS,
  //     payload: response
  //   })
  //   dispatch(updatePhase(selfConstants.PHASE.VERIFY_PHONE_NUMBER))
  //   dispatch({ type: appConstants.UPDATE_LOADER, payload: false })
  // }).catch(error => {
  //   dispatch({
  //     type: selfConstants.SEND_OTP_FAIL,
  //     payload: error
  //   })
  //   dispatch({ type: appConstants.UPDATE_LOADER, payload: false })
  // })
}

export const logTransaction = status => (dispatch, getState) => {
  const {
    app: { applicationRequestId }
  } = getState()

  const urlToLogTransaction = `${API_LOS_URL}/application-data/log/update/${applicationRequestId}/status/${status}`
  request(urlToLogTransaction, {
    method: 'POST'
  }).then(response => {
    console.log(response)
  })
}

export const verifyOTP = otp => (dispatch, getState) => {
  const {
    app: { applicationRequestId, isResumable },
    step1: { timeRemaining, resultSendOtp }
  } = getState()

  dispatch({ type: selfConstants.VERIFY_OTP })
  dispatch({ type: appConstants.UPDATE_LOADER, payload: true })

  const otpVerificationId = _get(resultSendOtp, 'data.otp_verification_id')
  const urlToVerifyOtp = `${API_URL}/application_requests/${applicationRequestId}/verifications/${otpVerificationId}/verify`
  request(urlToVerifyOtp, {
    method: 'POST',
    data: { otp: otp.join('') }
  }).then(response => {
    dispatch({ type: appConstants.UPDATE_LOADER, payload: false })
    if (!isResumable) {
      // dispatch(push({ pathname: '/step/3' }))
      const {
        data: { redirect_to }
      } = response

      switch (redirect_to) {
        case 'product_selection': {
          dispatch(push({ pathname: '/thanks-approve' }))
          break
        }
        case 'wait_ver': {
          dispatch(push({ pathname: '/thanks-accept' }))
          break
        }
        case 'product_selected': {
          dispatch(push({ pathname: '/thanks-wait' }))
          break
        }
        case 'loan_approved': {
          dispatch(logTransaction('PREVIEW_CONTRACT'))
          dispatch(push({ pathname: '/step/5' }))
          break
        }
        case 'kyc': {
          dispatch(push({ pathname: '/step/3' }))
          break
        }
        case 'otp': {
          dispatch(push({ pathname: '/terms-and-conditions-step1' }))
          break
        }
        default:
          dispatch(push({ pathname: '/not-found' }))
      }
    } else {
      const {
        data: { redirect_to }
      } = response
      switch (redirect_to) {
        case 'product_selection': {
          dispatch(push({ pathname: '/resume-application' }))
          break
        }
        case 'dashboard': {
          dispatch(push({ pathname: '/dashboard' }))
          break
        }
        default:
          dispatch(push({ pathname: '/not-found' }))
      }
    }
    dispatch({
      type: selfConstants.VERIFY_OTP_SUCCESS,
      payload: response
    })
    window.cdcm.user_login = 'True'
  }).catch(error => {
    dispatch({
      type: selfConstants.VERIFY_OTP_FAIL,
      payload: error
    })
    dispatch({ type: appConstants.UPDATE_LOADER, payload: false })
  })
}
