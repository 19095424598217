import request from '@/utils/request'
import * as selfConstants from './constants'
import { push } from 'react-router-redux'
import * as ga from '@/utils/gtag'

export const getApplicationStatus = (ktp, selfie) => (dispatch, getState) => {
  let apiStartTime = 0
  let apiEndTime = 0
  const {
    app: { applicationRequestId }
  } = getState()
  dispatch({ type: selfConstants.GET_APPLICATION_STATUS })
  apiStartTime = window.performance.now()
  return request(
    `${API_URL}/application_requests/${applicationRequestId}/product_eligibility`,
    { method: 'POST' }
  )
    .then(response => {
      apiEndTime = window.performance.now()
      ga.time({
        category: 'Loan Application Retrieval',
        name: 'load_time',
        value: apiEndTime - apiStartTime,
        label: 'application_retrieval_api'
      })
      dispatch({
        type: selfConstants.GET_APPLICATION_STATUS_SUCCESS
      })
      dispatch(push({ pathname: '/step/4' }))
    })
    .catch(error => {
      apiEndTime = window.performance.now()
      ga.time({
        category: 'Loan Application Retrieval',
        name: 'load_time',
        value: apiEndTime - apiStartTime,
        label: 'application_retrieval_api'
      })
      const {
        errors: { code }
      } = error
      if (code === selfConstants.REJECTED_ERROR_CODE) {
        window.cdcm.user_return_type = '3'
        ga.pageview({ title: 'Return User - Balance Reject', location: '/resume-application?status=balance-reject', path: '/resume-application?status=balance-reject' })
        dispatch({
          type: selfConstants.GET_APPLICATION_STATUS_USER_REJECTED
        })
      } else {
        ga.pageview({ title: 'Application Retrieval Failed', location: '/resume-application?status=retrieval-failed', path: '/resume-application?status=retrieval-failed' })
        dispatch({
          type: selfConstants.GET_APPLICATION_STATUS_API_FAIL
        })
      }
    })
}
