import React from 'react'
import Steps from '@/components/Steps'
import logo from '@/static/images/bpr-logo.png'
import { connect } from 'react-redux'
import { Wrapper } from './styledComponents'

class Layout extends React.Component {
  componentDidMount () {
    const { noSeparateLine } = this.props
    if (!noSeparateLine) {
      document.getElementsByTagName('body')[0].style.background = '#f8f8f8'
    }
  }

  componentWillUnmount () {
    document.getElementsByTagName('body')[0].style.background = ''
  }

  render () {
    const {
      children,
      stepId,
      noSeparateLine = false,
      doesntKnowHowManySteps
    } = this.props
    const stepIdInInteger = parseInt(stepId, 10)
    return (
      <Wrapper noSeparateLine={noSeparateLine}>
        {!noSeparateLine ? (
          <div data-header>
            <div className="flex-grow-1" />
            <div>
              <img data-logo src={logo} />
            </div>
            <div className="flex-grow-1" />
          </div>
        ) : (
          <div>
            <img data-logo src={logo} />
          </div>
        )}

        {/*{stepId &&*/}
        {/*  !doesntKnowHowManySteps && (*/}
        {/*  <div className='my-3'>*/}
        {/*    <Steps maxSteps={5} currentStep={stepIdInInteger} />*/}
        {/*  </div>*/}
        {/*)}*/}

        {children}
      </Wrapper>
    )
  }
}

export const mapStateToProps = state => {
  return {}
}

export const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout)
