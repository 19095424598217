import styled from 'styled-components'
import { primaryColor } from '@/containers/App/globalStyles'

export const HighlightText = styled.span`
  font-weight: bold;
  color: ${primaryColor};
`
export const InfoWrapper = styled.div`
  border: red;
  width: 100%;
  border-top: 1px dashed #979797;
  & > div {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #979797;
    padding: 5px;
  }
`
export const LinkButton = styled.span`
  text-decoration: underline;
  color: #007bff;
  cursor: pointer;
`
export const CardBox = styled.div`
.cards {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
  margin-top: 20px;
}
@media (min-width: 400px) {
  .cards { grid-template-columns: repeat(2, 1fr); }
}
@media only screen and (max-width: 414px) {
  .cards { grid-template-columns: repeat(2, 1fr); }
}
.card {
    background-color: DarkOrange;
    color: white;
    padding: 0.5rem;
    height: 7rem;
    width: 170px;
  }
`

export const HorizontalLine = styled.hr`
  background: dodgerblue;
  position: relative;
  height: 1px;
  margin: 10px 0;
`
export const LayoutBottom = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 5px;
  background-color: white;
  color: white;
  text-align: center;
  display: flex;
  align-items: right;
  justify-content: center;
`
