import styled from 'styled-components'

export const NotFoundSubtitle = styled.div`
  font-size: 19px;
  font-weight: 500;
  opacity: 0.7;
  color: #202020;
`

export const NotFoundMessage = styled.div`
  font-size: 14px;
  color: #4a4a4a;
  font-weight: 300;
  opacity: 1;
`
