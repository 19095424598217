export default {
  curAmountToPay: null,
  isAbleToChooseAmount: null,
  channels: [
    {
      paymentMode: 'Alfamart',
      displayText: 'Alfamart',
      methods: ['repayments.method.alfamart']
    },
    {
      paymentMode: 'Bca',
      displayText: 'VA BCA',
      methods: [
        'repayments.method.vabca.atm',
        'repayments.method.vabca.klikbca',
        'repayments.method.vabca.mbca'
      ]
    },
    {
      paymentMode: 'Mandiri',
      displayText: 'VA Mandiri',
      methods: [
        'repayments.method.vamandiri.atm',
        'repayments.method.vamandiri.online',
        'repayments.method.vamandiri.internetbanking',
        'repayments.method.vamandiri.atmprima',
        'repayments.method.vamandiri.atmbersama'
      ]
    },
    {
      paymentMode: 'Indomaret',
      displayText: 'Indomaret',
      methods: ['repayments.method.indomaret']
    }
  ],
  selectedChannel: {},
  resultGetRepayments: {},
  errorGetRepayments: {},
  status: ''
}
