import Step1 from './1'
import Step2 from './2' // bio validation
import Step3 from './3' // kyc
import Step4 from './4' // confirmation
import Step5 from './5' // thank you page or finished view
import Step6 from './6' // thank you page or finished view

export default {
  1: Step1,
  2: Step2,
  3: Step3,
  4: Step4,
  5: Step5,
  6: Step6,
}
