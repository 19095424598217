import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as appActions from '@/containers/App/actions'
import { FormattedMessage } from 'react-intl'
import Button from '@/components/Button'
import { AttemptText } from './styledComponents'
import warningIcon from './warning-icon.svg'
import * as ga from '@/utils/gtag'

class PopupWarning extends React.Component {
  componentDidMount () {
    ga.event({
      category: 'occurrence',
      action: 'step3',
      label: 'error_popup',
      value: 1
    })
  }

  closePopup = () => {
    ga.event({
      category: 'click',
      action: 'step3_error_popup',
      label: 'retry',
      value: 1
    })
    const { appActions } = this.props
    appActions.updateModal({ show: false })
  };

  render () {
    const { nAttempts, maxAttempts } = this.props
    return (
      <div className='text-center'>
        <div>
          <h5>
            <strong>
              <FormattedMessage id='step3.popup.warning.title' />
            </strong>
          </h5>
        </div>
        <div className='mt-3'>
          <AttemptText>
            <FormattedMessage
              id='step3.popup.warning.attempts'
              values={{ nAttempts, maxAttempts }}
            />
          </AttemptText>
        </div>
        <div className='mt-3'>
          <img src={warningIcon} />
        </div>
        <div className='mt-3'>
          <FormattedMessage id='step3.popup.warning.message' />
        </div>
        <div className='mt-3'>
          <Button data-test-id='btn-close' onClick={this.closePopup}>
            <FormattedMessage id='step3.popup.warning.btn.retry' />
          </Button>
        </div>
      </div>
    )
  }
}

export const mapStateToProps = state => {
  return {}
}

export const mapDispatchToProps = dispatch => {
  return {
    appActions: bindActionCreators(appActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PopupWarning)
