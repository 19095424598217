import React from 'react'
import { FormattedMessage } from 'react-intl'
import * as selfActions from '@/containers/Steps/5/actions'
import * as appActions from '@/containers/App/actions'
import { bindActionCreators } from 'redux'
import * as router from 'react-router-redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Button from '@/components/Button'
import _get from 'lodash/get'
import Checkbox from '@/components/Checkbox'
import ojkLogo from '@/static/images/ojk-logo.svg'
import { TermsAndConditions } from '../styledComponents'
import * as ga from '@/utils/gtag'

class ContractVN extends React.Component {
  state = {
    agreeTC: false
  };
  handleToggleAcceptTermsAndConditions = newValue => {
    this.setState({ agreeTC: newValue })
    ga.event({
      category: 'click',
      action: 'step5_check_box',
      label: newValue ? 'check' : 'uncheck',
      value: 1
    })
  };

  handleAgreeContract = () => {
    const { selfActions } = this.props
    selfActions.acceptContract()
  };

  render () {
    const { agreeTC } = this.state
    const contractHtml = _get(
      this.props,
      'resultGetContract.data.contract_html'
    )
    return (
      <React.Fragment>
        <div className='mt-2'>
          <TermsAndConditions className='scrollable'>
            <div dangerouslySetInnerHTML={{ __html: contractHtml }} />
          </TermsAndConditions>
        </div>

        <div className='mt-2'>
          <Checkbox
            data-test-id='cb-agreeTC'
            checked={agreeTC}
            label={<FormattedMessage id='step5.label.agreeT&C' />}
            onChange={this.handleToggleAcceptTermsAndConditions}
          />
        </div>

        <div className='mt-4'>
          <Button
            disabled={!agreeTC}
            data-test-id='btn-proceed'
            onClick={this.handleAgreeContract}
          >
            <FormattedMessage id='step5.btn.proceed' />
          </Button>
        </div>
        <div className='mt-4'>
          <img src={ojkLogo} />
        </div>
      </React.Fragment>
    )
  }
}

export const mapStateToProps = state => {
  return {
    resultGetContract: state.step5.resultGetContract
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    selfActions: bindActionCreators(selfActions, dispatch),
    appActions: bindActionCreators(appActions, dispatch),
    router: bindActionCreators(router, dispatch)
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ContractVN)
)
