import React from 'react'
import request from '@/utils/request'

const API_URL = 'https://api.bpragm.com/api/v1'

class SignaturePage extends React.Component {
  state = {
    intervalId: null,
    step: 0,
    curTime: null
  }

  componentDidMount () {
    const { onFailedGetSignature } = this.props
    this.setState({ curTime: this.props.maxTime })
    this.intervalId = setInterval(() => {
      const newTime = this.state.curTime - 1
      console.log('new time ', newTime)
      this.setState({ curTime: newTime })
      if (newTime <= 0) {
        clearInterval(this.intervalId)
        onFailedGetSignature()
      }
      this.getStatusSignature()
    }, 5000)
    console.log('page loading signature didmount')
  }

  componentWillUnmount () {
    console.log('wiill unmount', this.intervalId)
    clearInterval(this.intervalId)
  }

  getStatusSignature () {
    let timestamp = window.performance.now()
    const { applicationRequestId } = this.props
    const { onFinishedSignature } = this.props
    const { onFailedGetSignature } = this.props
    request(
      `${API_URL}/application_requests/${applicationRequestId}/status_signature?timestamp=${timestamp}`,
      { method: 'POST' }
    ).then((responseData) => {
      console.log('response data', responseData)
      const signatureStatus = responseData.data.signature_status
      const resultCode = responseData.data.result_code
      console.log('signature data', signatureStatus)
      if (resultCode == 0) {
        onFinishedSignature()
        clearInterval(this.intervalId)
      } else if (resultCode !== '08') {
        onFailedGetSignature()
        clearInterval(this.intervalId)
      }
    }).catch((e) => {
      console.log('error signature ', e)
    })
  }

  render () {
    return (<div>
      <p>Page Loading signature</p>
    </div>)
  }
}

export default SignaturePage
