import React from 'react'
import { Wrapper } from './styledComponents'

class FullScreenPopup extends React.Component {
  render () {
    const { children, noScroll, noPadding } = this.props
    return <Wrapper className={`${!noScroll && 'scrollable'} ${noPadding &&
    'p-0'}`}>{children}</Wrapper>
  }
}

export default FullScreenPopup
