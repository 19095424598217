import React from 'react'
import { Collapse } from 'reactstrap'
import arrowRight from './arrow-right.svg'
import arrowBottom from './arrow-bottom.svg'
import { Heading } from './styledComponents'

const Accordion = props => {
  const { items, activeIndex, onClickHeading } = props
  return (
    <div>
      {items.map((item, index) => {
        const { container, heading } = item
        const isOpen = index === activeIndex
        return (
          <div key={index} className='mb-2'>
            <Heading onClick={() => onClickHeading(isOpen ? null : index)}>
              <div>{heading}</div>
              <div>
                <img src={isOpen ? arrowBottom : arrowRight} alt='' />
              </div>
            </Heading>
            <Collapse isOpen={isOpen}>{container}</Collapse>
          </div>
        )
      })}
    </div>
  )
}

export default Accordion
