import * as ga from '@/utils/gtag'
import Cookies from 'js-cookie'
import * as appConstants from '@/containers/App/constants'
import _get from 'lodash/get'

const pathMatch = require('path-match')({
  sensitive: false,
  strict: false,
  end: true
})

export const disablePullToRefresh = () => {
  const isChrome = window.chrome || navigator.userAgent.match('CriOS')
  const isTouch = 'ontouchstart' in document.documentElement

  if (!isChrome || !isTouch) {
    return
  }

  let supportsOverscroll = false
  let supportsPassive = false
  let lastTouchY = 0
  let maybePrevent = false

  try {
    if (CSS.supports('overscroll-behavior-y', 'contain')) {
      supportsOverscroll = true
    }
  } catch (e) {}

  if (supportsOverscroll) {
    return (document.body.style.overscrollBehaviorY = 'contain')
  } else {
    const head = document.head || document.body
    const style = document.createElement('style')
    const css = `
      ::-webkit-scrollbar {
        width: 5px;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.2);
      }
      body {
        -webkit-overflow-scrolling: auto!important;
      }
    `

    style.type = 'text/css'

    if (style.styleSheet) {
      style.styleSheet.cssText = css
    } else {
      style.appendChild(document.createTextNode(css))
    }

    head.appendChild(style)
  }

  try {
    addEventListener('test', null, {
      get passive () {
        supportsPassive = true
      }
    })
  } catch (e) {}

  const setTouchStartPoint = event => {
    lastTouchY = event.touches[0].clientY
  }

  const isScrollingUp = event => {
    const touchY = event.touches[0].clientY
    const touchYDelta = touchY - lastTouchY

    lastTouchY = touchY

    return touchYDelta > 0
  }

  const touchstartHandler = event => {
    if (event.touches.length !== 1) return
    setTouchStartPoint(event)
    maybePrevent = window.pageYOffset === 0
  }

  const touchmoveHandler = event => {
    if (maybePrevent) {
      maybePrevent = false
      if (isScrollingUp(event)) {
        return event.preventDefault()
      }
    }
  }

  document.addEventListener(
    'touchstart',
    touchstartHandler,
    supportsPassive ? { passive: true } : false
  )

  document.addEventListener(
    'touchmove',
    touchmoveHandler,
    supportsPassive ? { passive: false } : false
  )
}

export const disableBackButton = cb => {
  if (typeof window === 'undefined') {
    throw new Error('window is undefined')
  }

  var _hash = '!'
  var noBackPlease = function () {
    window.location.href += '#'

    // making sure we have the fruit available for juice (^__^)
    window.setTimeout(function () {
      window.location.href += '!'
    }, 50)
  }

  window.onhashchange = function () {
    if (typeof cb === 'function') {
      cb()
    }
    if (window.location.hash !== _hash) {
      window.location.hash = _hash
    }
  }

  window.onload = function () {
    noBackPlease()

    // disables backspace on page except on input fields and textarea..
    document.body.onkeydown = function (e) {
      var elm = e.target.nodeName.toLowerCase()
      if (e.which === 8 && (elm !== 'input' && elm !== 'textarea')) {
        e.preventDefault()
      }
      // stopping event bubbling up the DOM tree..
      e.stopPropagation()
    }
  }
}

export const attachOnUnloadEvent = () => {
  window.onunload = () => {
    ga.event({
      action: 'refresh_page',
      category: 'occurrence',
      label: window.location.href,
      value: 1
    })
  }
}

export const isValidDate = d => {
  if (Object.prototype.toString.call(d) === '[object Date]') {
    // it is a date
    if (isNaN(d.getTime())) {
      // d.valueOf() could also work
      // date is not valid
      return false
    } else {
      // date is valid
      return true
    }
  } else {
    // not a date
    return false
  }
}

export const toIndonesiaCurrency = number => {
  if (number !== null) {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR'
    }).format(number)
  } else {
    return 0
  }
}

export const redirectToLastLocationIfNeeded = (location, router) => {
  const { pathname, search } = location

  const matchedRoute = appConstants.routes.find(route => {
    return pathMatch(route.path)(pathname)
  })

  const shouldRedirectToLastLocation =
    matchedRoute && _get(matchedRoute, 'routeProps.redirectToLastLocation')

  if (
    !shouldRedirectToLastLocation ||
    search.includes(appConstants.QUERY_STRING_FOR_TEST_MODE)
  ) {
    return
  }

  let lastLocation = {}
  const lastLocationString = Cookies.get('last-location')
  try {
    lastLocation = JSON.parse(lastLocationString)
  } catch (e) {}
  const lastPathname = lastLocation.pathname
  if (lastPathname && pathname !== lastPathname) {
    router.push({
      ...location,
      pathname: lastPathname
    })
  }
}

export const formatToDDMMYYYY = dobObj => {
  let dd = dobObj.getDate()
  let mm = dobObj.getMonth() + 1
  let yyyy = dobObj.getFullYear()
  if (dd < 10) {
    dd = '0' + dd
  }
  if (mm < 10) {
    mm = '0' + mm
  }
  return `${dd}-${mm}-${yyyy}`
}
