import * as selfConstants from './constants'
import initialState from './initialState'
import _get from 'lodash/get'
import _isUndefined from 'lodash/isUndefined'
import * as appConstants from '@/containers/App/constants'

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case selfConstants.GET_REPAYMENTS: {
      return {
        ...state,
        resultGetRepayments: initialState.resultGetRepayments,
        errorGetRepayments: initialState.errorGetRepayments
      }
    }
    case selfConstants.GET_REPAYMENTS_SUCCESS: {
      const resultGetRepayments = action.payload

      const paidInstallmentCount = _get(
        resultGetRepayments,
        'data.loan_details.0.loan.paid_installment_count'
      )
      const loanTermMonth = _get(
        resultGetRepayments,
        'data.loan_details.0.loan.loan_term_month'
      )
      resultGetRepayments.data.loan_details[0].loan.fullInstallmentsRepaid = _isUndefined(
        paidInstallmentCount
      )
        ? appConstants.NO_VALUE_SYMBOL
        : `${paidInstallmentCount}/${loanTermMonth}`
      const amountDue = _get(
        resultGetRepayments,
        'data.loan_details.0.loan.amount_due'
      )
      const minAmountDue = _get(
        resultGetRepayments,
        'data.loan_details.0.loan.min_amount_due'
      )
      const isAbleToChooseAmount = amountDue !== minAmountDue

      return {
        ...state,
        channels: state.channels.map(channel => {
          const repaymentCodeObj =
            resultGetRepayments.data.repayment_code.find(
              i => i.payment_mode === channel.paymentMode
            ) || {}
          return {
            ...channel,
            code: repaymentCodeObj.code
          }
        }),
        status: resultGetRepayments.data.loan_details[0].loan.page.toLowerCase(),
        resultGetRepayments,
        curAmountToPay: amountDue,
        isAbleToChooseAmount
      }
    }
    case selfConstants.GET_REPAYMENTS_FAIL:
      return {
        ...state,
        errorGetRepayments: action.payload
      }
    case selfConstants.UPDATE_CHANNEL:
      return {
        ...state,
        selectedChannel:
          state.channels.find(i => i.paymentMode === action.payload) || {}
      }
    case selfConstants.REDIRECT_TO_DEFAULT_PAGE:
      return {
        ...state,
        status: selfConstants.STATUS.INSTALLMENTDUE
      }
    case selfConstants.UPDATE_CURRENT_AMOUNT_TO_PAY: {
      const curAmountToPay = action.payload
      return {
        ...state,
        curAmountToPay
      }
    }
    default:
      return state
  }
}
export default reducer
