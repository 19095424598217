import React from 'react'
import { connect } from 'react-redux'
import iconApology from './apology.svg'
import { FormattedMessage } from 'react-intl'
import { ApologyMessage, ApologySubtitle } from './styledComponents'
import Layout from '@/components/Layout'

class ApologyPage extends React.Component {
  render () {
    return (
      <Layout noSeparateLine {...this.props}>
        <div className="mb-4">
          <img src={iconApology} />
        </div>
        <div className="title mt-2 font-weight-bold">
          <FormattedMessage id="apology.title" />
        </div>
        <ApologySubtitle>
          <FormattedMessage id="apology.subtitle" />
        </ApologySubtitle>
        <ApologyMessage className="mt-4">
          <FormattedMessage id="apology.message" />
        </ApologyMessage>
      </Layout>
    )
  }
}

export const mapStateToProps = state => {
  return {}
}
export const mapDispatchToProps = dispatch => {
  return {}
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApologyPage)
