window.cdcm = {
  country_id: '1', // Indonesia
  partner_id: '1_2', // LinkAja
  connectivity:
    (
      window.navigator.connection ||
      window.navigator.mozConnection ||
      window.navigator.webkitConnection ||
      {}
    ).effectiveType || '',
  website_version: '1.2.0',
  user_language:
    window.navigator.language || window.navigator.userLanguage || '',
  gender: '',
  age_bracket: '',
  user_login: 'False',
  product_id: '',
  user_return_type: '',
  credit_line: 0,
  loan_amount: 0,
  loan_success: 0
}
// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = ({ title, location, path }) => {
  window.gtag('config', GA_TRACKING_ID, {
    page_title: title,
    page_location: location,
    page_path: path,
    site_speed_sample_rate: 100,
    // linker: { accept_incoming: true },
    custom_map: {
      dimension1: 'country_id',
      dimension2: 'partner_id',
      dimension3: 'connectivity',
      dimension4: 'website_version',
      dimension5: 'user_language',
      // dimension6: 'platform_id',
      dimension7: 'gender',
      dimension8: 'age_bracket',
      dimension9: 'user_login',
      dimension10: 'product_id',
      dimension12: 'user_return_type',
      metric1: 'credit_line',
      metric2: 'loan_amount',
      metric3: 'loan_success'
    },
    ...window.cdcm
  })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
    ...window.cdcm
  })
}

export const time = ({ name, value, category, label }) => {
  window.gtag('event', 'timing_complete', {
    name,
    value,
    event_category: category,
    event_label: label,
    ...window.cdcm
  })
}

export const ecommerce = ({ name, payload }) => {
  window.gtag('event', name, payload)
}
