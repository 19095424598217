import React from 'react'
import { connect } from 'react-redux'
import * as selfActions from './actions'
import { maxOtpNumbers, PHASE } from './constants'
import * as appActions from '@/containers/App/actions'
import { bindActionCreators } from 'redux'
import PhaseEnterPhoneNumber from './components/PhaseEnterPhoneNumber'
import PhaseVerifyPhoneNumber from './components/PhaseVerifyPhoneNumber'
import _get from 'lodash/get'

const defaultSuggestedPhoneNumbers = ['11111111', '22222222', '33333333']

let start = 0
let end = 0

export class Step1 extends React.Component {
  handlePhoneInputChange = newPhoneNumber => {
    const { selfActions } = this.props
    selfActions.updatePhoneNumber(newPhoneNumber)
  }
  handleSubmitPhaseEnterPhoneNumber = () => {
    const { selfActions } = this.props
    // selfActions.sendOTP()
  }

  handleSubmitPhaseVerifyPhoneNumber = newOTP => {
    const { selfActions, otp } = this.props
    selfActions.verifyOTP(newOTP || otp)

    end = window.performance.now()
  }
  handleOnClickEditNumber = () => {
    const { selfActions } = this.props
    selfActions.updatePhase(PHASE.ENTER_PHONE_NUMBER)
  }
  handleOTPChanged = (newOTP, agreeTc) => {
    const { selfActions } = this.props
    selfActions.updateOTP(newOTP)
    const numberOfDigitsEnteredSoFar = newOTP.filter(n => !!n).length
    if (numberOfDigitsEnteredSoFar === 1) {
    } else if (numberOfDigitsEnteredSoFar === maxOtpNumbers) {
      this.handleSubmitPhaseVerifyPhoneNumber(newOTP)

      if (document.activeElement) {
        document.activeElement.blur()
      }
    }
  }

  componentDidMount () {
  }

  render () {
    const { phoneNumber, currentPhase, otp } = this.props
    const suggestionList = _get(
      this.props,
      'resultGetPhoneNumbers.phone_numbers',
      defaultSuggestedPhoneNumbers
    )

    let content = null
    switch (currentPhase) {
      case PHASE.VERIFY_PHONE_NUMBER:
        content = (
          <PhaseVerifyPhoneNumber
            phoneNumber={phoneNumber}
            onClickEditNumber={this.handleOnClickEditNumber}
            onSubmitClick={this.handleSubmitPhaseVerifyPhoneNumber}
            otp={otp}
            onOTPChanged={this.handleOTPChanged}
          />
        )
        break
      default:
        content = (
          <PhaseEnterPhoneNumber
            phoneNumber={phoneNumber}
            suggestionList={suggestionList}
            onPhoneInputChange={this.handlePhoneInputChange}
            onSubmitClick={this.handleSubmitPhaseEnterPhoneNumber}
          />
        )
    }
    return content
  }
}

export const mapStateToProps = state => {
  return {
    phoneNumber: state.step1.phoneNumber,
    currentPhase: state.step1.currentPhase,
    otp: state.step1.otp,
    resultGetPhoneNumbers: state.step1.resultGetPhoneNumbers
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    selfActions: bindActionCreators(selfActions, dispatch),
    appActions: bindActionCreators(appActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Step1)
