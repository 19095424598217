import React from 'react'
import { connect } from 'react-redux'
import logoOJK from '@/static/images/ojk-logo.svg'
import logoAFPI from '@/static/images/afpi-logo.png'
import { FormattedMessage } from 'react-intl'
import Button from '@/components/Button'
import { Link } from 'react-router-dom'
import * as router from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { WelcomeTitle } from './styledComponents'
import appResumeIcon from './App-Resume-Icon.svg'
import * as appActions from '@/containers/App/actions'
import * as step1Actions from '@/containers/Steps/1/actions'
import Layout from '@/components/Layout'
import * as helpers from '@/utils/helpers'

export class WelcomeBack extends React.Component {
  componentDidMount () {
    const { appActions } = this.props
    window.cdcm.user_return_type = '2'
    appActions.updateIsResumable(true)
    helpers.getLatLng()
  }

  handleClickTC = () => {
  }
  handleOnApproved = () => {
    const {
      router,
      applicationRequestId,
      location: { search }
    } = this.props
    // const parsedQs = qs.parse(search)
    router.push(`/step/4`)
  }
  handleResumeApp = () => {
    const { router, step1Actions } = this.props
    step1Actions.initialize()
    router.push('/step/3')
  }

  render () {
    return (
      <Layout noSeparateLine {...this.props}>
        <WelcomeTitle className="text-center title">
          <FormattedMessage id="thanks.title.accept" />
        </WelcomeTitle>

        <div className="mt-4">
          <img src={appResumeIcon} />
        </div>

        <div className="mt-4 text-center">
          <FormattedMessage id="thanks.subtitle.accept" />
        </div>

        <br />
        <div className="mt-4 text-center">
          {/*<Button*/}
          {/*  data-test-id='btn-proceed'*/}
          {/*  onClick={this.handleOnApproved}*/}
          {/*>*/}
          {/*  <FormattedMessage id='step3.btn.proceed' />*/}
          {/*</Button>*/}
        </div>

        <div className="mt-4 text-center">
          <span>
            <FormattedMessage
              id="thanks.tc"
              values={{
                't&c': (
                  <Link
                    to={{
                      pathname: '/terms-and-conditions',
                      search: '?from=thanks-accept'
                    }}
                    onClick={this.handleClickTC}
                  >
                    <FormattedMessage id="intro.tc2" />
                  </Link>
                )
              }}
            />
          </span>
        </div>

        <div
          className="mt-4 w-100 d-flex justify-content-around align-items-center">
          <div>
            <img src={logoOJK} style={{ height: '40px' }} alt="OJK logo" />
          </div>
          <div>
            <img src={logoAFPI} style={{ height: '40px' }} alt="AFPI logo" />
          </div>
        </div>
      </Layout>
    )
  }
}

export const mapStateToProps = state => {
  return {}
}

export const mapDispatchToProps = dispatch => {
  return {
    appActions: bindActionCreators(appActions, dispatch),
    step1Actions: bindActionCreators(step1Actions, dispatch),
    router: bindActionCreators(router, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WelcomeBack)
