import React from 'react'
import NumberFormat from 'react-number-format'

const AboutPage = () => {
  return (
    <div>
      <h2>About</h2>
      <p>Oct 2018...</p>
      <NumberFormat value={500000000.0} displayType={'text'} thousandSeparator={"."}
        decimalSeparator={","} prefix={'IDR '} />
    </div>
  )
}

export default AboutPage
