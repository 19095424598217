import * as selfConstants from './constants'
import initialState from './initialState'

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case selfConstants.SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload
      }
    case selfConstants.SET_FILES:
      return {
        ...state,
        files: action.payload
      }

    case selfConstants.VERIFY_IMAGES:
      return {
        ...state,
        title: 'step2.title',
        disabled: true,
        subtitle: selfConstants.SUBTITLE_WHILE_VERIFYING,
        buttonText: selfConstants.BUTTONTEXT_WHILE_VERIFYING,
        status: selfConstants.STATUS.VERIFYING,
        errorMessage: ''
      }
    case selfConstants.GET_KYC_SIGNATURE:
      const applicationRequest = action.payload.data.application_request
      const dataResponse = action.payload.data
      console.log('response on payload', dataResponse)
      console.log('application request', applicationRequest)
      if (dataResponse.result_code == 0) {
        return {
          ...state,
          title: 'step2.verification_process',
          disabled: true,
          subtitle: 'step2.subtitle_verification',
          applicationRequest: applicationRequest,
          status: selfConstants.STATUS.SHOWING_PROGRESS_KYC_SIGNATURE,
          errorMessage: ''
        }
      } else if (dataResponse.result_code == 1013) {
        return {
          ...state,
          pollingCount: 1,
          title: 'step2.kycfailed',
          subtitle: 'step2.subtitle_notitle',
          status: selfConstants.STATUS.FAILURE_WITH_RECHECK
        }
      } else if (dataResponse.result_code == 1006) {
        const iframeUrl = dataResponse.src_iframe
        return {
          ...state,
          title: 'step2.title',
          uploadretries: 0,
          pollingCount: 1,
          iframeSrc: iframeUrl,
          status: selfConstants.STATUS.SHOWING_FRAME_PERURI,
          errorMessage: ''
        }
      } else {
        return {
          ...state,
          pollingCount: 1,
          title: 'step2.notitle',
          subtitle: 'step2.subtitle_notitle',
          status: selfConstants.STATUS.FAILURE,
          uploadretries: state.uploadretries + 1
        }
      }

    case selfConstants.GET_STATUS_SUCCESS:
      return {
        ...state,
        title: 'step2.title',
        uploadretries: 0,
        pollingCount: 1,
        status: selfConstants.STATUS.SUCCESS,
        errorMessage: ''
      }

    case selfConstants.GET_SHOW_IFRAME:
      const data = action.payload.data
      console.log('data : ', data)
      const iframeUrl = data.src_iframe
      return {
        ...state,
        title: 'step2.title',
        uploadretries: 0,
        pollingCount: 1,
        iframeSrc: iframeUrl,
        status: selfConstants.STATUS.SHOWING_FRAME_PERURI,
        errorMessage: ''
      }
    case selfConstants.RENDER_SIGNATURE_PAGE:
      return {
        ...state,
        title: 'step2.verification_process',
        disabled: true,
        subtitle: 'step2.subtitle_verification',
        applicationRequest: action.payload,
        status: selfConstants.STATUS.SHOWING_PROGRESS_KYC_SIGNATURE,
        errorMessage: ''
      }
    case selfConstants.GET_SHOW_IFRAME_SPECIMENT:
      console.log('data : ', action.payload.data)
      return {
        ...state,
        title: 'step2.title',
        uploadretries: 0,
        pollingCount: 1,
        iframeSrc: action.payload.data.src_iframe,
        status: selfConstants.STATUS.SHOWING_FRAME_PERURI_SPECIMENT,
        errorMessage: ''
      }
    case selfConstants.GET_STATUS_STAY:
      return {
        ...state,
        title: 'step2.title',
        subtitle: 'step2.subtitle',
        status: selfConstants.STATUS.SHOW_PRIVY_TERMS,
        buttonText: 'step2.btn.verifyKYC',
        errorMessage: '',
        pollingCount: 1
      }
    case selfConstants.GET_KYC_SIGNATURE_SUCCESS:
      return {
        ...state,
        title: 'step2.verification_process_success',
        disabled: true,
        subtitle: 'step2.subtitle_verification_success',
        applicationRequest: applicationRequest,
        status: selfConstants.STATUS.SUCCESS_GET_SIGNATURE,
        errorMessage: ''
      }
    case selfConstants.GET_STATUS_FAIL:
      return {
        ...state,
        pollingCount: 1,
        title: 'step2.title',
        subtitle: 'step2.subtitlewhileverifying',
        status: selfConstants.STATUS.FAILURE,
        uploadretries: state.uploadretries + 1
      }
    case selfConstants.GET_STATUS_MAX_RETRIES_REACHED:
      return {
        ...state,
        pollingCount: 1,
        title: 'step2.title',
        subtitle: 'step2.subtitlewhileverifying',
        status: selfConstants.STATUS.MAX_RETRIES_REACHED,
        uploadretries: 1
      }
    case selfConstants.STATUS_PENDING:
      return {
        ...state,
        pollingCount: state.pollingCount + 1
      }

    // Set Upload Count
    case selfConstants.SET_UPLOAD_COUNT:
      return {
        ...state,
        uploadretries: action.payload
      }
    case selfConstants.CHANGE_STATUS: {
      return {
        ...state,
        status: action.payload
      }
    }
    case selfConstants.CHANGE_CHOSEN_TYPE: {
      return {
        ...state,
        chosenType: action.payload
      }
    }
    case selfConstants.SET_FILE_DETAILS: {
      const { key, value } = action.payload

      const newFiles = {
        ...state.files,
        [key]: {
          ...state.files[key],
          ...value
        }
      }

      let shouldEnableSubmitButton = true
      Object.keys(newFiles).forEach(key => {
        if (!newFiles[key].dataUrl) {
          shouldEnableSubmitButton = false
        }
      })

      const enableSubmitButtonUpdates = shouldEnableSubmitButton
        ? {
          disabled: false,
          title: selfConstants.TITLE_AFTER_UPLOADING,
          subtitle: selfConstants.SUBTITLE_AFTER_UPLOADING,
          errorMessage: ''
        }
        : {}
      return {
        ...state,
        files: newFiles,
        ...enableSubmitButtonUpdates
      }
    }

    case selfConstants.RENDER_ASLIRI_PAGE: {
      return {
        ...state,
        title: selfConstants.TITLE_AFTER_UPLOAD_KTP,
        disabled: true,
        subtitle: selfConstants.SUBTITLE_AFTER_UPLOAD_KTP,
        buttonText: selfConstants.BUTTON_AFTER_UPLOAD_KTP,
        status: selfConstants.STATUS.SHOWING_ASLIRI,
        errorMessage: ''
      }
    }

    case selfConstants.RENDER_ASLIRI_CAMERA_PAGE: {
      return {
        ...state,
        status: selfConstants.STATUS.SHOWING_ASLIRI_CAMERA,
        account: action.payload.account
      }
    }
    case selfConstants.SET_ASLIRI_FILE: {
      const ekycData = action.payload.ekycData
      const isEkycDataExist = ekycData.length > 0

      return {
        ...state,
        ekycData: ekycData,
        status: (isEkycDataExist) ?
          selfConstants.STATUS.SHOWING_ASLIRI : '',
        disabled: !isEkycDataExist,
        errorMessage: !isEkycDataExist ? '' : undefined
      }
    }
    case selfConstants.VERIFY_ASLIRI:
      return {
        ...state,
        title: 'step2.asliri_title',
        disabled: true,
        subtitle: 'step2.asliri-subtitlewhileverifying',
        buttonText: selfConstants.BUTTONTEXT_WHILE_VERIFYING,
        status: selfConstants.STATUS.VERIFYING,
        errorMessage: ''
      }

    // Default
    default:
      return state
  }
}
export default reducer
