import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import * as selfActions from './actions'
import { bindActionCreators } from 'redux'
import { UploadFailureText } from './styledComponents'
import * as selfConstants from './constants'
import failureSmiley from './failure-smiley.svg'
import Button from '@/components/Button'
import * as appConstants from '@/containers/App/constants'
import * as ga from '@/utils/gtag'
import * as appActions from '@/containers/App/actions'
import * as helpers from '@/utils/helpers'
import KYCForm from './KYCForm'

class KYCInfoPage extends React.Component {
  componentDidMount () {
    const { selfActions } = this.props
    selfActions.getKYCForm()
    console.log(selfActions)
    helpers.getLatLng()
  }

  goToTCash = () => {
    window.location.href = appConstants.TCASH_APP_LINK
  }

  renderBody (status) {
    switch (status) {
      case selfConstants.STATUS.FAILURE:
        return (
          <React.Fragment>
            <div className="mt-4">
              <img src={failureSmiley} />
            </div>
            <div className="subtitle text-center mt-2 font-weight-bold">
              <UploadFailureText>
                <FormattedMessage id="step2.subtitlefailureapology" />
              </UploadFailureText>
              <UploadFailureText>
                <FormattedMessage id="step3.subtitlefailurereason" />
              </UploadFailureText>
            </div>
            <div className="mt-4">
              <span className="subtitle">
                <FormattedMessage id="step3.label.help" />
              </span>
            </div>
            <div className="mt-4">
              <Button disabled={false} onClick={this.goToTCash}>
                <FormattedMessage id="btn.goToTcash" />
              </Button>
            </div>
          </React.Fragment>
        )
      case selfConstants.STATUS.LOANREJECTED:
        return (
          <React.Fragment>
            <div className="title mt-2 font-weight-bold text-center">
              <FormattedMessage id="step2.error.applicationrejectedtitle" />
            </div>
            <div className="subtitle mt-2 text-center">
              <FormattedMessage id="step2.error.applicationrejectedmessage" />
            </div>
            <div className="mt-5">
              <img src={failureSmiley} />
            </div>
            <div className="mt-3">
              <Button disabled={false} onClick={this.goToTCash}>
                <FormattedMessage id="btn.goToTcash" />
              </Button>
            </div>
          </React.Fragment>
        )
      default:
        // TODO: Prefilled value of KTP.
        const {
          schema,
          uiSchema,
          formData,
          selfActions,
          isTouched
        } = this.props
        return (
          <React.Fragment>
            <KYCForm
              schema={schema}
              uiSchema={uiSchema}
              formData={formData}
              onSubmit={this.submit}
              postKYCForm={selfActions.postKYCForm}
              setKYCFormData={selfActions.setKYCFormData}
              getCities={selfActions.getCities}
              getSubdistricts={selfActions.getSubdistricts}
              getVillages={selfActions.getVillages}
              isTouched={isTouched}
              setKYCFormIsTouched={selfActions.setKYCFormIsTouched}
              phoneNumberFromPrevStep={this.props.phoneNumberFromPrevStep}
              errorMessages={this.props.errorMessages}
              fieldNamesList={this.props.fieldNamesList}
              gaFieldTags={this.props.gaFieldTags}
            />
          </React.Fragment>
        )
    }
  }

  render () {
    const { title, subtitle, status } = this.props
    return (
      <div className="d-flex flex-column align-items-center">
        <div className="title">
          <strong><FormattedMessage id={title} /></strong>
        </div>
        <div className="subtitle text-center mt-2">
          <FormattedMessage id={subtitle} />
        </div>
        {this.renderBody(status)}
      </div>
    )
  }
}

export const mapStateToProps = state => {
  return {
    schema: state.step3.schema,
    uiSchema: state.step3.uiSchema,
    formData: state.step3.formData,
    status: state.step3.status,
    errorMessages: state.step3.errorMessages,
    fieldNamesList: state.step3.fieldNamesList,
    gaFieldTags: state.step3.gaFieldTags,
    title: state.step3.title,
    subtitle: state.step3.subtitle,
    isTouched: state.step3.isTouched,
    phoneNumberFromPrevStep: state.step1.phoneNumber
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    selfActions: bindActionCreators(selfActions, dispatch),
    appActions: bindActionCreators(appActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KYCInfoPage)
