import * as selfConstants from "./constants";
import * as appConstants from "@/containers/App/constants";
import * as ga from "@/utils/gtag";

const actionTypeToLoaderStateMap = {
  [selfConstants.GET_KYC_FORM]: true,
  [selfConstants.GET_KYC_FORM_SUCCESS]: false,
  [selfConstants.GET_KYC_FORM_FAIL]: false,
  [selfConstants.GET_CITIES]: true,
  [selfConstants.GET_CITIES_SUCCESS]: false,
  [selfConstants.GET_CITIES_FAIL]: false,
  [selfConstants.GET_SUBDISTRICTS]: true,
  [selfConstants.GET_SUBDISTRICTS_SUCCESS]: false,
  [selfConstants.GET_SUBDISTRICTS_FAIL]: false,
  [selfConstants.GET_VILLAGES]: true,
  [selfConstants.GET_VILLAGES_SUCCESS]: false,
  [selfConstants.GET_VILLAGES_FAIL]: false,
  [selfConstants.SUBMIT_KYC_FORM]: true,
  [selfConstants.SUBMIT_KYC_FORM_SUCCESS]: false,
  [selfConstants.SUBMIT_KYC_FORM_FAIL]: false,
  [selfConstants.SUBMIT_KYC_FORM_API_FAIL]: false
};

const actionTypeToEventTrackingMap = {
  [selfConstants.GET_CITIES_SUCCESS]: action => {
    ga.time({
      category: "Application Form",
      name: "fetch_time",
      value: action.meta.fetchTime,
      label: "get_city_api"
    });
    ga.event({
      action: "step3",
      category: "occurrence",
      label: "get_city_success",
      value: 1
    });
  },
  [selfConstants.GET_CITIES_FAIL]: action => {
    ga.time({
      category: "Application Form",
      name: "fetch_time",
      value: action.meta.fetchTime,
      label: "get_city_api"
    });
    ga.event({
      action: "step3",
      category: "occurrence",
      label: "get_city_failure",
      value: 1
    });
  },
  [selfConstants.GET_SUBDISTRICTS_SUCCESS]: action => {
    ga.time({
      category: "Application Form",
      name: "fetch_time",
      value: action.meta.fetchTime,
      label: "get_subdistrict_api"
    });
    ga.event({
      action: "step3",
      category: "occurrence",
      label: "get_subdistrict_success",
      value: 1
    });
  },
  [selfConstants.GET_SUBDISTRICTS_FAIL]: action => {
    ga.time({
      category: "Application Form",
      name: "fetch_time",
      value: action.meta.fetchTime,
      label: "get_subdistrict_api"
    });
    ga.event({
      action: "step3",
      category: "occurrence",
      label: "get_subdistrict_failure",
      value: 1
    });
  },
  [selfConstants.GET_VILLAGES_SUCCESS]: action => {
    ga.time({
      category: "Application Form",
      name: "fetch_time",
      value: action.meta.fetchTime,
      label: "get_village_api"
    });
    ga.event({
      action: "step3",
      category: "occurrence",
      label: "get_village_success",
      value: 1
    });
  },
  [selfConstants.GET_VILLAGES_FAIL]: action => {
    ga.time({
      category: "Application Form",
      name: "fetch_time",
      value: action.meta.fetchTime,
      label: "get_village_api"
    });
    ga.event({
      action: "step3",
      category: "occurrence",
      label: "get_village_failure",
      value: 1
    });
  },
  [selfConstants.GET_KYC_FORM_SUCCESS]: action => {
    ga.time({
      category: "Application Form",
      name: "fetch_time",
      value: action.meta.fetchTime,
      label: "get_kyc_form_api"
    });
    ga.event({
      action: "step3",
      category: "occurrence",
      label: "get_kyc_form_success",
      value: 1
    });
  },
  [selfConstants.GET_KYC_FORM_FAIL]: action => {
    ga.time({
      category: "Application Form",
      name: "fetch_time",
      value: action.meta.fetchTime,
      label: "get_kyc_form_api"
    });
    ga.event({
      action: "step3",
      category: "occurrence",
      label: "get_kyc_form_failure",
      value: 1
    });
  },
  [selfConstants.SUBMIT_KYC_FORM_SUCCESS]: action => {
    ga.time({
      category: 'Application Process',
      name: 'decision_time',
      value: action.meta.fetchTime,
      label: 'decision_engine'
    })

    const valuesToPost = action.meta.valuesToPost;
    const gender = valuesToPost.gender;
    const dateOfBirth = action.meta.dateOfBirth;

    if (gender === 'LAKI-LAKI') {
      window.cdcm.gender = 'Male'
    } else {
      window.cdcm.gender = 'Female'
    }

    const age = selfConstants.getAge(dateOfBirth)
    if (age < 22) {
      window.cdcm.age_bracket = '[1-21]'
    } else if (age < 35) {
      window.cdcm.age_bracket = '[22-34]'
    } else if (age < 51) {
      window.cdcm.age_bracket = '[35-50]'
    } else {
      window.cdcm.age_bracket = '[51+]'
    }

    const gaFields = [
      { label: 'gender', value: valuesToPost.gender },
      { label: 'year_of_birth', value: dateOfBirth.getFullYear() },
      { label: 'place_of_birth', value: valuesToPost.birth_place.toLowerCase() },
      { label: 'zip_code', value: valuesToPost.zipcode },
      { label: 'cur_province', value: valuesToPost.province },
      { label: 'cur_city', value: valuesToPost.city },
      { label: 'cur_subdistrict', value: valuesToPost.sub_district },
      { label: 'cur_village', value: (valuesToPost.village).toLowerCase() },
      { label: 'religion', value: valuesToPost.religion },
      { label: 'education', value: valuesToPost.highest_education },
      { label: 'employment', value: valuesToPost.occupation },
      { label: 'income_range', value: valuesToPost.monthly_income },
      { label: 'emrg_relationship', value: valuesToPost.emergency_contact_relationship },
      { label: 'emrg_title', value: valuesToPost.emergency_contact_title },
      { label: 'loan_purpose', value: valuesToPost.loan_purpose }
    ]

    for (let field in gaFields) {
      ga.event({
        action: 'step3_v1_submit_' + gaFields[field].label,
        category: 'click',
        label: gaFields[field].value,
        value: 1
      })
    }

    ga.event({
      action: 'step4',
      category: 'occurrence',
      label: 'application_success',
      value: 1
    })

  },
  [selfConstants.SUBMIT_KYC_FORM_FAIL]: action => {
    ga.time({
      category: 'Application Process',
      name: 'decision_time',
      value: action.meta.fetchTime,
      label: 'decision_engine'
    })
  },
  [selfConstants.SUBMIT_KYC_FORM_API_FAIL]: action => {
    const {fetchTime, code, message} = action.meta;
    ga.time({
      category: 'Application Process',
      name: 'decision_time',
      value: fetchTime,
      label: 'decision_engine'
    })
    if (code === 'kyc_validation_error') {
      if (typeof message === 'string') {
        if (message.includes(selfConstants.KTP_ERR_MSG_FIRST_4_DIGITS)) {
          ga.event({
            action: 'step3',
            category: 'occurrence',
            label: 'error_ktp_first_4',
            value: 1
          })
        } else if (message.includes(selfConstants.KTP_ERR_MSG_GENDER)) {
          ga.event({
            action: 'step3',
            category: 'occurrence',
            label: 'error_ktp_gender',
            value: 1
          })
        }
      } else {
        message.forEach((msg, index) => {
          if (msg.includes(selfConstants.KTP_ERR_MSG_FIRST_4_DIGITS)) {
            ga.event({
              action: 'step3',
              category: 'occurrence',
              label: 'error_ktp_first_4',
              value: 1
            })
          } else if (msg.includes(selfConstants.KTP_ERR_MSG_GENDER)) {
            ga.event({
              action: 'step3',
              category: 'occurrence',
              label: 'error_ktp_gender',
              value: 1
            })
          }
        })
      }
    }
  },
  [selfConstants.SUBMIT_KYC_FORM_LOAN_REJECTED]: action => {
    window.cdcm.user_return_type = '3'
    ga.event({
      action: 'step4',
      category: 'occurrence',
      label: 'application_rejected',
      value: 1
    })
  },
};

export const step2UpdateLoader = (state, dispatch, action) => {
  const loaderState = actionTypeToLoaderStateMap[action.type];
  if (loaderState !== undefined) {
    dispatch({ type: appConstants.UPDATE_LOADER, payload: loaderState });
  }
};

export const step2EventTracking = (state, dispatch, action) => {
  const eventTracking = actionTypeToEventTrackingMap[action.type];
  if (eventTracking) {
    eventTracking(action);
  }
};
