import React from 'react'
import HorizontalLine from '@/components/HorizontalLine'
import { CloseButton, Title, Wrapper } from './styledComponents'
import closeIcon from '@/static/images/close-icon.svg'

class ModalHeader extends React.Component {
  render () {
    const { children, onClickCloseButton } = this.props
    return (
      <Wrapper>
        <Title className='mr-4'>{children}</Title>
        <CloseButton onClick={onClickCloseButton}>
          <img src={closeIcon} />
        </CloseButton>
        <HorizontalLine />
      </Wrapper>
    )
  }
}

export default ModalHeader
