import React from 'react'

export const ALL_TYPES = {
  linkaja: [
    {
      id: 'kyc',
      placeholderImg: require('./placeholder-kyc.svg'),
      title: 'step2.ktptitle',
      subtitle: 'step2.ktpsubtitle',
      cameraOnPage: <div>KTP Camera ON</div>
    }
  ],
  viettel: [
    {
      id: 'kyc',
      placeholderImg: require('./placeholder-kyc.svg'),
      title: 'step2.ktptitle',
      subtitle: 'step2.ktpsubtitle',
      cameraOnPage: <div>KTP Camera ON</div>
    },
    {
      id: 'kyc_reverse',
      placeholderImg: require('./placeholder-kyc-reverse.svg'),
      title: 'step2.ktptitle',
      subtitle: 'step2.ktpsubtitle',
      cameraOnPage: <div>KTP Camera ON</div>
    },
    {
      id: 'selfie',
      placeholderImg: require('./placeholder-selfie.svg'),
      title: 'step2.selfietitle',
      subtitle: 'step2.selfiesubtitle',
      cameraOnPage: <div>Selfie CAMERA ON</div>
    }
  ]
}

export const STATUS = {
  MAIN_SCREEN: 'MAIN_SCREEN',
  VERIFYING: 'VERIFYING',
  SUCCESS: 'SUCCESS',
  SUCCESS_GET_SIGNATURE: 'SUCCESS_GET_SIGNATURE',
  FAILURE: 'FAILURE',
  MAX_RETRIES_REACHED: 'MAX_RETRIES_REACHED',
  SHOWING_INSTRUCTIONS: 'SHOWING_INSTRUCTIONS',
  SHOWING_ASLIRI: 'SHOWING_ASLIRI',
  SHOWING_ASLIRI_CAMERA: 'SHOWING_ASLIRI_CAMERA',
  SHOWING_FRAME_PERURI: 'SHOWING_FRAME_PERURI',
  SHOWING_FRAME_PERURI_SPECIMENT: 'SHOWING_FRAME_PERURI_SPECIMENT',
  CAMERA_ON: 'CAMERA_ON',
  SHOWING_PROGRESS_KYC_SIGNATURE: 'SHOWING_PROGRESS_KYC_SIGNATURE',
  FAILURE_WITH_RECHECK: 'FAILURE_WITH_RECHECK',
  SHOW_PRIVY_TERMS: 'SHOW_PRIVY_TERMS',
  SHOW_KTP_CAMERA: 'SHOW_KTP_CAMERA'
}

export const SHOW_SUCCESS_PAGE = 'STEP_3/SHOW_SUCCESS_PAGE'
export const SHOW_FAILURE_PAGE = 'STEP_3/SHOW_FAILURE_PAGE'

export const TITLE_AFTER_UPLOADING = 'step2.titleafteruploading'
export const SUBTITLE_AFTER_UPLOADING = 'step2.subtitleafteruploading'
export const TITLE_WHILE_VERIFYING = 'step2.titleafteruploading'
export const SUBTITLE_WHILE_VERIFYING = 'step2.subtitlewhileverifying'
export const BUTTONTEXT_WHILE_VERIFYING = 'step2.btn.verifyingKYC'

export const TITLE_AFTER_UPLOAD_KTP = 'step2.asliri_title'
export const SUBTITLE_AFTER_UPLOAD_KTP = 'step2.asliri_subtitle'
export const BUTTON_AFTER_UPLOAD_KTP = 'step2.btn.asliri_send'

export const VERIFY_IMAGES = 'STEP_3/VERIFY_IMAGES'
export const VERIFY_ASLIRI = 'STEP_3/VERIFY_ASLIRI'

export const GET_KYC_UPLOAD_URL_FAIL_MESSAGE = 'step2.error.generateImageUrl'
export const KYC_UPLOAD_FAIL_MESSAGE = 'step2.error.uploadImage'

export const GET_STATUS = 'STEP_3/GET_STATUS'
export const GET_STATUS_SUCCESS = 'STEP_3/GET_STATUS_SUCCESS'
export const GET_STATUS_STAY = 'STEP_3/GET_STATUS_STAY'
export const GET_STATUS_FAIL = 'STEP_3/GET_STATUS_FAIL'
export const GET_STATUS_MAX_RETRIES_REACHED =
  'STEP_3/GET_STATUS_MAX_RETRIES_REACHED'
export const STATUS_PENDING = 'STEP_3/STATUS_PENDING'
export const KYC_UPLOAD_MAX_RETRIES = 10
export const KYC_UPLOAD_MAX_TIMEOUT_COUNT = 12
export const SET_ERROR_MESSAGE = 'STEP_3/SET_ERROR_MESSAGE'
export const SET_UPLOAD_COUNT = 'STEP_3/SET_UPLOAD_COUNT'
export const CHANGE_STATUS = 'STEP_3/CHANGE_STATUS'
export const CHANGE_CHOSEN_TYPE = 'STEP_3/CHANGE_CHOSEN_TYPE'
export const SET_FILE_DETAILS = 'STEP_3/SET_FILE_DETAILS'
export const SET_FILES = 'STEP_3SET_FILES'
export const GET_SHOW_IFRAME = 'STEP_3/GET_SHOW_IFRAME'
export const GET_SHOW_IFRAME_SPECIMENT = 'STEP_3/GET_SHOW_IFRAME_SPECIMENT'
export const GET_KYC_SIGNATURE = 'GET_KYC_SIGNATURE'
export const GET_KYC_SIGNATURE_SUCCESS = 'GET_KYC_SIGNATURE_SUCCESS'
export const RENDER_SIGNATURE_PAGE = 'RENDER_SIGNATURE_PAGE'

export const RENDER_ASLIRI_PAGE = 'STEP_3/RENDER_ASLIRI_PAGE'
export const RENDER_ASLIRI_CAMERA_PAGE = 'STEP_3/RENDER_ASLIRI_CAMERA_PAGE'
export const SET_ASLIRI_FILE = 'STEP_3/SET_ASLIRI_FILE'

export const GET_GESTURE_ERROR = 'step2.error.gesture'
export const GET_ACCOUNT_ERROR = 'step2.error.account'
export const VERIFICATION_ASLIRI_ERROR = 'step2.error.asliri'

