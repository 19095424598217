import styled from 'styled-components'

export const CheckboxWrapper = styled.span`
  display: inline-block;
  * {
    vertical-align: middle;
  }
  [data-icon="true"] {
    border: 1px solid #e3e3e3;
    background: #ffffff;
    display: inline-block;
    height: 18px;
    width: 18px;
    border-radius: 5px;
    position: relative;
    img {
      position: absolute;
      top: 4px;
      left: 4px;
    }
  }
`
