import React from 'react'
import { CodeArea, Subtitle, Title } from './styledComponents'
import { FormattedMessage } from 'react-intl'
import { Tooltip } from 'reactstrap'

class CopiableCode extends React.Component {
  constructor (props) {
    super(props)
    this.codeAreaRef = React.createRef()
    this.state = {
      isTooltipOpen: false
    }
  }

  handleOnClick = () => {
    const { onCodeClick, code } = this.props
    this.setState({ isTooltipOpen: true })
    window.getSelection().selectAllChildren(this.codeAreaRef.current)
    document.execCommand('copy')
    setTimeout(() => {
      this.setState({ isTooltipOpen: false })
    }, 1500)

    if (onCodeClick) {
      onCodeClick(code)
    }
  };

  render () {
    const { code, title, onCodeClick, ...rest } = this.props
    const { isTooltipOpen } = this.state
    return (
      <div {...rest}>
        <div className='d-flex justify-content-between'>
          <Title>{title}</Title>
          <Subtitle>
            <FormattedMessage id='repayments.selectMethod.label.tapToCopy' />
          </Subtitle>
        </div>
        <CodeArea
          className='selectable'
          ref={this.codeAreaRef}
          onClick={this.handleOnClick}
        >
          {code}
        </CodeArea>
        <Tooltip
          placement='top'
          isOpen={isTooltipOpen}
          target={() => this.codeAreaRef.current}
        >
          <FormattedMessage id='copiableCode.tooltip' />
        </Tooltip>
      </div>
    )
  }
}

export default CopiableCode
