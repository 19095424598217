import styled from 'styled-components'

export const LoaderWrapper = styled.div`
  background: black;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0.5;
  z-index: 2000;
  [data-button-close="true"] {
    position: fixed;
    right: 0;
    top: 0;
    padding: 0 10px;
    font-size: 30px;
    color: white;
  }
  img {
    width: 100px;
  }
`
