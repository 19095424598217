import React from 'react'
import { connect } from 'react-redux'
import * as appActions from '@/containers/App/actions'
import Card, { Heading } from '@/components/Card'
import HorizontalLine from '@/components/HorizontalLine'
import { bindActionCreators } from 'redux'
import * as selfActions from './actions'
import * as router from 'react-router-redux'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _isNull from 'lodash/isNull'
import * as ga from '@/utils/gtag'
import * as appConstants from '@/containers/App/constants'
import ErrorPage from './components/ErrorPage'
import AwaitConfirmation from './components/AwaitConfirmation'
import LoanSuccess from './components/LoanSuccess'
import InstallmentSuccess from './components/InstallmentSuccess'
import * as selfConstants from './constants'
import { FormattedMessage } from 'react-intl'
import List from '@/components/List'
import ButtonLink from '@/components/ButtonLink'
import ButtonFixed from '@/components/ButtonFixed'
import Slider from '@/components/Slider'
import _debounce from 'lodash/debounce'
import { generateSliderMarks } from './utils'
import * as appUtils from '@/containers/App/utils'

export class SelectChannel extends React.Component {
  constructor (props) {
    super(props)
    this.debouncedGAUpdateCurrentSliderValue = _debounce(
      (newValue, amountDue, minAmountDue) => {
        ga.event({
          category: 'click',
          action: 'paid_amount_need',
          label: 'adjust_slider',
          value: newValue
        })

        if (newValue === amountDue) {
          ga.event({
            category: 'occurrence',
            action: 'paid_amount_need',
            label: 'slider_to_max',
            value: amountDue
          })
        } else if (newValue === minAmountDue) {
          ga.event({
            category: 'occurrence',
            action: 'paid_amount_need',
            label: 'slider_to_min',
            value: minAmountDue
          })
        }
      },
      2000
    )
  }

  componentDidMount () {
    const {
      selfActions,
      token,
      isAbleToChooseAmount,
      resultGetRepayments
    } = this.props
    selfActions.getRepayments(token)
    const amountDue = _get(
      resultGetRepayments,
      'data.loan_details.0.loan.amount_due'
    )
  }

  handleOnChangeCurAmountToPay = newValue => {
    const { selfActions, resultGetRepayments } = this.props
    selfActions.updateCurrentAmountToPay(newValue)
    const amountDue = _get(
      resultGetRepayments,
      'data.loan_details.0.loan.amount_due'
    )
    const minAmountDue = _get(
      resultGetRepayments,
      'data.loan_details.0.loan.min_amount_due'
    )
    this.debouncedGAUpdateCurrentSliderValue(newValue, amountDue, minAmountDue)
  };

  handleProceed = () => {
    const { selfActions, token } = this.props
    selfActions.postAmountToPay(token)
    ga.event({
      category: 'click',
      action: 'select_payment_channel',
      label: 'button',
      value: 1
    })
  };

  handleClickViewInstallmentSummary = () => {
    const { router, token } = this.props
    router.push(`/repayments/${token}/installment-summary`)
    ga.event({
      category: 'click',
      action: 'view_installment_summary',
      label: 'button',
      value: 1
    })
  };

  render () {
    const {
      errorGetRepayments,
      resultGetRepayments,
      token,
      status,
      locale: {
        lang,
        currencyConfig,
        creditAmount: { step }
      },
      curAmountToPay,
      isAbleToChooseAmount
    } = this.props

    if (!_isEmpty(errorGetRepayments)) {
      return <ErrorPage token={token} />
    }
    if (_isEmpty(resultGetRepayments)) {
      return null
    }

    switch (status) {
      case selfConstants.STATUS.AWAITCONFIRMATION:
        return <AwaitConfirmation resultGetRepayments={resultGetRepayments} />
      case selfConstants.STATUS.LOANSUCCESS:
        return <LoanSuccess resultGetRepayments={resultGetRepayments} />
      case selfConstants.STATUS.INSTALLMENTSUCCESS:
        return (
          <InstallmentSuccess
            resultGetRepayments={resultGetRepayments}
            token={token}
          />
        )
      default:
    }

    const numberFormatter = new Intl.NumberFormat(lang, {
      style: 'currency',
      ...currencyConfig
    })

    const amountDue = _get(
      resultGetRepayments,
      'data.loan_details.0.loan.amount_due'
    )
    const minAmountDue = _get(
      resultGetRepayments,
      'data.loan_details.0.loan.min_amount_due'
    )

    const paidAmount = _get(
      resultGetRepayments,
      'data.loan_details.0.loan.paid_amount'
    )

    const loanDetails = [
      {
        key: <FormattedMessage id='repayments.selectChannel.label.principal' />,
        value: numberFormatter.format(
          _get(resultGetRepayments, 'data.loan_details.0.loan.principal')
        )
      },
      {
        key: (
          <FormattedMessage id='repayments.selectChannel.label.amountPaid' />
        ),
        value: _isNull(paidAmount)
          ? appConstants.NO_VALUE_SYMBOL
          : numberFormatter.format(paidAmount)
      },
      {
        key: (
          <FormattedMessage id='repayments.selectChannel.label.fullInstallmentsRepaid' />
        ),
        value: _get(
          resultGetRepayments,
          'data.loan_details.0.loan.fullInstallmentsRepaid'
        )
      },
      {
        key: (
          <FormattedMessage id='repayments.selectChannel.label.amountDueThisCycle' />
        ),
        value: numberFormatter.format(amountDue)
      },
      {
        key: <FormattedMessage id='repayments.selectChannel.label.dueDate' />,
        value: appUtils.formatToDDMMYYYY(
          new Date(
            Date.parse(
              _get(resultGetRepayments, 'data.loan_details.0.loan.due_date')
            )
          )
        )
      }
    ]

    const marks = generateSliderMarks(minAmountDue, amountDue, step)
    return (
      <div>
        <Card>
          <Heading>
            <FormattedMessage id='repayments.selectChannel.heading.loanDetails' />
          </Heading>
          <HorizontalLine />
          <List className='mt-2' data={loanDetails} />
          <div className='mt-2'>
            <ButtonLink onClick={this.handleClickViewInstallmentSummary}>
              <FormattedMessage id='repayments.selectChannel.btn.viewInstallmentSummary' />
            </ButtonLink>
          </div>
        </Card>
        {isAbleToChooseAmount ? (
          <Card className='mt-2'>
            <Heading>
              <FormattedMessage id='repayments.selectChannel.heading.chooseAmount' />
            </Heading>
            <HorizontalLine />
            <div className='mt-3 d-flex flex-column align-items-center'>
              <h6>
                <FormattedMessage id='repayments.selectChannel.label.chooseAmount' />
              </h6>
              <Slider
                dataTestId='slider-amount-to-pay'
                min={minAmountDue}
                max={amountDue}
                step={null}
                marks={marks}
                value={curAmountToPay}
                onChange={this.handleOnChangeCurAmountToPay}
                labelTop={numberFormatter.format(curAmountToPay)}
                labelLeft={numberFormatter.format(minAmountDue)}
                labelRight={numberFormatter.format(amountDue)}
              />
            </div>
          </Card>
        ) : (
          <Card className='mt-2'>
            <Heading>
              <FormattedMessage id='repayments.selectChannel.heading.amountToBePaid' />
            </Heading>
            <HorizontalLine />
            <div className='mt-3'>
              <h4 className='primary-color'>
                {numberFormatter.format(amountDue)}
              </h4>
            </div>
          </Card>
        )}
        <ButtonFixed onClick={this.handleProceed}>
          <FormattedMessage id='repayments.selectChannel.btn.selectPaymentChannel' />
        </ButtonFixed>
      </div>
    )
  }
}

export const mapStateToProps = state => {
  return {
    locale: state.app.locale,
    resultGetRepayments: state.repaymentsSelectChannel.resultGetRepayments,
    errorGetRepayments: state.repaymentsSelectChannel.errorGetRepayments,
    channels: state.repaymentsSelectChannel.channels,
    selectedChannel: state.repaymentsSelectChannel.selectedChannel,
    status: state.repaymentsSelectChannel.status,
    curAmountToPay: state.repaymentsSelectChannel.curAmountToPay,
    isAbleToChooseAmount: state.repaymentsSelectChannel.isAbleToChooseAmount
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    router: bindActionCreators(router, dispatch),
    appActions: bindActionCreators(appActions, dispatch),
    selfActions: bindActionCreators(selfActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectChannel)
