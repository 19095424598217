import React from 'react'

const inputMark = (allMarks, newValue) => {
  allMarks[newValue] = {
    style: null,
    label: null
  }
}

export const generateSliderMarks = (_min, _max, _step) => {
  const min = parseInt(_min, 10)
  const max = parseInt(_max, 10)
  const step = parseInt(_step, 10)
  const res = {}
  let lastInputValue = null
  for (let i = min; i <= max; i += step) {
    inputMark(res, i)
    lastInputValue = i
  }
  inputMark(res, max)

  const lastStep = max - lastInputValue
  if (lastStep > 0 && lastStep < step) {
    delete res[lastInputValue]
  }
  return res
}
