import * as selfConstants from './constants'
import initialState from './initialState'

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case selfConstants.SUBMIT_KYC_FORM_FAIL:
      return {
        ...state,
        title: 'blankstring',
        subtitle: 'blankstring',
        status: selfConstants.STATUS.FAILURE
      }
    case selfConstants.SUBMIT_KYC_FORM_LOAN_REJECTED:
      return {
        ...state,
        title: 'blankstring',
        subtitle: 'blankstring',
        status: selfConstants.STATUS.LOANREJECTED
      }
    case selfConstants.GET_KYC_FORM_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case selfConstants.SET_KYC_FORM_DATA:
      return {
        ...state,
        formData: action.payload
      }
    case selfConstants.SET_KYC_FORM_SCHEMA:
      return {
        ...state,
        schema: action.payload
      }
    case selfConstants.SET_KYC_FORM_DATA_HOW_TO_REACH:
      return {
        ...state,
        formData: {
          ...state.formData,
          howToReach: {
            ...state.formData.howToReach,
            ...action.payload
          }
        }
      }
    case selfConstants.SET_KYC_FORM_IS_TOUCHED:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
export default reducer
