import request from '@/utils/request'
import * as selfConstants from './constants'
import * as appConstants from '@/containers/App/constants'
import * as ga from '@/utils/gtag'
import { push } from 'react-router-redux'
import _get from 'lodash/get'

const API_URL = 'http://127.0.0.1:4000/api/v1'
export const getRepayments = token => async (dispatch, getState) => {
  const {
    app: { applicationRequestId }
  } = getState()

  dispatch({ type: selfConstants.GET_REPAYMENTS })
  dispatch({ type: appConstants.UPDATE_LOADER, payload: true })
  const startTime = window.performance.now()
  let endTime = 0
  try {
    const response = await request(`${API_URL}/repayments/${token}`, {
      method: 'GET'
    })
    endTime = window.performance.now()
    dispatch({ type: appConstants.UPDATE_LOADER, payload: false })
    dispatch({
      type: selfConstants.GET_REPAYMENTS_SUCCESS,
      payload: response
    })
  } catch (error) {
    endTime = window.performance.now()
    ga.event({
      action: 'repayment',
      category: 'occurrence',
      label: 'repymt',
      value: endTime - startTime
    })
    dispatch({ type: appConstants.UPDATE_LOADER, payload: false })
    dispatch({
      type: selfConstants.GET_REPAYMENTS_FAIL,
      payload: error
    })
  }
}

export const postAmountToPay = token => async (dispatch, getState) => {
  const {
    app: { applicationRequestId },
    repaymentsSelectChannel: { curAmountToPay, resultGetRepayments }
  } = getState()

  dispatch({ type: selfConstants.POST_AMOUNT_TO_PAY })
  dispatch({ type: appConstants.UPDATE_LOADER, payload: true })
  try {
    const response = await request(`${API_URL}/repayments/${token}`, {
      method: 'PUT',
      data: {
        amount: curAmountToPay
      }
    })
    dispatch({ type: appConstants.UPDATE_LOADER, payload: false })
    dispatch({
      type: selfConstants.POST_AMOUNT_TO_PAY_SUCCESS,
      payload: response
    })
    dispatch(push({ pathname: `/repayments/${token}/select-method` }))

    // send GA events
    ga.event({
      category: 'occurrence',
      action: 'repymt_amount_need',
      label: 'amount_need',
      value: curAmountToPay
    })
    ga.event({
      category: 'occurrence',
      action: 'repymt_loan_principal_v2',
      label: 'loan_principal',
      value: _get(resultGetRepayments, 'data.loan_details.0.loan.principal')
    })
    ga.event({
      category: 'occurrence',
      action: 'repymt_amt_paid',
      label: 'amt_paid',
      value: _get(resultGetRepayments, 'data.loan_details.0.loan.paid_amount')
    })
    ga.event({
      category: 'occurrence',
      action: 'repymt_full_inst_repaid',
      label: _get(
        resultGetRepayments,
        'data.loan_details.0.loan.fullInstallmentsRepaid'
      ),
      value: 1
    })
    ga.event({
      category: 'occurrence',
      action: 'repymt_amt_due',
      label: 'amt_due',
      value: _get(resultGetRepayments, 'data.loan_details.0.loan.amount_due')
    })
    ga.event({
      category: 'occurrence',
      action: 'repymt_due_date',
      label: _get(resultGetRepayments, 'data.loan_details.0.loan.due_date'),
      value: 1
    })
  } catch (error) {
    dispatch({ type: appConstants.UPDATE_LOADER, payload: false })
    dispatch({
      type: selfConstants.POST_AMOUNT_TO_PAY_FAIL,
      payload: error
    })
    alert(error.errors.message) // temporarily handle error case
  }
}

export const updateChannel = payload => {
  return {
    type: selfConstants.UPDATE_CHANNEL,
    payload
  }
}

export const tryPaymentAgain = () => dispatch => {
  dispatch({
    type: selfConstants.REDIRECT_TO_DEFAULT_PAGE
  })
}

export const updateCurrentAmountToPay = payload => {
  return {
    type: selfConstants.UPDATE_CURRENT_AMOUNT_TO_PAY,
    payload
  }
}
