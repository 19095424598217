import React from 'react'
import { connect } from 'react-redux'
import circuitBreakerIcon from './circuit_breaker_icon.svg'
import { FormattedMessage } from 'react-intl'
import Layout from '@/components/Layout'

class CircuitBreakerPage extends React.Component {
  componentDidMount () {
  }

  render () {
    return (
      <Layout noSeparateLine {...this.props}>
        <div className='my-4'>
          <img src={circuitBreakerIcon} />
        </div>
        <div className='title mt-2 font-weight-bold text-center'>
          <FormattedMessage id='circuitBreaker.title' />
        </div>
        <div className='subtitle mt-2 text-center'>
          <FormattedMessage id='circuitBreaker.subtitle' />
        </div>
      </Layout>
    )
  }
}

export const mapStateToProps = state => {
  return {}
}
export const mapDispatchToProps = dispatch => {
  return {}
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CircuitBreakerPage)
