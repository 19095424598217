import React from 'react'
import RcSlider from 'rc-slider/lib/Slider'
import 'rc-slider/assets/index.css'

import {
  dotStyle,
  sliderHandleStyle,
  sliderRailStyle,
  sliderTrackStyle
} from './styles'

import { LabelTop } from './styledComponents'

class Slider extends React.Component {
  render () {
    const { labelTop, labelLeft, labelRight, dataTestId, ...rest } = this.props
    return (
      <div className='w-100 mt-2 d-flex flex-column align-items-center'>
        <LabelTop>{labelTop}</LabelTop>
        <div data-test-id={dataTestId} className='w-100 my-2 px-4'>
          <RcSlider
            dotStyle={dotStyle}
            trackStyle={sliderTrackStyle}
            handleStyle={sliderHandleStyle}
            railStyle={sliderRailStyle}
            {...rest}
          />
        </div>
        <div className='w-100 d-flex justify-content-between px-4'>
          <div>
            <strong>{labelLeft}</strong>
          </div>
          <div>
            <strong>{labelRight}</strong>
          </div>
        </div>
      </div>
    )
  }
}

export default Slider
