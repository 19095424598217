import styled from "styled-components"

export const ActiveStep = styled.span`
  font-weight: bold;
  background: #394dff;
  color: #ffffff;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #3e51ff;
  width: 1.75rem;
  height: 1.75rem;
  text-align: center;
  line-height: 1.7rem;
`

export const InactiveStep = styled.span`
  font-weight: bold;
  display: inline-block;
  color: #9b9b9b;
  border-radius: 50%;
  border: 1px solid #e6e6e6;
  width: 1.75rem;
  height: 1.75rem;
  text-align: center;
  line-height: 1.7rem;
  background: #fff;
`

export const ConnectingLine = styled.span`
  position: relative;
  display: inline-block;
  width: 1rem;
  flex: 1;
  margin: 0px 5px 0px 5px;
  height: 2px;
  background-color: #d8d8d8;
  vertical-align: middle;
`
