import React from 'react'
import { Wrapper } from './styledComponents'

class ScrollingProgressBar extends React.Component {
  componentDidMount () {
    window.onscroll = this.handleOnScroll
    window.scrollTo(window.scrollX, window.scrollY + 1) // scroll a bit initiate progress bar value
  }

  componentWillUnmount () {
    window.onscroll = null
  }

  handleOnScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
    const height =
      document.documentElement.scrollHeight -
      Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
    const scrolled = (winScroll / height) * 100
    document.getElementById('scrolling-progress-bar').style.width =
      scrolled + '%'
  };

  render () {
    return (
      <Wrapper>
        <div id='scrolling-progress-bar' />
      </Wrapper>
    )
  }
}

export default ScrollingProgressBar
