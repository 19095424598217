import { combineReducers } from 'redux'
import { PURGE } from 'redux-persist'
import appReducer from '@/containers/App/reducer'
import homeReducer from '@/containers/HomePage/reducer'
import step1Reducer from '@/containers/Steps/1/reducer'
import step2Reducer from '@/containers/Steps/3/reducer'
import step3Reducer from '@/containers/Steps/2/reducer'
import step4Reducer from '@/containers/Steps/4/reducer'
import step5Reducer from '@/containers/Steps/5/reducer'
import step6Reducer from '@/containers/Steps/6/reducer'
import resumeAppReducer from '@/containers/ResumeApp/reducer'
import repaymentsSelectChannelReducer from '@/containers/RepaymentsPage/phases/SelectChannel/reducer'
import repaymentsSelectMethodReducer from '@/containers/RepaymentsPage/phases/SelectMethod/reducer'
import { reducer as formReducer } from 'redux-form'
import { routerReducer } from 'react-router-redux'

const allReducer = combineReducers({
  routing: routerReducer,
  app: appReducer,
  home: homeReducer,
  form: formReducer,
  step1: step1Reducer,
  step2: step2Reducer,
  step3: step3Reducer,
  step4: step4Reducer,
  step5: step5Reducer,
  step6: step6Reducer,
  resumeApp: resumeAppReducer,
  repaymentsSelectChannel: repaymentsSelectChannelReducer,
  repaymentsSelectMethod: repaymentsSelectMethodReducer
})

const rootReducer = (state, action) => {
  if (action.type === PURGE) {
    state = {
      app: state.app
    }
  }

  return allReducer(state, action)
}

export default rootReducer
