import React from 'react'

/**
 * Photo overlay for ktp.
 * @return {JSX.Element}
 * @constructor
 */
export function KTPOverlay ({ style, ...props }) {
  return <div style={{
    opacity: 0.5,
    aspectRatio: '1.58 / 1',
    width: '80%',
    border: 'solid 6px white',
    borderRadius: '1rem',
    boxShadow: '0 0 0 1000px rgba(0, 0, 0, 1)',
    ...style
  }} {...props} ></div>
}
