import React from 'react'
import * as selfConstants from './constants'
import * as appActions from '@/containers/App/actions'
import * as appUtils from '@/containers/App/utils'
import { push } from 'react-router-redux'
import PopupWarning from './components/PopupWarning'
import PopupBlocked from './components/PopupBlocked'
import APIErrorContent from '@/components/APIErrorContent'
import DynamicAPIErrorContent from '@/components/DynamicApiErrorContent'
import { getKYCFormService, getCitiesService, getSubdistrictsService, getVillagesService, postKYCFormService } from './services';
import _ from 'lodash'

let apiStartTime = 0
let apiEndTime = 0

// TODO: Better naming convention.
// 2. Use axios interceptor for getting timing of axios requests.
const generateDefinition = (fieldName, enumObjectArray) => {
  const fieldCode = selfConstants.enumFields[fieldName].code
  const enumArr = [selfConstants.DEFAULT_SELECT_WIDGET_VALUE]
  const defaultSelectWidgetLabel = selfConstants.DEFAULT_SELECT_WIDGET_VALUE
  const enumNamesArr = [defaultSelectWidgetLabel]
  enumObjectArray.forEach((enumObject) => {
    // Changing all enum to strings.
    enumArr.push(String(enumObject[fieldCode]));
    enumNamesArr.push(enumObject.name);
  })
  return {
    [fieldName]: {
      "title": selfConstants.fieldNameToFieldLabelMap[fieldName],
      "type": "string",
      "pattern": "^((?!Select).)*$",
      "default": selfConstants.DEFAULT_SELECT_WIDGET_VALUE,
      "enum": enumArr,
      "enumNames": enumNamesArr
    }
  }
}

export const getCities = (provinceCode) => (dispatch, getState) => {
  dispatch({ type: selfConstants.GET_CITIES })
  apiStartTime = window.performance.now()
  return getCitiesService(provinceCode)
    .then(response => {
      apiEndTime = window.performance.now()
      const cityDefinition = generateDefinition("city", response.data.cities);
      dispatch(resetHowToReachFieldsValueAndDefinitions(["city", "sub_district", "village"]))
      dispatch(setKYCFormSchemaDefinition(cityDefinition))
      dispatch({
        type: selfConstants.GET_CITIES_SUCCESS, meta: {
          fetchTime: apiEndTime - apiStartTime
        }
      })
    })
    .catch(() => {
      apiEndTime = window.performance.now()
      dispatch({
        type: selfConstants.GET_CITIES_FAIL, meta: {
          fetchTime: apiEndTime - apiStartTime
        }
      })
    })
}

export const getSubdistricts = (cityCode) => (dispatch, getState) => {
  dispatch({ type: selfConstants.GET_SUBDISTRICTS })
  const {
    step3: { formData: { howToReach: { province: provinceCode } } }
  } = getState()
  apiStartTime = window.performance.now()
  return getSubdistrictsService(provinceCode, cityCode)
    .then(response => {
      apiEndTime = window.performance.now()
      const subDistrictDefinition = generateDefinition("sub_district", response.data.sub_districts);
      dispatch(resetHowToReachFieldsValueAndDefinitions(["sub_district", "village"]))
      dispatch(setKYCFormSchemaDefinition(subDistrictDefinition))
      dispatch({
        type: selfConstants.GET_SUBDISTRICTS_SUCCESS, meta: {
          fetchTime: apiEndTime - apiStartTime
        }
      })
    })
    .catch(() => {
      apiEndTime = window.performance.now()
      dispatch({
        type: selfConstants.GET_SUBDISTRICTS_FAIL, meta: {
          fetchTime: apiEndTime - apiStartTime
        }
      })
    })
}

export const getVillages = (subdistrictCode) => (dispatch, getState) => {
  dispatch({ type: selfConstants.GET_VILLAGES })
  const {
    step3: { formData: { howToReach: { province: provinceCode, city: cityCode } } }
  } = getState()
  apiStartTime = window.performance.now()
  return getVillagesService(provinceCode, cityCode, subdistrictCode)
    .then(response => {
      apiEndTime = window.performance.now()
      const villagesDefinition = generateDefinition("village", response.data.villages);
      dispatch(resetHowToReachFieldsValueAndDefinitions(["village"]))
      dispatch(setKYCFormSchemaDefinition(villagesDefinition))
      dispatch({
        type: selfConstants.GET_VILLAGES_SUCCESS, meta: {
          fetchTime: apiEndTime - apiStartTime
        }
      })
    })
    .catch(() => {
      apiEndTime = window.performance.now()
      dispatch({
        type: selfConstants.GET_VILLAGES_FAIL, meta: {
          fetchTime: apiEndTime - apiStartTime
        }
      })
    })
}

const getEnumNameFromDefinitions = (definition, enumValue) => {
  const enumIndex = definition.enum.indexOf(enumValue)
  return definition.enumNames[enumIndex]
}

const generateValuesToPostFromFormData = (formData, definitions) => {
  let dateOfBirth;
  const valuesToPost = Object.keys(formData).reduce((valuesToPost, formSection) => {
    const updatedValuesToPost = Object.assign(valuesToPost, formData[formSection])
    // convert date_of_birth from stringified Date obj to 'dd-mm-yyyy' string
    if (formSection === 'basicInfo') {
      dateOfBirth = new Date(updatedValuesToPost.date_of_birth)
      const formattedDateOfBirth = appUtils.formatToDDMMYYYY(dateOfBirth)
      updatedValuesToPost.date_of_birth = formattedDateOfBirth;
    }
    if (formSection === 'howToReach') {
      ['province', 'city', 'sub_district', 'village'].forEach((addressLevel) => {
        updatedValuesToPost[addressLevel] = getEnumNameFromDefinitions(definitions[addressLevel], valuesToPost[addressLevel])
      })
    }
    return updatedValuesToPost;
  }, {});
  return [valuesToPost, dateOfBirth]
}

const dispatchUpdateModal = (dispatch, show, showHeader, body) => {
  return dispatch(
    appActions.updateModal({
      show,
      showHeader,
      body
    })
  )
}

export const postKYCForm = (formData, schema) => (dispatch, getState) => {
  dispatch({ type: selfConstants.SUBMIT_KYC_FORM })
  const { app: { applicationRequestId } } = getState()
  const [valuesToPost, dateOfBirth] = generateValuesToPostFromFormData(formData, schema.definitions);
  apiStartTime = window.performance.now()
  return postKYCFormService(applicationRequestId, valuesToPost)
    .then(response => {
      apiEndTime = window.performance.now()
      const fetchTime = apiEndTime - apiStartTime
      if (response.success) {
        dispatch({
          type: selfConstants.SUBMIT_KYC_FORM_SUCCESS,
          payload: response,
          meta: { fetchTime, valuesToPost, dateOfBirth }
        })
        dispatch(push({ pathname: '/step/3' }))
        return;
      }
      dispatch({ type: selfConstants.SUBMIT_KYC_FORM_FAIL, meta: { fetchTime }, message: response.errors.message })
    })
    .catch(error => {
      console.log('Data Ver Error', error)
      apiEndTime = window.performance.now()
      const fetchTime = apiStartTime - apiEndTime;
      const { errors: { kyc_attempt_count, code, message } } = error;
      dispatch({ type: selfConstants.SUBMIT_KYC_FORM_API_FAIL, meta: { fetchTime, code, message } })
      const max_attempt = 5 // should come from api
      if (code === 'application_rejected' || code === 'ktp_exists_error' || code === 'error_could_not_meet_decision_engine') {
        dispatch({ type: selfConstants.SUBMIT_KYC_FORM_LOAN_REJECTED })
      } else if (code === 'kyc_validation_error') {
        // Check error count
        if (kyc_attempt_count > max_attempt) {
          return dispatchUpdateModal(dispatch, true, false, <PopupBlocked />)
        }
        dispatchUpdateModal(dispatch, true, false,
          <PopupWarning nAttempts={kyc_attempt_count} maxAttempts={max_attempt} />)
      } else {
        dispatchUpdateModal(dispatch, true, false, <DynamicAPIErrorContent message={message} />)
      }
    })
}

export const getKYCForm = () => (dispatch, getState) => {
  dispatch({ type: selfConstants.GET_KYC_FORM })
  const { app } = getState()
  apiStartTime = window.performance.now()
  return getKYCFormService(app.applicationRequestId, app.locale.code)
    .then(response => {
      apiEndTime = window.performance.now()
      dispatch({
        type: selfConstants.GET_KYC_FORM_SUCCESS,
        payload: response.data.form,
        meta: {
          fetchTime: apiEndTime - apiStartTime
        }
      })
    })
    .catch(error => {
      apiEndTime = window.performance.now()
      dispatch({
        type: selfConstants.GET_KYC_FORM_FAIL, payload: error, meta: {
          fetchTime: apiEndTime - apiStartTime
        }
      });
      dispatchUpdateModal(dispatch, true, false, <APIErrorContent />)
    })
}

export const setKYCFormSchemaDefinition = (newDefinitions) => (dispatch, getState) => {
  const { step3: { schema } } = getState()
  const { definitions } = schema
  const updatedDefinitions = { ...definitions, ...newDefinitions }
  const updatedSchema = { ...schema, definitions: updatedDefinitions }
  dispatch({ type: selfConstants.SET_KYC_FORM_SCHEMA, payload: updatedSchema })
}

export const setKYCFormData = (formData) => (dispatch, getState) => {
  dispatch({
    type: selfConstants.SET_KYC_FORM_DATA,
    payload: formData
  })
}

export const resetHowToReachFieldsValueAndDefinitions = (fieldsToReset) => (dispatch, getState) => {
  const emptyDefinitions = fieldsToReset.reduce((emptyDefinitions, field) => {
    const emptyDefinition = generateDefinition(field, [])
    return { ...emptyDefinitions, ...emptyDefinition }
  }, {})

  dispatch(setKYCFormSchemaDefinition(emptyDefinitions));
  dispatch({
    type: selfConstants.SET_KYC_FORM_DATA_HOW_TO_REACH,
    payload: fieldsToReset.reduce((acc, field) => { acc[field] = selfConstants.DEFAULT_SELECT_WIDGET_VALUE; return acc; }, {})
  })
}

export const setKYCFormIsTouched = (touchedFieldIds) => (dispatch, getState) => {
  const { step3: { isTouched } } = getState();
  dispatch({
    type: selfConstants.SET_KYC_FORM_IS_TOUCHED,
    payload: { isTouched: _.union(isTouched, touchedFieldIds) }
  })
}
