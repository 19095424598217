import React from 'react'
import { connect } from 'react-redux'
import * as selfActions from '../../actions'
import * as appActions from '@/containers/App/actions'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import OTPInput from '@/components/OTPInput'
// import TimerBar from '@/components/TimerBar'
import styled from 'styled-components'
import * as appConstants from '@/containers/App/constants'

import {
  ERROR_OTP_RESEND_ATTEMPT_EXCEEDED,
  maxOtpNumbers,
  timeoutOtpVerification
} from '../../constants'
import _get from 'lodash/get'
import uuidv1 from 'uuid/v1'
import Card, { CardVerify } from '../../../../../components/Card'
import Timer from '../../../../../components/Timer'
import Checkbox from '../../../../../components/Checkbox'
import { Link } from 'react-router-dom'

const ButtonEditNumber = styled.span`
	color: #3145ff;
`

export class PhaseVerifyPhoneNumber extends React.Component {
  state = {
    keyTimer: uuidv1(),
    timeout: false,
    agreeTC: false
  }
  handleOnTimeChanged = newTime => {
    const { selfActions } = this.props
    selfActions.updateTimeRemaining(newTime)
    const elapsedTime = timeoutOtpVerification - newTime
    if ([30, 60, 120, 180].includes(elapsedTime)) {
    }
    if (newTime === 0) {
      this.setState({ timeout: true })
    }
  }

  handleToggleAcceptTermsAndConditions = newValue => {
    this.setState({ agreeTC: newValue })
    const { otp, onOTPChanged } = this.props
    onOTPChanged(otp, newValue)
  }

  handleClickResend = () => {
    const { selfActions } = this.props
    this.setState({
      hasClickedResend: true,
      timeout: false,
      keyTimer: uuidv1()
    })
    // selfActions.sendOTP(true)
  }

  handleClickSupportCall = () => {
  }

  handleClickSupportEmail = () => {
  }

  render () {
    const {
      phoneNumber,
      onSubmitClick,
      onClickEditNumber,
      otp,
      onOTPChanged,
      isResumable,
      locale: { countryCode },
      merchant
    } = this.props

    const { timeout, keyTimer } = this.state

    const errorCode =
      _get(this.props, 'resultSendOtp.errors.code') ||
      _get(this.props, 'resultVerifyOtp.errors.code')

    const errorMessage = _get(
      this.props,
      'resultVerifyOtp.errors.message',
      _get(this.props, 'resultVerifyOtp.errors.messages.0')
    )
    const isOTPValid = !errorCode ? undefined : !errorCode

    const shouldShowSupportLinks = [
      'limit_exceeded',
      'invalid_phone_number',
      'invalid_msisdn',
      ERROR_OTP_RESEND_ATTEMPT_EXCEEDED
    ].includes(errorCode)
    const shouldShowResendLink = timeout && !shouldShowSupportLinks
    const { agreeTC } = this.state
    console.log('aggree tc', agreeTC)
    return (
      <div className="d-flex flex-column align-items-center">
        <div className="title">
          <FormattedMessage id="step1.title" />
        </div>
        <div className="subtitle text-center mt-2">
          <FormattedMessage
            id="step1.subtitleVerifyPhoneNumber"
            values={{
              lineBreak: <br />,
              phoneNumber: (
                <span>
                  <strong>+{countryCode} {phoneNumber} </strong>
                </span>
              ),
              providerNumber: (
                <strong>{!isResumable ? '62168' : 'BPR AGM'}</strong>
              ),
              btnEditNumber: (
                <ButtonEditNumber onClick={onClickEditNumber}>
                  <strong>
                    <FormattedMessage id="step1.btn.editNumber" />
                  </strong>
                </ButtonEditNumber>
              )
            }}
          />
        </div>
        <div className="mt-3">
          <CardVerify>
            <OTPInput
              maxNumbers={maxOtpNumbers}
              value={otp}
              valid={isOTPValid}
              onChange={onOTPChanged}
              agreeTc={agreeTC}
            />
            <div style={{ marginTop: 30 }}>
              <span className={'subtitle text-center mt-2'}>
                <FormattedMessage id="step1.VerifyTimerTitle" />
                {!shouldShowSupportLinks && (
                  <Timer
                    maxTime={timeoutOtpVerification}
                    // maxTime={10}
                    timeout={timeout}
                    onTimeChanged={this.handleOnTimeChanged}
                  />
                )}
              </span>
            </div>

          </CardVerify>
        </div>

        {/*{!shouldShowSupportLinks && (*/}
        {/*  <TimerBar*/}
        {/*    key={keyTimer}*/}
        {/*    maxTime={timeoutOtpVerification}*/}
        {/*    timeout={timeout}*/}
        {/*    onTimeChanged={this.handleOnTimeChanged}*/}
        {/*  />*/}
        {/*)}*/}

        {isOTPValid === false && (
          <div className="mt-2 text-center">
            <span className="error-color">
              <FormattedMessage
                id={`step1.error.${errorCode}`}
                values={{
                  merchantName: appConstants.merchantDetails[merchant].name
                }}
                defaultMessage={errorMessage}
              />
            </span>
          </div>
        )}

        {shouldShowResendLink && (
          <div className="mt-4 text-center">
            <span className="error-color">
              <FormattedMessage
                id="step1.label.haveNotReceivedCode"
                values={{
                  btnResend: (
                    <a href="#" onClick={this.handleClickResend}>
                      <FormattedMessage id="step1.btn.resend" />
                    </a>
                  ),
                  lineBreak: <br />
                }}
              />
            </span>
          </div>
        )}

        {/*<div className='mt-2'>*/}
        {/*  <p>*/}
        {/*    <Checkbox*/}
        {/*      data-test-id='cb-agreeTC'*/}
        {/*      checked={agreeTC}*/}
        {/*      label={<FormattedMessage id='step1.label.agreeT&C' />}*/}
        {/*      onChange={this.handleToggleAcceptTermsAndConditions}*/}
        {/*    />*/}
        {/*  </p>*/}
        {/*  <p>*/}
        {/*    <Link*/}
        {/*      to={{*/}
        {/*        pathname: '/terms-and-conditions',*/}
        {/*        search: '?from=intro'*/}
        {/*      }}*/}
        {/*      onClick={this.handleClickTC}*/}
        {/*    >*/}
        {/*      <FormattedMessage id='intro.tc3' />*/}
        {/*    </Link>*/}
        {/*  </p>*/}
        {/*</div>*/}
      </div>
    )
  }
}

export const mapStateToProps = state => {
  return {
    resultVerifyOtp: state.step1.resultVerifyOtp,
    resultSendOtp: state.step1.resultSendOtp,
    isResumable: state.app.isResumable,
    locale: state.app.locale,
    merchant: state.app.merchant

  }
}

export const mapDispatchToProps = dispatch => {
  return {
    appActions: bindActionCreators(appActions, dispatch),
    selfActions: bindActionCreators(selfActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhaseVerifyPhoneNumber)
