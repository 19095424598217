import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import qs from 'query-string'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as appActions from '@/containers/App/actions'
import * as appConstants from '@/containers/App/constants'
import Cookies from 'js-cookie'

export class PrivateRoute extends React.Component {
  render () {
    const {
      appActions,
      component: Component,
      render,
      applicationRequestId,
      ...rest
    } = this.props

    return (
      <Route
        {...rest}
        render={props => {
          const DefaultComponent = render ? (
            render(props)
          ) : (
            <Component {...props} />
          )
          const {
            location: { search, pathname },
            location
          } = props

          const parsedQs = qs.parse(search)

          if (search.includes(appConstants.QUERY_STRING_FOR_TEST_MODE)) {
            return DefaultComponent
          }

          const parsedApplicationRequestId = parsedQs['application-request-id']
          if (parsedApplicationRequestId) {
            Cookies.set('application-request-id', parsedApplicationRequestId)
            appActions.updateApplicationRequestId(parsedApplicationRequestId)
            return (
              <Redirect
                to={{
                  pathname
                }}
              />
            )
          }

          if (applicationRequestId) {
            return DefaultComponent
          }

          const storedApplicationRequestId = Cookies.get(
            'application-request-id'
          )
          console.log('===================================')
          console.log(storedApplicationRequestId)
          if (storedApplicationRequestId) {
            appActions.updateApplicationRequestId(storedApplicationRequestId)
            return DefaultComponent
          }
          return (
            <Redirect
              to={{
                pathname: '/not-found',
                search: '?errorId=invalidApplicationRequestId'
              }}
            />
          )
        }}
      />
    )
  }
}

export const mapStateToProps = state => {
  return {
    applicationRequestId: state.app.applicationRequestId
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    appActions: bindActionCreators(appActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute)
