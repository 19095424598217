export const GET_CONTRACT = 'STEP_5/GET_CONTRACT'
export const GET_CONTRACT_SUCCESS = 'STEP_5/GET_CONTRACT_SUCCESS'
export const GET_CONTRACT_FAIL = 'STEP_5/GET_CONTRACT_FAIL'

export const ACCEPT_CONTRACT = 'STEP_5/ACCEPT_CONTRACT'
export const REJECT_CONTRACT = 'STEP_5/REJECT_CONTRACT'
export const ACCEPT_CONTRACT_SUCCESS = 'STEP_5/ACCEPT_CONTRACT_SUCCESS'
export const REJECT_CONTRACT_SUCCESS = 'STEP_5/REJECT_CONTRACT_SUCCESS'
export const ACCEPT_CONTRACT_FAIL = 'STEP_5/ACCEPT_CONTRACT_FAIL'

