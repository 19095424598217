import initialState from './initialState'
import * as selfConstants from './constants'

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case selfConstants.GET_APPLICATION_STATUS:
      return {
        ...state,
        status: ''
      }
    case selfConstants.GET_STATUS_SUCCESS:
      return {
        ...state,
        status: ''
      }
    case selfConstants.GET_APPLICATION_STATUS_API_FAIL:
      return {
        ...state,
        status: selfConstants.STATUS.APIFAILURE
      }
    case selfConstants.GET_APPLICATION_STATUS_USER_REJECTED:
      return {
        ...state,
        status: selfConstants.STATUS.USERREJECTED
      }

    default:
      return state
  }
}
export default reducer
