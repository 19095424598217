import request from '@/utils/request'
import * as selfConstants from './constants'
import { push } from 'react-router-redux'
import * as appConstants from '@/containers/App/constants'

const API_URL = 'https://api.bpragm.com/api/v1'
const API_LOS_URL = 'https://los.bpragm.com/api'
export const updateCurrentCreditAmount = payload => {
  return {
    type: selfConstants.UPDATE_CURRENT_CREDIT_AMOUNT,
    payload
  }
}
export const updateSelectedProductId = payload => {
  return {
    type: selfConstants.UPDATE_SELECTED_PRODUCT_ID,
    payload
  }
}

export const getProducts = (intl) => async (dispatch, getState) => {
  console.log('get products')
  const {
    app: { applicationRequestId }
  } = getState()
  dispatch({ type: selfConstants.GET_PRODUCTS })
  dispatch({ type: appConstants.UPDATE_LOADER, payload: true })
  try {
    const response = await request(
      `${API_URL}/application_requests/${applicationRequestId}/products`,
      { method: 'GET' }
    )
    const angsuranUrl = ({
      interest,
      principal,
      productId,
      tenor
    }) => `${API_LOS_URL}/products/fineract/repayment/schedule?interestRatePerPeriod=${interest}&principal=${principal}&productId=${productId}&tenor=${tenor}`
    const productData = response.data
    const resAngsuran = await request(angsuranUrl({
      interest: productData.interest,
      principal: productData.principal_loan,
      tenor: productData.tenor,
      productId: productData.product.id
    }), { method: 'GET' })
    dispatch({ type: appConstants.UPDATE_LOADER, payload: false })
    const contractUrl = API_LOS_URL +
      '/application-data-loan-products/app/contract/req/' + applicationRequestId
    const contractRes = await request(
      contractUrl,
      { method: 'GET' }
    )

    dispatch({
      type: selfConstants.GET_PRODUCTS_SUCCESS,
      payload: {
        response: {
          ...response,
          angsuran: resAngsuran,
          contract: contractRes
        }, intl
      }
    })
  } catch (error) {
    dispatch({ type: appConstants.UPDATE_LOADER, payload: false })
    dispatch({
      type: selfConstants.GET_PRODUCTS_FAIL,
      payload: error
    })
    console.log('get product failed')
  }
}

export const gotoNextStep = () => dispatch => {
  dispatch(push({ pathname: '/step/5' }))
}

export const getInstallments = () => async (dispatch, getState) => {
  const {
    app: { applicationRequestId },
    step4: { curCreditAmount }
  } = getState()

  dispatch({ type: selfConstants.GET_INSTALLMENTS })
  dispatch({ type: appConstants.UPDATE_LOADER, payload: true })
  try {
    const response = await request(
      `${API_URL}/application_requests/${applicationRequestId}/installments?amount=${curCreditAmount}`,
      { method: 'GET' }
    )
    dispatch({ type: appConstants.UPDATE_LOADER, payload: false })
    dispatch({
      type: selfConstants.GET_INSTALLMENTS_SUCCESS,
      payload: response
    })
  } catch (error) {
    dispatch({ type: appConstants.UPDATE_LOADER, payload: false })
    dispatch({
      type: selfConstants.GET_INSTALLMENTS_FAIL,
      payload: error
    })
  }
}
export const logTransaction = status => async (dispatch, getState) => {
  const {
    app: { applicationRequestId }
  } = getState()

  const urlToLogTransaction = `${API_LOS_URL}/application-data/log/update/${applicationRequestId}/status/${status}`
  await request(urlToLogTransaction, {
    method: 'POST'
  }).then(response => {
    console.log(response)
  })
}

export const postProductSelection = (
  currencyParams, principal, loanProductId, cycleDays) => async (
  dispatch, getState) => {
  const {
    app: {
      applicationRequestId,
      locale: {
        currencyConfig: { currency }
      }
    },
    step4: {
      contract
    }
  } = getState()
  dispatch({ type: selfConstants.POST_PRODUCT_SELECTION })
  try {
    // Recalculate Product
    await request(
      `${API_LOS_URL}/application-data-loan-products/recalculate/${applicationRequestId}`,
      {
        method: 'POST'
      }
    )

    const response = await request(
      `${API_URL}/application_requests/${applicationRequestId}/product_selection`,
      {
        method: 'POST',
        data: {
          request_id: applicationRequestId,
          currency: currencyParams,
          principal: parseInt(principal),
          loan_product_id: loanProductId,
          cycle_day: cycleDays
        }
      }
    )
    dispatch({
      type: selfConstants.POST_PRODUCT_SELECTION_SUCCESS,
      payload: response
    })
    // dispatch(push({ pathname: '/thanks-wait' }))
    dispatch(push({ pathname: '/step/5' }))
  } catch (error) {
    dispatch({
      type: selfConstants.POST_PRODUCT_SELECTION_FAIL,
      payload: error
    })
  }
}
