import React, { createRef } from 'react'
import { bindActionCreators } from 'redux'
import * as selfActions from '@/containers/Steps/3/actions'
import { connect } from 'react-redux'
import Webcam from 'react-webcam'
import Camera from './Camera.svg'
import { PhotoOverlay } from '@/containers/Steps/3/components/PhotoOverlay'

const majorDimen = { min: 1024, ideal: 1280, max: 1920 }
const minorDimen = { min: 576, ideal: 720, max: 1080 }

class AsliriCameraPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      webcamRef: createRef()
    }
  }

  sendData = (imageBase64) => {
    const { selfActions } = this.props
    selfActions.setAsliRIFiles([imageBase64])
  }

  render () {
    const {
      webcamRef
    } = this.state

    return (
      <div className="d-flex flex-column h-100">
        <header className="bg-white p-4 w-100">
          <h5 className="font-weight-bold text-center text-lg m-0">
            Foto wajah anda
          </h5>
        </header>
        <div className="h-100 overflow-hidden">
          <div
            className="d-flex h-100 w-100 position-relative justify-content-center">
            <PhotoOverlay style={{
              position: 'absolute',
              zIndex: 100,
              width: 'min(550px, 95%)',
              height: '95%',
              display: 'grid',
              justifyItems: 'center',
              top: '50%',
              transform: 'translateY(-50%)'
            }}
            />
            <Webcam
              allowFullScreen
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              screenshotQuality={1}
              minScreenshotHeight={1280}
              className="h-100"
              style={{ transform: 'rotateY(180deg)' }}
              videoConstraints={{
                facingMode: 'user'
              }}
            />
          </div>
        </div>
        <div
          className="position-absolute fixed-bottom d-flex justify-content-center mb-3 w-100">
          <div className=" d-flex flex-row justify-content-center w-100 px-5">
          <span
            className="rounded-circle bg-primary svg-icon-white p-3"
            onClick={async () => {
              const imageSrc = webcamRef.current.getScreenshot()
              this.sendData(imageSrc)
            }}
          >
            <img src={Camera} alt="Camera Icon" />
          </span>
          </div>
        </div>
      </div>
    )
  }
}

export const mapStateToProps = state => {
  return {
    gestureInstruction: state.step2.gestureInstruction,
    account: state.step2.account,
    gestureSetUsed: state.step2.gestureSetUsed
  }
}
export const mapDispatchToProps = dispatch => {
  return {
    selfActions: bindActionCreators(selfActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AsliriCameraPage)
