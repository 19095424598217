import React from 'react'
import { connect } from 'react-redux'
// import * as selfActions from './actions'
import * as appActions from '@/containers/App/actions'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import Button from '@/components/Button'
import cx from 'classnames'
import { PhoneInputWrapper } from '../styledComponents'
import PhoneInput from '../PhoneInput'
import indoFlag from './indo-flag.svg'
import ojkLogo from '@/static/images/ojk-logo.svg'
import _get from 'lodash/get'

export class PhaseEnterPhoneNumber extends React.Component {
  render () {
    const {
      phoneNumber,
      suggestionList,
      onPhoneInputChange,
      onSubmitClick
    } = this.props
    const errorCode = _get(this.props, 'resultSendOtp.errors.code')
    const errorMessage = _get(
      this.props,
      'resultSendOtp.errors.message',
      _get(this.props, 'resultSendOtp.errors.messages.0')
    )
    const hasError = !!errorCode
    const disabled = true
    return (
      <div className='d-flex flex-column align-items-center'>
        <div className='title'>
          <FormattedMessage id='step1.title' />
        </div>
        <div className='subtitle text-center mt-2 mb-3'>
          <FormattedMessage id='step1.subtitleEnterPhoneNumber' />
        </div>
        {hasError && (
          <div className='error-color'>
            <FormattedMessage
              id={`step1.error.${errorCode}`}
              defaultMessage={errorMessage}
            />
          </div>
        )}
        <PhoneInputWrapper
          className={cx('mt-1 position-relative', { 'has-error': hasError })}
          id='phoneInputWrapper'
          disabled={disabled}
        >
          <div className='d-flex'>
            <div>
              <img src={indoFlag} />
            </div>
            <div className='mx-2'>(+62)</div>
            <PhoneInput
              className='flex-grow-1'
              value={phoneNumber}
              disabled={disabled}
              onChange={onPhoneInputChange}
              suggestionList={suggestionList}
            />
          </div>
        </PhoneInputWrapper>
        <div className='mt-4'>
          <Button onClick={onSubmitClick} disabled={phoneNumber.length === 0}>
            <FormattedMessage id='step1.btn.continue' />
          </Button>
        </div>
        <div className='mt-5 pt-5'>
          <img src={ojkLogo} />
        </div>
      </div>
    )
  }
}

export const mapStateToProps = state => {
  return {
    resultSendOtp: state.step1.resultSendOtp
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    appActions: bindActionCreators(appActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhaseEnterPhoneNumber)
