import React from 'react'
import { connect } from 'react-redux'
// import * as selfActions from './actions'
import * as appActions from '@/containers/App/actions'
import { bindActionCreators } from 'redux'
import { FormattedHTMLMessage } from 'react-intl'
import closeIcon from './close-circular-button-symbol.svg'
import { CloseButton } from './styledComponents'
import * as router from 'react-router-redux'
import qs from 'query-string'

export class TermsAndConditions extends React.Component {
  componentDidMount () {
  }

  handleOnClose = () => {
    const {
      router,
      location: { search }
    } = this.props
    const parsedQs = qs.parse(search)
    router.push(`/${parsedQs.from}`)
  };

  render () {
    const {
      location: { search }
    } = this.props
    const fromOtherPage = search.includes('from')
    return (
      <div className='p-2'>
        {fromOtherPage && (
          <CloseButton onClick={this.handleOnClose}>
            <img src={closeIcon} />
          </CloseButton>
        )}

        <div className='subtitle mt-3'>
          <FormattedHTMLMessage id='tc.content' />
        </div>
      </div>
    )
  }
}

export const mapStateToProps = state => {
  return {
    applicationRequestId: state.app.applicationRequestId
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    router: bindActionCreators(router, dispatch),
    appActions: bindActionCreators(appActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TermsAndConditions)
