export const STATUS = {
  USERREJECTED: 'USERREJECTED',
  APIFAILURE: 'FAILURE'
}

export const GET_APPLICATION_STATUS = 'RESUME_APP/GET_APPLICATION_STATUS'
export const GET_APPLICATION_STATUS_SUCCESS = 'RESUME_APP/GET_APPLICATION_STATUS_SUCCESS'
export const GET_APPLICATION_STATUS_API_FAIL = 'RESUME_APP/GET_APPLICATION_STATUS_API_FAIL'
export const GET_APPLICATION_STATUS_USER_REJECTED = 'RESUME_APP/GET_APPLICATION_STATUS_USER_REJECTED'
export const REJECTED_ERROR_CODE = 'de_rejected'
