import React from 'react'
import styled from 'styled-components'
import { secondaryColor } from '@/containers/App/globalStyles'

export const Heading = styled.div`
  background: ${secondaryColor};
  border-radius: 4px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
