import React from 'react'
import styled from 'styled-components'
import leftArrow from './left-arrow.svg'

const Button = styled.div`
  position: fixed;
  top: 17px;
  left: 15px;
  z-index: 1;
`

const BackButton = (props) => {
  const { onClick } = props
  return (
    <Button role='button' onClick={onClick}>
      <img src={leftArrow} />
    </Button>
  )
}

export default BackButton
