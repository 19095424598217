import request from '@/utils/request'
import axirequesth from '@/utils/axirequesth'
import React from 'react'
import * as selfConstants from './constants'
import * as appActions from '@/containers/App/actions'
import { push } from 'react-router-redux'
import * as appConstants from '@/containers/App/constants'
import DynamicAPIErrorContent from '@/components/DynamicApiErrorContent'
import DynamicRejectContent from '@/components/DynamicRejectContent'

const API_URL = 'https://api.bpragm.com/api/v1'
const API_LOS_URL = 'https://los.bpragm.com/api'
const API_PRIVY_URL = 'https://los.bpragm.com/services/ttfprivyid/api'
const API_SIGN_CONTRACT_LENDER = 'https://cashloan.trustteknologi.id/services/ttfdigisignperuri'

export const getContract = () => async (dispatch, getState) => {
  const {
    app: { applicationRequestId }
  } = getState()

  dispatch({ type: selfConstants.GET_CONTRACT })
  const start = window.performance.now()
  dispatch({ type: appConstants.UPDATE_LOADER, payload: true })
  try {
    const response = await axirequesth(
      `${API_PRIVY_URL}/contract/borrower/v1/preview?applicationRequestId=${applicationRequestId}`,
      {
        method: 'POST',
        data: {}
      }
    )
    console.log('response ', response)
    dispatch({ type: appConstants.UPDATE_LOADER, payload: false })
    dispatch({
      type: selfConstants.GET_CONTRACT_SUCCESS,
      payload: response
    })
  } catch (error) {
    console.log('error', error)
    dispatch({ type: appConstants.UPDATE_LOADER, payload: false })
    dispatch({
      type: selfConstants.GET_CONTRACT_FAIL,
      payload: error
    })
  }
}

export const gotoVerifyOtp = () => dispatch => {
  dispatch(push({ pathname: '/step/6' }))
}

const dispatchUpdateModal = (dispatch, show, showHeader, body) => {
  return dispatch(
    appActions.updateModal({
      show,
      showHeader,
      body
    })
  )
}

export const acceptContract = () => async (dispatch, getState) => {
  console.log('accept contract')
  dispatch({ type: appConstants.UPDATE_LOADER, payload: true })
  const {
    app: { applicationRequestId },
    step4: {
      curCreditAmount,
      selectedProductId,
      selectedProduct: { name, number_of_repayments, service_charge_percentage }
    }
    // ,
    // step5: {
    //   resultGetContract: {
    //     data: { contract_id }
    //   }
    // }
  } = getState()

  dispatch({ type: selfConstants.ACCEPT_CONTRACT })
  const start = window.performance.now()
  try {
    const urlToLogTransaction = `${API_LOS_URL}/application-data/log/update/${applicationRequestId}/status/DIGITAL_SIGN_CONTRACT`
    request(urlToLogTransaction, {
      method: 'POST'
    }).then(response => {
      console.log(response)
    })
    dispatch({ type: appConstants.UPDATE_LOADER, payload: false })
    dispatch({
      type: selfConstants.ACCEPT_CONTRACT_SUCCESS,
      payload: {}
    })
    // dispatch(push({ pathname: '/step/6' }))
  } catch (error) {
    console.log('Error Accept contract ', error)
    dispatch({ type: appConstants.UPDATE_LOADER, payload: false })
    const { errors: { message } } = error
    // const message = 'Gagal menyetujui dokumen'
    dispatchUpdateModal(dispatch, true, false, <DynamicRejectContent
      message={message} />)
    console.log(message)
    dispatch({
      type: selfConstants.ACCEPT_CONTRACT_FAIL,
      payload: error
    })
  }
}

export const rejectContract = () => async (dispatch, getState) => {
  console.log('reject contract')
  dispatch({ type: appConstants.UPDATE_LOADER, payload: true })
  const {
    app: { applicationRequestId }
  } = getState()

  dispatch({ type: selfConstants.REJECT_CONTRACT })
  try {
    const response = await request(
      `${API_URL}/application_requests/${applicationRequestId}/contract_rejected`,
      {
        method: 'GET'
      }
    )
    const urlToLogTransaction = `${API_LOS_URL}/application-data/log/update/${applicationRequestId}/status/CONTRACT_REJECTED`
    request(urlToLogTransaction, {
      method: 'POST'
    }).then(response => {
      console.log(response)
    })
    const message = 'Anda tidak menyetuji e-kontrak ini, silahkan hubungi admin untuk informasi lebih lanjut'
    dispatchUpdateModal(dispatch, true, false, <DynamicRejectContent
      message={message} />)
    console.log(message)
    dispatch({ type: appConstants.UPDATE_LOADER, payload: false })
    dispatch({
      type: selfConstants.REJECT_CONTRACT_SUCCESS,
      payload: response
    })
    // dispatch(push({ pathname: '/step/6' }))
  } catch (error) {
    dispatch({ type: appConstants.UPDATE_LOADER, payload: false })
    const { errors: { message } } = error
    // const message = 'Gagal menyetujui dokumen'
    dispatchUpdateModal(dispatch, true, false, <DynamicAPIErrorContent
      message={message} />)
    console.log(message)
    dispatch({
      type: selfConstants.ACCEPT_CONTRACT_FAIL,
      payload: error
    })
  }
}
