import React from 'react'
import { LoaderWrapper } from './styledComponents'
import imgSpinner from './giphy-11.gif'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as appActions from '@/containers/App/actions'

class Loader extends React.Component {
  handleClickClose = () => {
    const { appActions } = this.props
    appActions.updateLoader(false)
  };

  render () {
    return (
      <LoaderWrapper className='d-flex flex-column align-items-center justify-content-center'>
        <img src={imgSpinner} />
      </LoaderWrapper>
    )
  }
}

export const mapStateToProps = state => {
  return {}
}

export const mapDispatchToProps = dispatch => {
  return {
    appActions: bindActionCreators(appActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Loader)
