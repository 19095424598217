import React from 'react'
import _get from 'lodash/get'
import * as selfConstants from './constants'

class CameraHandler extends React.Component {
  handleOnChange = e => {
    const { onError, onLoadFile } = this.props
    const file = _get(e, 'currentTarget.files.0')
    if (file) {
      if (selfConstants.IMAGE_TYPE.indexOf(file.type) < 0) {
        onError('type', selfConstants.IMAGE_TYPE_ERROR, file)
        return false
      } else if (file.size / 1024 / 1024 > selfConstants.IMAGE_SIZE) {
        onError('limit', selfConstants.IMAGE_SIZE_ERROR, file)
        return false
      }
      // selfActions.clearErrorMessages()

      const fr = new window.FileReader()
      fr.onload = event => {
        onLoadFile({
          name: file.name,
          size: file.size,
          type: file.type,
          dataUrl: event.target.result
        })
      }
      fr.readAsDataURL(file)
    }
  };

  render () {
    const { innerRef, id } = this.props
    return (
      <input
        id={id}
        type='file'
        accept='image/*'
        hidden
        ref={innerRef}
        onChange={this.handleOnChange}
        capture='camera'
      />
    )
  }
}

export default React.forwardRef((props, ref) => (
  <CameraHandler {...props} innerRef={ref} />
))
