import React from "react"
import PropTypes from "prop-types"
import { TimerDisplay } from "./styledComponents"
import { FormattedMessage } from 'react-intl'

class Timer extends React.Component {
  state = {
    curTime: null
  }
  idInterval = null

  componentDidMount() {
    this.setState({ curTime: this.props.maxTime })

    this.idInterval = setInterval(() => {
      const newTime = this.state.curTime - 1
      this.setState({ curTime: newTime })
      this.props.onTimeChanged(newTime)
      if (newTime <= 0) {
        clearInterval(this.idInterval)
      }
    }, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.idInterval)
  }

  getMinutes(time) {
    return Math.floor(time / 60)
      .toString()
      .padStart(2, "0")
  }

  getSeconds(time) {
    return Math.floor(time % 60)
      .toString()
      .padStart(2, "0")
  }

  render() {
    const { curTime } = this.state
    return (
      <span>
        <TimerDisplay timeout={this.props.timeout}>
          {this.getMinutes(curTime)}:
          {this.getSeconds(curTime)}
          &nbsp;<FormattedMessage id='timer.mins' />
        </TimerDisplay>
      </span>
    )
  }
}

Timer.propTypes = {
  timeout: PropTypes.bool,
  maxTime: PropTypes.number,
  onTimeChanged: PropTypes.func
}

export default Timer
