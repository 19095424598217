// import * as selfConstants from './constants'
import initialState from './initialState'
import * as selfConstants from './constants'
import * as selfUtils from './utils'
import React from 'react'
import { supportedLocales } from '@/locales'
import * as ga from '@/utils/gtag'
import _get from 'lodash/get'

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case selfConstants.UPDATE_CURRENT_CREDIT_AMOUNT: {
      const curCreditAmount = action.payload

      return {
        ...state,
        curCreditAmount,
        isMonthlyInstallmentCalculated: false,
        selectedProductId: null,
        loanProducts: state.loanProducts.map(product => {
          const { min_principal, max_principal } = product
          return {
            ...product,
            disabled:
              curCreditAmount < min_principal || curCreditAmount > max_principal
          }
        })
      }
    }
    case selfConstants.UPDATE_SELECTED_PRODUCT_ID: {
      const selectedProductId = action.payload
      const { resultGetInstallments, dueDate } = state
      const selectedProduct =
        _get(state, 'resultGetProducts.data.loan_products', []).find(
          p => p.id === selectedProductId
        ) || {}

      const numberOfRepayments = selectedProduct.number_of_repayments
      return {
        ...state,
        selectedProductId,
        selectedProduct,
        ...selfUtils.updateStateForChanges({
          selectedProductId,
          resultGetInstallments,
          dueDate
        })
      }
    }
    case selfConstants.GET_PRODUCTS_SUCCESS: {
      const { response: { contract, ...response }, intl } = action.payload
      const resultGetProducts = response.data
      const creditAmount = resultGetProducts.principal_loan
      // const selectedProduct = resultGetProducts.product.productName
      const tenor = resultGetProducts.tenor
      const interestRate = resultGetProducts.interest + ' % ' +
        resultGetProducts.product.interestType
      const interestAmount = resultGetProducts.interestAmount
      const interestSchedule = resultGetProducts.product.interestSchedule
      const principalLoan = resultGetProducts.principal_loan
      const adminFee = resultGetProducts.service_fee
      const disbursementTotal = resultGetProducts.total_disburse
      const installment = resultGetProducts.installments
      const currency = resultGetProducts.currency
      const principal = resultGetProducts.principal_loan
      const loanProuductId = resultGetProducts.loan_product_id
      const cycleDay = resultGetProducts.cycle_days[0]
      const insuranceFee = resultGetProducts.insuranceFee
      const product = resultGetProducts.product
      const angsuran = response.angsuran
      const provisiFee = contract.provisiFee || 0
      const notarisFee = contract.notarisFee || 0
      const materaiFee = contract.materaiFee || 0
      const admTabFee = contract.admTabFee || 0
      const otherFee = contract.otherFee || 0
      const nominalTabunganWajib = contract.amountTabWajib || 0
      const totalDisburse = contract.totalDisburse || 0

      return {
        ...state,
        resultGetProducts,
        creditAmount: creditAmount,
        status: selfConstants.ON_PRODUCTS_SUCCESS,
        tenor: tenor,
        selectedProduct: resultGetProducts.product.productName,
        interestRate: interestRate,
        interestAmount: interestAmount,
        interestSchedule: interestSchedule,
        principalLoan: principalLoan,
        adminFee: adminFee,
        disbursementTotal: disbursementTotal,
        installment: installment,
        currency: currency,
        insuranceFee: insuranceFee,
        principal: principal,
        loan_product_id: loanProuductId,
        cycle_day: cycleDay,
        product,
        angsuran,
        provisiFee,
        notarisFee,
        materaiFee,
        admTabFee,
        otherFee,
        totalDisburse,
        nominalTabunganWajib,
        contract
      }
    }
    case selfConstants.GET_INSTALLMENTS: {
      return {
        ...state,
        isMonthlyInstallmentCalculated: false
      }
    }
    case selfConstants.GET_PRODUCTS_FAIL: {
      return {
        ...state,
        status: selfConstants.ON_GET_PRODUCT_FAILED
      }
    }
    case selfConstants.GET_INSTALLMENTS_SUCCESS: {
      const resultGetInstallments = action.payload
      const {
        selectedProductId,
        selectedProduct: { name, number_of_repayments },
        dueDate,
        curCreditAmount
      } = state
      return {
        ...state,
        isMonthlyInstallmentCalculated: true,
        resultGetInstallments,
        ...selfUtils.updateStateForChanges({
          selectedProductId,
          resultGetInstallments,
          dueDate
        })
      }
    }
    default:
      return state
  }
}
export default reducer
