import { createGlobalStyle } from 'styled-components'
// import 'circular-std'
import 'react-datepicker/dist/react-datepicker.css'

export const primaryColor = '#394DFF'
export const secondaryColor = '#f1f2ff'

const GlobalStyle = createGlobalStyle`
  body {
    // font-family: CircularStd;
    font-family: Tahoma,Verdana,Segoe,sans-serif;
  }
  
  a {
    color: #2c4cff;
    text-decoration: underline;
  }
  
  *:not(input):not(textarea) {
    user-select: none;
  }
  
  .title {
    font-size: 18px;
    color: #202020;
    font-weight: bold;
    margin-top: 20px;
  }
  .subtitle {
    opacity: 0.9;
    font-size: 14px;
    color: #4A4A4A;
  }
  .input-control {
    border: 0;
    border-bottom: 1px solid #DBDBDB;
    padding: 0;
    outline: none;
    appearance: none;
    border-radius: 0;
  }
  .scrollable {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .primary-color {
    color: ${primaryColor};
  }
  
  .error-color {
    color: #D0021B;
  }
  .has-error {
    border-color: #D0021B;
    input:not([data-suggestion="true"]) {
      color: #D0021B;
    }
  }
  .selectable { 
    user-select: auto !important; 
  }
  .tooltip-inner{
    background-color: #394dff !important;
    color: #fff !important;
  }
  .bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before{
    border-top-color: #394dff;
  }
  .react-datepicker__input-container {
    width: 100%;
  }
  .caret {
    position: absolute;
    right: 0;
    top: 10px;
  }
  .has-error {
    color: #e02020;
    border-color: #e02020;
  }
  @keyframes vanish {
    to {
      transform: translate(0px, -200px) rotate(360deg);
      width: 0px;
      opacity: 0;
    }
  }
  .rotate {
    animation-name: vanish;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    /*animation-timing-function: linear;*/
  }
  @keyframes rotateFull {
    0%  {transform: rotate(0deg);}
    50%  {transform: rotate(180deg);}
    100% {transform: rotate(0deg);}
  }
  .rotateSpinner {
    animation-name: rotateFull;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
  @keyframes antiClockwiseSpin {
    0%  {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
  }
  .rotateSuccessIcon {
    animation-name: antiClockwiseSpin;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }
`

export default GlobalStyle
