import React from 'react'
import { connect } from 'react-redux'
import * as selfActions from './actions'
import * as appActions from '@/containers/App/actions'
import { bindActionCreators } from 'redux'
import * as router from 'react-router-redux'
import Layout from '@/components/Layout'
import Steps from '@/containers/Steps'

export class HomePage extends React.Component {
  render () {
    const {
      match: {
        params: { id = 1 }
      },
      isResumable,
      ...rest
    } = this.props
    const Step = Steps[id]
    const stepIdInInteger = parseInt(id, 10)
    const doesntKnowHowManySteps = isResumable && stepIdInInteger === 1
    return (
      <Layout
        stepId={id}
        noSeparateLine={doesntKnowHowManySteps}
        doesntKnowHowManySteps={doesntKnowHowManySteps}
        {...rest}
      >
        <Step />
      </Layout>
    )
  }
}

export const mapStateToProps = state => {
  return {
    isLoading: state.home.isLoading,
    resultGetArticles: state.home.resultGetArticles,
    isResumable: state.app.isResumable
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    selfActions: bindActionCreators(selfActions, dispatch),
    appActions: bindActionCreators(appActions, dispatch),
    router: bindActionCreators(router, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePage)
