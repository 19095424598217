import * as selfConstants from './constants'
import initialState from './initialState'
import _get from 'lodash/get'

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case selfConstants.GET_APPLICATION_REQUEST_ID_SUCCESS: {
      const resultGetApplicationRequestId = action.payload
      return {
        ...state,
        resultGetApplicationRequestId
      }
    }
    case selfConstants.GET_PHONE_NUMBERS:
      return {
        ...state,
        isLoading: true
      }
    case selfConstants.GET_PHONE_NUMBERS_SUCCESS: {
      const resultGetPhoneNumbers = action.payload.data
      const phoneNumber = resultGetPhoneNumbers.phone_numbers[0] || ''
      return {
        ...state,
        resultGetPhoneNumbers,
        phoneNumber,
        isLoading: false
      }
    }
    case selfConstants.GET_PHONE_NUMBERS_FAIL:
      return {
        ...state,
        errorGetPhoneNumbers: action.payload,
        isLoading: false
      }
    case selfConstants.SEND_OTP_SUCCESS:
    case selfConstants.SEND_OTP_FAIL:
      const resultSendOtp = action.payload
      const errorCode = _get(resultSendOtp, 'errors.code')
      if (errorCode === selfConstants.ERROR_OTP_RESEND_ATTEMPT_EXCEEDED) {
      }
      return {
        ...state,
        resultSendOtp,
        otp: initialState.otp,
        resultVerifyOtp: initialState.resultVerifyOtp
      }
    case selfConstants.INITIAL_OTP:
      const token = action.token
      return {
        ...state,
        otp: initialState.otp,
        token: token,
        resultVerifyOtp: initialState.resultVerifyOtp
      }
    case selfConstants.UPDATE_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: action.payload
      }
    case selfConstants.UPDATE_TIME_REMAINING:
      return {
        ...state,
        timeRemaining: action.payload
      }
    case selfConstants.UPDATE_PHASE:
      return {
        ...state,
        currentPhase: action.payload
      }
    case selfConstants.UPDATE_OTP:
      return {
        ...state,
        otp: action.payload
      }
    case selfConstants.VERIFY_OTP_SUCCESS: {
      return {
        ...state,
        resultVerifyOtp: action.payload
      }
    }
    case selfConstants.VERIFY_OTP_FAIL: {
      return {
        ...state,
        otp: initialState.otp,
        resultVerifyOtp: action.payload
      }
    }
    default:
      return state
  }
}
export default reducer
