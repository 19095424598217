import React from 'react'
import { connect } from 'react-redux'
import * as selfActions from './actions'
import * as appActions from '@/containers/App/actions'
import Card from '@/components/Card'
import List from '@/components/List'
import * as selfConstants from './constants'
import { bindActionCreators } from 'redux'
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl'
import Button from '@/components/Button'
import {
  HighlightText,
  LinkButton,
  CardBox,
  HorizontalLine,
  LayoutBottom
} from './components/styledComponents'
import MoreInfoContent from './components/MoreInfoContent'
import * as helpers from '@/utils/helpers'
import failureSmiley from '../2/failure-smiley.svg'
import { UploadFailureText } from '../2/styledComponents'
import { toIndonesiaCurrency } from '../../App/utils'

export class Step4 extends React.Component {
  constructor (props) {
    super(props)
  }

  componentDidMount () {
    const { selfActions, intl } = this.props
    console.log('viewdidmount ')
    selfActions.getProducts(intl)
    helpers.getLatLng()
  }

  gotoGetProduct = () => {
    const { selfActions, intl } = this.props
    selfActions.getProducts(intl)
  }

  handleOnTenureChange = (newOptionValue, index) => {
    const { selfActions } = this.props
    window.cdcm.product_id = String(index + 1)
    selfActions.updateSelectedProductId(newOptionValue)
    selfActions.getInstallments()
  }

  handleOnCreditAmountChange = newValue => {
    const { selfActions } = this.props
    selfActions.updateCurrentCreditAmount(newValue)
    this.debouncedGAUpdateCurrentSliderValue(newValue)
  }

  handleMoreInfoClicked = () => {
    const { appActions } = this.props
    appActions.updateModal({
      show: true,
      showHeader: false,
      body: <MoreInfoContent />
    })
  }

  handleProceed = () => {
    const {
      selfActions,
      currency,
      principal,
      loan_product_id,
      cycle_day
    } = this.props
    selfActions.postProductSelection(currency, principal, loan_product_id,
      cycle_day)
    selfActions.gotoNextStep()
  }
  handleReject = () => {
    const { selfActions } = this.props
    selfActions.logTransaction('LOAN_OFFER_CANCELLED')
    window.location.replace('https://trustiq.id/')
  }

  render () {
    const {
      status,
      creditAmount,
      tenor,
      selectedProduct,
      interestRate,
      interestAmount,
      interestSchedule,
      principalLoan,
      dueDate,
      adminFee,
      disbursementTotal,
      insuranceFee,
      installment,
      product,
      angsuran,
      provisiFee,
      notarisFee,
      materaiFee,
      admTabFee,
      otherFee,
      totalDisburse,
      nominalTabunganWajib
    } = this.props
    if (status == undefined) {
      console.log('undefined')
      return (<div />)
    } else {
      switch (status) {
        default:
          return (
            <div className="d-flex flex-column align-items-center w-100">
              <div className="title">
                <FormattedMessage id="step4.label.selectCreditAmount" />
              </div>
              <CardBox>
                <div className="cards">
                  <div className="card">
                    <p>Jumlah Pinjaman</p>
                    <p>
                      {typeof creditAmount !== 'undefined' && creditAmount !==
                      null ? (
                        toIndonesiaCurrency(creditAmount)
                      ) : (
                        ''
                      )}
                    </p>
                  </div>
                  <div className="card">
                    <p>Produk</p>
                    <p>
                      {typeof selectedProduct !== 'undefined' &&
                      selectedProduct !== null ? (
                        selectedProduct
                      ) : (
                        ''
                      )}
                    </p>
                  </div>
                  <div className="card">
                    <p>Jangka Waktu</p>
                    <p>
                      {typeof tenor !== 'undefined' && tenor !== null ? (
                        tenor
                      ) : (
                        ''
                      )}
                    </p>
                  </div>
                  <div className="card">
                    <p>Bunga Per {typeof interestSchedule !== 'undefined' &&
                    interestSchedule !== null ? (
                      interestSchedule
                    ) : (
                      ''
                    )}</p>
                    <p>
                      {typeof interestRate !== 'undefined' && interestRate !==
                      null ? (
                        interestRate
                      ) : (
                        ''
                      )}
                    </p>
                  </div>
                </div>
              </CardBox>
              <div className="title mt-4">
                Rincian Pinjaman
              </div>
              <Card className="w-100 mt-4">
                <List
                  className="mt-2"
                  data={[
                    {
                      key: (
                        <p>Pokok Pinjaman</p>
                      ),
                      value: (<HighlightText>
                        {typeof principalLoan !== 'undefined' &&
                        principalLoan !== null ? (
                          toIndonesiaCurrency(principalLoan)
                        ) : (
                          ''
                        )}
                      </HighlightText>)
                    },
                    {
                      key: <p>Biaya Admin</p>,
                      value: (<HighlightText>
                        {typeof adminFee !== 'undefined' && adminFee !== null
                          ? (
                            toIndonesiaCurrency(adminFee)
                          )
                          : (
                            ''
                          )}
                      </HighlightText>)
                    },
                    {
                      key: <p>Biaya Provisi</p>,
                      value: (<HighlightText>
                        {typeof provisiFee !== 'undefined' && provisiFee !==
                        null
                          ? (
                            toIndonesiaCurrency(provisiFee)
                          )
                          : (
                            ''
                          )}
                      </HighlightText>)
                    },
                    {
                      key: (
                        <p>Biaya Premi</p>
                      ),
                      value: (<HighlightText>
                        {typeof insuranceFee !== 'undefined' && insuranceFee !==
                        null ? (
                          toIndonesiaCurrency(insuranceFee)
                        ) : (
                          ''
                        )}
                      </HighlightText>)
                    },
                    {
                      key: <p>Biaya Notaris</p>,
                      value: (<HighlightText>
                        {typeof notarisFee !== 'undefined' && notarisFee !==
                        null
                          ? (
                            toIndonesiaCurrency(notarisFee)
                          )
                          : (
                            ''
                          )}
                      </HighlightText>)
                    },
                    {
                      key: <p>Biaya Materai</p>,
                      value: (<HighlightText>
                        {typeof materaiFee !== 'undefined' && materaiFee !==
                        null
                          ? (
                            toIndonesiaCurrency(materaiFee)
                          )
                          : (
                            ''
                          )}
                      </HighlightText>)
                    },
                    {
                      key: <p>Biaya Admin Tabungan</p>,
                      value: (<HighlightText>
                        {typeof admTabFee !== 'undefined' && admTabFee !==
                        null
                          ? (
                            toIndonesiaCurrency(admTabFee)
                          )
                          : (
                            ''
                          )}
                      </HighlightText>)
                    },
                    {
                      key: <p>Tabungan Cash Coll</p>,
                      value: (<HighlightText>
                        {typeof otherFee !== 'undefined' && otherFee !==
                        null
                          ? (
                            toIndonesiaCurrency(otherFee)
                          )
                          : (
                            ''
                          )}
                      </HighlightText>)
                    },
                    {
                      key: <p>Total Pencairan</p>,
                      value: (<HighlightText>
                        {typeof totalDisburse !== 'undefined' &&
                        totalDisburse !==
                        null
                          ? (
                            toIndonesiaCurrency(totalDisburse)
                          )
                          : (
                            ''
                          )}
                      </HighlightText>)
                    },
                    {
                      key: (
                        <p>Angsuran</p>
                      ),
                      value: (<HighlightText>
                        {(product.graceoninterest === 0 &&
                          product.graceonprincipal === 0)
                          ? (typeof installment !==
                          'undefined' && installment !==
                          null ? (
                            toIndonesiaCurrency(installment)
                          ) : (
                            ''
                          ))
                          : 'Berdasarkan tabel angsuran di bawah ini.'}
                      </HighlightText>)
                    },
                    {
                      key: (
                        <p>Bunga</p>
                      ),
                      value: (<HighlightText>
                        {typeof interestAmount !== 'undefined' &&
                        interestAmount !== null ? (
                          toIndonesiaCurrency(interestAmount)
                        ) : (
                          ''
                        )}

                      </HighlightText>)
                    },
                    {
                      key: <p>Nominal Tabungan Wajib</p>,
                      value: (<HighlightText>
                        {typeof nominalTabunganWajib !== 'undefined' &&
                        nominalTabunganWajib !==
                        null
                          ? (
                            toIndonesiaCurrency(nominalTabunganWajib)
                          )
                          : (
                            ''
                          )}
                      </HighlightText>)
                    },
                    {
                      key: <p>Total Angsuran</p>,
                      value: (<HighlightText>
                        {typeof nominalTabunganWajib !== 'undefined' &&
                        nominalTabunganWajib !==
                        null
                          ? (
                            toIndonesiaCurrency(
                              installment + nominalTabunganWajib))
                          : (
                            ''
                          )}
                      </HighlightText>)
                    }
                  ]}
                />
              </Card>

              <h5 className="title mt-4">
                Tabel Angsuran
              </h5>
              <Card className="w-100 mt-4" style={{ marginBottom: '70px' }}>
                <div className="table-responsive">
                  <table className="table table-striped m-0">
                    <thead>
                    <tr>
                      <th scope="col">Angsuran</th>
                      <th scope="col">Tgl. Jatuh Tempo</th>
                      <th scope="col">Pokok</th>
                      <th scope="col">Bunga</th>
                      <th scope="col">Nilai Angsuran</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      angsuran.length ?
                        angsuran.map(item => (
                          <tr>
                            <th scope="row">{item.period || '-'}</th>
                            <td>{item.dueDate || '-'}</td>
                            <td>{toIndonesiaCurrency(
                              item.principalDue || 0)}</td>
                            <td>{toIndonesiaCurrency(
                              item.interestDue || 0)}</td>
                            <td>{toIndonesiaCurrency(
                              item.totalInstallmentAmountForPeriod || 0)}</td>
                          </tr>
                        ))
                        : (
                          <tr>
                            <th scope="row">-</th>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        )
                    }
                    </tbody>
                  </table>
                </div>
              </Card>
              <div>
                <LayoutBottom>
                  <Button
                    data-test-id="btn-proceed"
                    disabled={false}
                    onClick={this.handleReject}
                    style={{ minWidth: '40%', background: 'DeepSkyBlue' }}
                  >
                    Batal
                  </Button>
                  <Button
                    disabled={false}
                    onClick={this.handleProceed}
                    style={{
                      marginLeft: '1rem',
                      minWidth: '40%',
                      background: 'DeepSkyBlue'
                    }}
                  >
                    Konfirmasi
                  </Button>
                </LayoutBottom>
              </div>
            </div>
          )
        case selfConstants.ON_GET_PRODUCT_FAILED:
          return (
            <React.Fragment>
              <div className="mt-4">
                <img src={failureSmiley} />
              </div>
              <div className="subtitle text-center mt-2 font-weight-bold">
                <UploadFailureText>
                  <FormattedMessage id="step2.subtitlefailureapology" />
                </UploadFailureText>
                <UploadFailureText>
                  <FormattedMessage id="step3.subtitlefailurereason" />
                </UploadFailureText>
              </div>
              <div className="mt-4">
                <span className="subtitle">
                  <FormattedMessage id="step3.label.help" />
                </span>
              </div>
              <div className="mt-4">
                <Button disabled={false} onClick={this.gotoGetProduct}>
                  <FormattedMessage id="step2.btn.tryagain" />
                </Button>
              </div>
            </React.Fragment>
          )
      }
    }
  }
}

export const mapStateToProps = state => {
  return {
    status: state.step4.status,
    locale: state.app.locale,
    creditAmount: state.step4.creditAmount,
    tenor: state.step4.tenor,
    selectedProduct: state.step4.selectedProduct,
    interestRate: state.step4.interestRate,
    interestAmount: state.step4.interestAmount,
    interestSchedule: state.step4.interestSchedule,
    dueDate: state.step4.dueDate,
    principalLoan: state.step4.principalLoan,
    adminFee: state.step4.adminFee,
    insuranceFee: state.step4.insuranceFee,
    disbursementTotal: state.step4.disbursementTotal,
    selectedProductId: state.step4.selectedProductId,
    installment: state.step4.installment,
    currency: state.step4.currency,
    principal: state.step4.principal,
    loan_product_id: state.step4.loan_product_id,
    cycle_day: state.step4.cycle_day,
    isMonthlyInstallmentCalculated: state.step4.isMonthlyInstallmentCalculated,
    product: state.step4.product,
    angsuran: state.step4.angsuran,
    provisiFee: state.step4.provisiFee,
    notarisFee: state.step4.notarisFee,
    materaiFee: state.step4.materaiFee,
    admTabFee: state.step4.admTabFee,
    otherFee: state.step4.otherFee,
    totalDisburse: state.step4.totalDisburse,
    nominalTabunganWajib: state.step4.nominalTabunganWajib
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    selfActions: bindActionCreators(selfActions, dispatch),
    appActions: bindActionCreators(appActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Step4))
